import React from "react";
import Barcode from 'react-barcode';

function JackpotTicketCopy(data) {
    return (
        <div>
            {
                data.barcode !== undefined && data.barcode != null && data.bets != undefined && data.bets !== null &&
                <div id="invoice-POS" className="ticket-copy">
                    <div id="watermark" className="copy">
                        <h1>Copy</h1>
                    </div>
                    <div id="top">
                        <div className="logo">
                            <img src="./images/keno-jackpot-black.svg" />
                        </div>
                        <br />
                        <div className="info">
                            <h2>BetShop</h2>
                        </div>
                    </div>
                    <div id="mid">
                        <div className="info">
                            <span>{data.shop_name} - {data.name}</span>
                            <span>{returnDate(data.date)} - {returnTime(data.date)}</span>
                            {
                                data.barcode !== undefined && data.barcode != null &&
                                <span>Ticket: {data.barcode.toString()}</span>
                            }
                            {
                                data.barcode !== undefined && data.barcode != null &&
                                <div className="cnBarcode">
                                    <Barcode value={data.barcode.toString()} />
                                </div>
                            }
                        </div>
                    </div>
                    <div id="bot">
                        {
                            data.bets != undefined && data.bets !== null && data.bets.length > 0 &&
                            data.bets.map((element, i) => (
                                <div className="divTable cnTableTop" key={i}>
                                    <div className="divTableBody">
                                        <div className="divTableRow">
                                            <div className="divTableCell  cnWidth1">{element.event_id}</div>
                                            <div className="divTableCell">&nbsp;</div>
                                            <div className="divTableCell cnWidth2"><strong>Keno Jackpot</strong></div>
                                            <div className="divTableCell">&nbsp;</div>
                                            <div className="divTableCell cnAlignRight cnWidth3">
                                                JACKPOT
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell  cnWidth1">{returnTime(data.date)}</div>
                                            <div className="divTableCell">&nbsp;</div>
                                            <div className="divTableCell cnWidth2">
                                                <ul>
                                                    {
                                                        element.ball_1 && element.ball_1 != null &&
                                                        <li>
                                                            {element.ball_1}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_2 && element.ball_2 != null &&
                                                        <li>
                                                            {element.ball_2}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_3 && element.ball_3 != null &&
                                                        <li>
                                                            {element.ball_3}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_4 && element.ball_4 != null &&
                                                        <li>
                                                            {element.ball_4}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_5 && element.ball_5 != null &&
                                                        <li>
                                                            {element.ball_5}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_6 && element.ball_6 != null &&
                                                        <li>
                                                            {element.ball_6}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_7 && element.ball_7 != null &&
                                                        <li>
                                                            {element.ball_7}
                                                        </li>
                                                    }
                                                    {
                                                        element.ball_8 && element.ball_8 != null &&
                                                        <li>
                                                            {element.ball_8}
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="divTableCell">&nbsp;</div>
                                            <div className="divTableCell cnAlignRight cnWidth3">{element.stakes} {localStorage.getItem('currency_symbol')}</div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="divTable cnTableBottom" >
                            <div className="divTableBody">
                                <div className="divTableRow">
                                    <div className="divTableCell cnBig">Total Stake</div>
                                    <div className="divTableCell">&nbsp;</div>
                                    <div className="divTableCell cnAlignRight cnBig">{data.total_stakes} {localStorage.getItem('currency_symbol')}</div>
                                </div>
                                <div className="divTableRow">
                                    <div className="divTableCell cnMed">Min/Max Win <br /> * T&C</div>
                                    <div className="divTableCell">&nbsp;</div>
                                    <div className="divTableCell cnAlignRight cnMed">
                                        1000 / JACKPOT {localStorage.getItem('currency_symbol')}
                                        <br />
                                        {"JACKPOT OR HIT (2-7/8)"}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="divTable cnTableBottom cnHeight" >
                            <div className="divTableBody">
                                <div className="divTableRow">
                                    <div className="divTableCell disclaimer"><strong>This is a "copy" ticket, from Baron Bet.</strong>
                                        This copy ticket has no monetary value and is just a receipt to confirm your ticket.
                                        For further inquiries, please contact us on <strong>support@baron.bet</strong> or on our hotline <strong>{process.env.REACT_APP_KENO_GAME_HOTLINE}</strong>.
                                        ** Please play responsibly. **
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default JackpotTicketCopy

function returnDate(string) {
    var arr = string.split(" ");
    return arr[0];
}

function returnTime(string) {
    var arr = string.split(" ");
    return arr[1];
}