import React, { useEffect, useState, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Language from "../assets/language.json";
import { useReactToPrint } from "react-to-print";
import { ToastContainer, toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import $ from "jquery";
import JackpotTicket from "./JackpotTicket";
import JackpotWinningReceipt from "./JackpotWinningReceipt";

function JackpotSlip(data) {

    const inputRef = React.useRef(null)
    const componentRef = useRef();
    const receiptComponent = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handlePrintReceipt = useReactToPrint({
        content: () => receiptComponent.current,
    });

    const [_stakes, _set_stakes] = useState(data._slip);
    const [_user_language, _set_user_language] = useState(localStorage.getItem("language"));
    let [_ticket_details, _set_ticket_details] = useState();
    let [_ticket_details_modal, _set_ticket_details_modal] = useState(false);
    let [_shop_credit, _set_shop_credit] = useState();
    const [_please_wait, set_please_wait] = useState(false);

    const [_barcode, _set_barcode] = useState();
    let [_ticket_bets, _set_ticket_bets] = useState();
    let [_ticket_date, _set_ticket_date] = useState();
    let [_ticket_max_odds, _set_ticket_max_odds] = useState();
    let [_ticket_max_potential_win, _set_ticket_max_potential_win] = useState();
    let [_ticket_max_total_win, _set_ticket_max_total_win] = useState();
    let [_ticket_min_odds, _set_ticket_min_odds] = useState();
    let [_ticket_min_potential_win, _set_ticket_min_potential_win] = useState();
    let [_ticket_time, _set_ticket_time] = useState();
    let [_ticket_total_stakes, _set_ticket_total_stakes] = useState();

    let [_cancel_ticket_modal_show, _set_cancel_ticket_modal_show] = useState(false);
    let [_did_cancel, _set_did_cancel] = useState(false);
    let [_cancel_ticket_error, _set_cancel_ticket_error] = useState();
    let [_cancel_ticket_number, _set_cancel_ticket_number] = useState("");

    const [_payout_ticket_modal_show, _set_payout_ticket_modal_show] = useState(false);
    let [_payout_ticket_error, _set_payout_ticket_error] = useState();
    let [_payout_ticket_number, _set_payout_ticket_number] = useState("");
    let [_payout_confirm_data, _set_payout_confirm_data] = useState();
    let [_payout_confirm_modal, _set_payout_confirm_modal] = useState(false);
    let [_confirm_payout, _set_confirm_payout] = useState(false);
    let [winning_ticket_data, set_winning_ticket_data] = useState();

    let [jackpot_winner_fullName, set_jackpot_winner_fullName] = useState("");
    let [jackpot_winner_fullAddress, set_jackpot_winner_fullAddress] = useState("");
    let [jackpot_winner_phoneNumber, set_jackpot_winner_phoneNumber] = useState("");
    let [jackpot_winner_cin, set_jackpot_winner_cin] = useState("");

    let [confirm_jackpot_submit, set_confirm_jackpot_submit] = useState(false);

    let [jackpot_user_verification, set_jackpot_user_verification] = useState(false);
    let [jackpot_lucky_winner_infos, set_jackpot_lucky_winner_infos] = useState([]);


    const update_shop_credit = async () => {
        if (localStorage.getItem('user_id')) {
            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: localStorage.getItem('auth_token'),
                    "Content-Type": "application/json",
                },
            };
            fetch(process.env.REACT_APP_API_URL + "shop-credit/" + localStorage.getItem('user_id'), requestOptions)
                .then((response) => response.json())
                .then(async (data) => {
                    _set_shop_credit(data.credit)
                });
        }
    }

    const _remove_single_bet = async (i) => {
        data._remove_row(i);
    };

    const _delete_all_bets = async () => {
        data._remove_all();
    };

    const _remove_bet_list = async () => {
        data.remove_bet_list();
    }

    const _get_details_about_ticket = async (ticket_number) => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                barcode: ticket_number,
            }),
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/bet-details/" +
            localStorage.getItem("user_id"),
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.message == "bet_found") {
                    _set_ticket_details(data);
                    _set_ticket_details_modal(true);
                }
            });
    };

    const _print_slip = async () => {

        if (_stakes[0].ball.length > 0) {
            for (let i = 0; i < _stakes[0].ball.length; i++) {  
                const ball = _stakes[0].ball[i];
                if (ball > 80) {
                    toast.error(Language.translations.login.toast.something_wrong);
                    return;
                }
            }
        }

        set_please_wait(true);
        var today = changeTimeZone(new Date(), localStorage.getItem("timezone"));
        /*
         */
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                bets: _stakes,
                min_odds: 1,
                max_odds: data.jackpot_amount,
                min_potential_win: 1000,
                max_potential_win: data.jackpot_amount,
                total_stakes: _add_stakes(_stakes),
                max_total_win: data.jackpot_amount,
                user_id: localStorage.getItem("user_id"),
                date:
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getDate(),
                time:
                    today.getHours() +
                    ":" +
                    today.getMinutes() +
                    ":" +
                    today.getSeconds(),
                name: localStorage.getItem("name"),
                on_going_id_bet: data._on_going_play,
            }),
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/post-bet/" +
            localStorage.getItem("user_id"),
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.message == "success") {
                    _set_ticket_bets(data.request.bets);
                    _set_ticket_date(data.date);
                    _set_ticket_max_odds(data.request.max_odds);
                    _set_ticket_max_potential_win(data.request.max_potential_win);
                    _set_ticket_max_total_win(data.request.max_total_win);
                    _set_ticket_max_total_win(data.request.max_total_win);
                    _set_ticket_min_odds(data.request.min_odds);
                    _set_ticket_min_potential_win(data.request.min_potential_win);
                    _set_ticket_time(data.request.time);
                    _set_ticket_total_stakes(data.request.total_stakes);
                    _set_barcode(data.barcode);
                    setTimeout(function () {
                        handlePrint();
                        _delete_all_bets();
                        _update_money();
                    }, 200);
                    setTimeout(function () {
                        _set_ticket_bets(null);
                        _set_ticket_date(null);
                        _set_ticket_max_odds(null);
                        _set_ticket_max_potential_win(null);
                        _set_ticket_max_total_win(null);
                        _set_ticket_max_total_win(null);
                        _set_ticket_min_odds(null);
                        _set_ticket_min_potential_win(null);
                        _set_ticket_time(null);
                        _set_ticket_total_stakes(null);
                        _set_barcode(null);
                        set_please_wait(false);
                    }, 1500);
                    return;
                } else {
                    _delete_all_bets();
                    set_please_wait(false);
                    toast.error(Language.translations.login.toast.something_wrong);
                    return;
                }
            });
    };

    const _update_money = async () => {
        data._tab_update_money();
        update_shop_credit();
    };

    const _cancel_played_ticket = async () => {
        let error = [];
        if (
            _cancel_ticket_number == "" ||
            _cancel_ticket_number == undefined ||
            _cancel_ticket_number == null
        ) {
            error.push("cannot be empty");
            _set_cancel_ticket_error(error);
            setTimeout(function () {
                _set_cancel_ticket_error();
            }, 20000);
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ barcode: _cancel_ticket_number }),
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/cancel-bet/" +
            localStorage.getItem("user_id"),
            requestOptions
        )
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message == "bet_not_found") {
                    error.push(Language.translations.Slip.ticket_invalid[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_already_finalized") {
                    error.push(Language.translations.Slip.ticket_finalized[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_already_cancelled") {
                    error.push(Language.translations.Slip.ticket_already_cancelled[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_already_paid_out") {
                    error.push(Language.translations.Slip.paid_out[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_not_paid_out") {
                    error.push(Language.translations.Slip.not_paid_out[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_lost") {
                    error.push(Language.translations.Slip.bet_lost[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_expired") {
                    error.push(Language.translations.Slip.expired_ticket[_user_language]);
                    _set_cancel_ticket_error(error);
                    setTimeout(function () {
                        _set_cancel_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_cancelled") {
                    _update_money();
                    _set_did_cancel(true);
                    _remove_bet_list();
                    return;
                }
                error.push(Language.translations.Slip.something_wrong_try_again[_user_language]);
                _set_cancel_ticket_error(error);
                setTimeout(function () {
                    _set_cancel_ticket_error();
                }, 20000);
                return;
            });
    };

    const _pay_ticket = async (ticket_number) => {
        let error = [];
        if (
            ticket_number == "" ||
            ticket_number == undefined ||
            ticket_number == null
        ) {
            error.push(Language.translations.Slip.cannot_be_empty[_user_language]);
            _set_payout_ticket_error(error);
            setTimeout(function () {
                _set_payout_ticket_error();
            }, 20000);
            return;
        }
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ barcode: ticket_number }),
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/payout/" +
            localStorage.getItem("user_id"),
            requestOptions
        )
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message == "not_paid_out") {
                    _set_payout_ticket_modal_show(false);
                    _set_payout_confirm_data(data);
                    _set_payout_confirm_modal(true);
                    return;
                }
                if (data.message == "pending") {
                    _set_payout_ticket_number("");
                    error.push(Language.translations.Slip.awaiting_draw[_user_language]);
                    _set_payout_ticket_error(error);
                    setTimeout(function () {
                        _set_payout_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "paid_out") {
                    _set_payout_ticket_number("");
                    error.push(Language.translations.Slip.paid_out[_user_language]);
                    _set_payout_ticket_error(error);
                    setTimeout(function () {
                        _set_payout_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "lost") {
                    _set_payout_ticket_number("");
                    error.push(Language.translations.Slip.lost_ticket[_user_language]);
                    _set_payout_ticket_error(error);
                    setTimeout(function () {
                        _set_payout_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "cancelled") {
                    _set_payout_ticket_number("");
                    error.push(Language.translations.Slip.ticket_cancelled[_user_language]);
                    _set_payout_ticket_error(error);
                    setTimeout(function () {
                        _set_payout_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "bet_not_found") {
                    _set_payout_ticket_number(Language.translations.Slip.ticket_invalid[_user_language]);
                    error.push("invalid");
                    _set_payout_ticket_error(error);
                    setTimeout(function () {
                        _set_payout_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "expired") {
                    _set_payout_ticket_number("");
                    error.push(Language.translations.Slip.ticket_expired[_user_language]);
                    _set_payout_ticket_error(error);
                    setTimeout(function () {
                        _set_payout_ticket_error();
                    }, 20000);
                    return;
                }

                error.push(Language.translations.Slip.something_wrong_try_again[_user_language]);
                _set_payout_ticket_error(error);
                setTimeout(function () {
                    _set_payout_ticket_error();
                }, 20000);
                return;
            });
    };

    const _confirm_payment = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                barcode: _payout_ticket_number,
            }),
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/confirm-payout/" +
            localStorage.getItem("user_id"),
            requestOptions
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.message == "paid_out") {
                    _update_money();
                    _set_confirm_payout(true);
                    set_winning_ticket_data(data);
                    _remove_bet_list();
                } else {
                    _update_money();
                    _set_payout_ticket_number("");
                    _set_payout_confirm_modal(false);
                    _set_payout_confirm_data();
                    _set_confirm_payout(false);
                }
            });
    };

    const updateJackpotWinnerInfo = async (full_name, full_address, phone_number, barcode, cin) => {
        set_jackpot_user_verification(true)
        if (full_name == null || full_name == undefined || full_name == "") {
            toast.error('Please verify fields before submission');
            set_jackpot_user_verification(false)
            return;
        }
        if (full_address == null || full_address == undefined || full_address == "") {
            toast.error('Please verify fields before submission');
            set_jackpot_user_verification(false)
            return;
        }
        if (phone_number == null || phone_number == undefined || phone_number == "") {
            toast.error('Please verify fields before submission');
            set_jackpot_user_verification(false)
            return;
        }
        if (barcode == null || barcode == undefined || barcode == "") {
            toast.error('Please verify fields before submission');
            set_jackpot_user_verification(false)
            return;
        }
        if (cin == null || cin == undefined || cin == "") {
            toast.error('Please verify fields before submission');
            set_jackpot_user_verification(false)
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                barcode: barcode,
                name: full_name,
                address: full_address,
                phone: phone_number,
                cin: cin,
                validated_by_user: localStorage.getItem('user_id'),
                validated_by_shop: localStorage.getItem('shop_id')
            }),
        };

        fetch(process.env.REACT_APP_API_URL + "jackpot/submit-winner-info/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.details == "winner_info_saved") {

                    set_jackpot_lucky_winner_infos([{
                        name: full_name,
                        address: full_address,
                        phone: phone_number,
                        cin: cin,
                        validated_by_user: localStorage.getItem('user_id'),
                        validated_by_shop: localStorage.getItem('shop_id')
                    }]);

                    _set_confirm_payout(true);
                    set_winning_ticket_data(data);
                    set_confirm_jackpot_submit(false);
                    set_jackpot_winner_fullName("");
                    set_jackpot_winner_fullAddress("");
                    set_jackpot_winner_phoneNumber("");
                    set_jackpot_winner_cin("");
                    toast.success('Winner info saved successfully');
                    set_jackpot_user_verification(false);
                    setTimeout(function () {
                        handlePrintReceipt();
                    }, 2000);
                    setTimeout(function () {
                        handlePrintReceipt();
                    }, 4000);
                } else {
                    toast.error('Something went wrong, please try again later');
                    set_jackpot_user_verification(false);
                    return;
                }
            });
    };

    function enterPrint() {
        let _is_possible_to_print = _check_to_disable_print(_shop_credit, _add_stakes(_stakes), data._slip.length);
        if (_is_possible_to_print == false) {
            inputRef.current.click()
        }
    }

    useEffect(() => {
        update_shop_credit();
    }, [])

    React.useEffect(() => {
        data.childFunc.current = () => _from_tab_check_ticket(data.childFunc);
        data.enterFunc.current = () => enterPrint();
    }, [])

    useEffect(() => {
        if (data._is_settings_open == false && _payout_ticket_modal_show == false && _payout_confirm_modal == false && data._show_ticket_of_the_day == false && _cancel_ticket_modal_show == false) {
            if ($('#root').hasClass('open-sidebar')) {
                //do nothing 
            } else {
                document.getElementById("fastbet-input-jackpot").focus();
            }
        }
    })

    function _from_tab_check_ticket(tab_data) {
        _set_payout_ticket_modal_show(true);
        _set_payout_ticket_number(tab_data.current.fastbet);
        _pay_ticket(tab_data.current.fastbet);
    }

    return (
        <>

            {/* BY DEFAULT */}
            {data._show_ticket_of_the_day == false && (
                <>
                    <Scrollbars>
                        <div className="divTable">
                            <div className="divTableBody">
                                <div className="divTableRow">
                                    <div className="divTableCell1 cellSelect cnTableHead noBorderTop noBorderLeft noBorderBottom">
                                        {Language.translations.Slip.selection[_user_language]}
                                    </div>
                                    <div className="divTableCell1 cellOdds cnTableHead noBorderTop noBorderBottom">
                                        {Language.translations.Slip.odds[_user_language]}
                                    </div>
                                    <div className="divTableCell1 cellStake cnTableHead noBorderTop noBorderBottom">
                                        {Language.translations.Slip.stake[_user_language]}
                                    </div>
                                    <div className="divTableCell1 cellRemove cnTableHead noBorderTop noBorderRight noBorderBottom">
                                        &nbsp;
                                    </div>
                                </div>
                                {_stakes !== undefined &&
                                    _stakes !== null &&
                                    _stakes.map((object, i) => (
                                        <div className="divTableRow cnColor" key={i}>
                                            <div className="divTableCell1 cellSelect cnVerticalAlign cnClear">
                                                <div className="cnImg">
                                                    <img
                                                        className="cnSmall"
                                                        src="./images/keno-black.svg"
                                                        alt="Keno"
                                                    />
                                                </div>
                                                <ul>
                                                    {object.ball !== undefined &&
                                                        object.ball !== null &&
                                                        object.ball.map((ball, index_2) => (
                                                            <li className="ball" key={index_2}>
                                                                {ball}
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                            <div className="divTableCell1 cellOdds cnVerticalAlign">
                                                {
                                                    object.ball.length == 8
                                                        ? "JACKPOT"
                                                        : object.ball.length == 7
                                                            ? "200"
                                                            : object.ball.length == 6
                                                                ? "60"
                                                                : object.ball.length == 5
                                                                    ? "20"
                                                                    : object.ball.length == 4
                                                                        ? "10"
                                                                        : object.ball.length == 3
                                                                            ? "5"
                                                                            : object.ball.length == 2
                                                                                ? "1"
                                                                                : object.ball.length == 1
                                                                                    ? "0"
                                                                                    : 0
                                                }
                                            </div>
                                            <div className="divTableCell1 cellStake cnVerticalAlign">
                                                <div className="inner">
                                                    <input
                                                        value={
                                                            object.stakes == "" ? 0 : Math.round(object.stakes)
                                                        }
                                                        type="text"
                                                        disabled={true}
                                                        onFocus={(e) => {
                                                            e.target.select();
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="divTableCell1 cellRemove cnVerticalAlign">
                                                <button
                                                    onClick={() => {
                                                        _remove_single_bet(i);
                                                    }}
                                                ></button>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <div className="divTable">
                            <div className="divTableBody">
                                {_stakes !== undefined &&
                                    _stakes !== null &&
                                    _stakes.length > 0 && (
                                        <>
                                            <div className="divTableRow">
                                                <div className="divTableCell1 cnAlignCenter cnRed cnSingle noBorderLeft noBorderRight">
                                                    {Language.translations.Slip.single[_user_language]}
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>

                        <table className="mergeCell">
                            <tbody>
                                {_stakes !== undefined &&
                                    _stakes !== null &&
                                    _stakes.length > 0 && (
                                        <>
                                            <tr className="cnTop">
                                                <td
                                                    rowSpan="2"
                                                    colSpan="1"
                                                    width="10%"
                                                    className="noBorderTop noBorderLeft"
                                                >
                                                    GR
                                                </td>
                                                <td
                                                    rowSpan="2"
                                                    colSpan="1"
                                                    width="15%"
                                                    className="noBorderTop"
                                                >
                                                    Combi
                                                </td>
                                                <td
                                                    rowSpan="1"
                                                    colSpan="2"
                                                    width="45%"
                                                    className="noBorderTop"
                                                >
                                                    {Language.translations.Slip.odds[_user_language]}
                                                </td>
                                                <td
                                                    rowSpan="2"
                                                    colSpan="1"
                                                    width="30%"
                                                    className="noBorderTop noBorderRight"
                                                >
                                                    {
                                                        Language.translations.Slip.stakes_bet[
                                                        _user_language
                                                        ]
                                                    }
                                                </td>
                                            </tr>
                                            <tr className="cnTop">
                                                <td>Min</td>
                                                <td>Max</td>
                                            </tr>
                                            <tr>
                                                <td className="noBorderBottom">1</td>
                                                <td className="noBorderBottom">{_stakes.length}</td>
                                                <td className="noBorderBottom">
                                                    1
                                                </td>
                                                <td className="noBorderBottom">
                                                    JACKPOT
                                                </td>
                                                <td className="noBorderBottom cnPadTop">
                                                    <input
                                                        disabled={true}
                                                        value={Math.round(_add_stakes(_stakes))}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )}
                            </tbody>
                        </table>

                        {_stakes !== undefined && _stakes !== null && _stakes.length > 0 && (
                            <>
                                <div className="win-payout">
                                    <div className="potential-min-win cnClear">
                                        <div className="win-wrapper">
                                            <div className="cnInner cnClear">
                                                <div className="cn-left">
                                                    <p>
                                                        {
                                                            Language.translations.Slip.potential_min_win[
                                                            _user_language
                                                            ]
                                                        }
                                                    </p>
                                                </div>
                                                <div className="cn-right">
                                                    {"1000"}
                                                    {localStorage.getItem("currency_symbol")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="potential-max-win cnClear">
                                        <div className="win-wrapper">
                                            <div className="cnInner cnClear">
                                                <div className="cn-left">
                                                    <p>
                                                        {
                                                            Language.translations.Slip.potential_max_win[
                                                            _user_language
                                                            ]
                                                        }
                                                    </p>
                                                </div>
                                                <div className="cn-right">
                                                    {"JACKPOT"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Scrollbars>
                </>
            )}

            {/* BY DEFAULT */}
            {data._show_ticket_of_the_day == false && (
                <>
                    <div className="cnTotalStake">
                        <div className="cnInner cnClear">
                            <div className="cn-left">
                                {Language.translations.Slip.total_stakes[_user_language]}
                            </div>
                            <div className="cn-right">
                                {Math.round(_add_stakes(_stakes))}
                                {localStorage.getItem("currency_symbol")}
                            </div>
                        </div>
                        <div className="cnInner cnClear">
                            <div className="cn-left">
                                {Language.translations.Slip.max_total_win[_user_language]}
                            </div>
                            {
                                _stakes !== undefined && _stakes !== null && _stakes.length > 0 ?
                                    <div className="cn-right">
                                        {"JACKPOT"}
                                    </div>
                                    :
                                    <div className="cn-right">
                                        {"0"}
                                        {localStorage.getItem("currency_symbol")}
                                    </div>
                            }
                        </div>
                    </div>
                </>
            )}

            {/* ALL TICKETS FOR KENO JACKPOT */}
            {data._show_ticket_of_the_day == true && (
                <>
                    <Scrollbars>
                        <div className="divTable cnDocument">
                            <div className="divTableBody">
                                <div className="divTableRow">
                                    {/* <div className="divTableCell1 cnTableHead noBorderTop noBorderLeft noBorderBottom">
                                        Info
                                    </div> */}
                                    <div className="divTableCell1 cnTableHead noBorderTop noBorderBottom">
                                        {Language.translations.Slip.ticket[_user_language]} N0
                                    </div>
                                    {/* <div className="divTableCell1 cnTableHead noBorderTop noBorderBottom">
                                        {Language.translations.Slip.stake[_user_language]}
                                    </div>
                                    <div className="divTableCell1 cnTableHead noBorderTop noBorderRight noBorderBottom">
                                        {Language.translations.Slip.status[_user_language]}
                                    </div> */}
                                </div>
                                {data.all_tickets !== undefined && data.all_tickets !== null ? (
                                    data.all_tickets
                                        .slice(0)
                                        .reverse()
                                        .map((object, i) => (
                                            <div className="divTableRow cnColor" key={i}>
                                                {/* <div className="divTableCell1 noBorderTop noBorderLeft noBorderBottom cnWarning">
                                                    <button
                                                        onClick={() => {
                                                            _get_details_about_ticket(object.ticket_number);
                                                        }}
                                                    >
                                                        <img src="./images/icon-warning.svg" alt="Keno" />
                                                    </button>
                                                </div> */}
                                                <div className="divTableCell1 noBorderTop noBorderBottom">
                                                    {object.ticket_number}
                                                </div>
                                                {/* <div className="divTableCell1 noBorderTop noBorderBottom">
                                                    {object.total_stake}
                                                    {localStorage.getItem("currency_symbol")}
                                                </div> */}
                                                {/* <div className="divTableCell1 noBorderTop noBorderRight noBorderBottom">
                                                    {object.payout_status == "paid_out" && Language.translations.Slip.paid[_user_language]}
                                                    {object.payout_status == "not_paid_out" && object.total_payout &&
                                                        <b>
                                                            {object.total_payout} {localStorage.getItem('currency_symbol')}
                                                            {"manamarina"}
                                                        </b>
                                                    }
                                                    {object.payout_status == "lost" && Language.translations.Slip.lost[_user_language]}
                                                    {object.payout_status == "lost" && "manamarina"}
                                                    {object.payout_status == "expired" && Language.translations.Slip.expired[_user_language]}
                                                    {object.payout_status == "cancelled" && Language.translations.Slip.cancelled[_user_language]}
                                                    {object.payout_status == "pending" && Language.translations.Slip.pending[_user_language]}
                                                </div> */}
                                            </div>
                                        ))
                                ) : (
                                    <div className="cnTable">
                                        <div className="cnTableCell">
                                            <div className="vertical-allign-no-bacground">
                                                <ColorRing
                                                    visible={true}
                                                    height="80"
                                                    width="80"
                                                    ariaLabel="blocks-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="blocks-wrapper"
                                                    colors={[
                                                        "#003779",
                                                        "#ff171b",
                                                        "#ff8400",
                                                        "#008032",
                                                        "#ffcc00",
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Scrollbars>
                </>
            )}

            {/* TICKET DETAILS MODAL */}
            {_ticket_details_modal == true && (
                <div className="modal-settings cnLong">
                    <div className="cnInner">
                        <Scrollbars>
                            <div className="cnTop">
                                <h1>
                                    {Language.translations.Slip.ticket_details[_user_language]}
                                </h1>
                                <div className="cnClose">
                                    <button
                                        onClick={() => {
                                            _set_ticket_details_modal(false);
                                            _set_ticket_details();
                                        }}
                                    ></button>
                                </div>
                            </div>
                            <div className="cnBody">
                                <div className={"stamp " + _ticket_details.draw_outcome}>
                                    {
                                        _ticket_details.draw_outcome == "lost" &&
                                        <h1>
                                            {Language.translations.Slip.lost[_user_language]}
                                        </h1>
                                    }
                                    {
                                        _ticket_details.draw_outcome == "pending" &&
                                        <h1>
                                            {Language.translations.Slip.pending[_user_language]}
                                        </h1>
                                    }
                                    {
                                        _ticket_details.draw_outcome == "won" &&
                                        <h1>
                                            {Language.translations.Slip.won[_user_language]}
                                        </h1>
                                    }
                                    {
                                        _ticket_details.draw_outcome == "cancelled" &&
                                        <h1>
                                            {Language.translations.Slip.cancelled[_user_language]}
                                        </h1>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col col-sm-6">
                                        <div className="cnTableWrap">
                                            <div className="divTable">
                                                <div className="divTableBody">
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.ticket_number[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.ticket_number != undefined &&
                                                                _ticket_details.ticket_number != null &&
                                                                _ticket_details.ticket_number}
                                                        </div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {Language.translations.Slip.time[_user_language]}
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.book_time != undefined &&
                                                                _ticket_details.book_time != null &&
                                                                _ticket_details.book_time}
                                                        </div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.selection[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.selections != undefined &&
                                                                _ticket_details.selections != null &&
                                                                _ticket_details.selections}
                                                        </div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.ticket_type[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.ticket_type != undefined &&
                                                                _ticket_details.ticket_type != null &&
                                                                _ticket_details.ticket_type}
                                                        </div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.status[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.ticket_status != undefined &&
                                                                _ticket_details.ticket_status != null &&
                                                                _ticket_details.ticket_status}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-sm-6">
                                        <div className="cnTableWrap">
                                            <div className="divTable">
                                                <div className="divTableBody">
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.total_stakes[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.total_stake != undefined &&
                                                                _ticket_details.total_stake != null &&
                                                                _ticket_details.total_stake}
                                                        </div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.jackpot[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell"></div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.total_payout[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.total_payout != undefined &&
                                                                _ticket_details.total_payout != null &&
                                                                _ticket_details.total_payout}
                                                        </div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.winning_tax[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell"></div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            {
                                                                Language.translations.Slip.net_payout[
                                                                _user_language
                                                                ]
                                                            }
                                                        </div>
                                                        <div className="divTableCell">
                                                            {_ticket_details.net_payout != undefined &&
                                                                _ticket_details.net_payout != null &&
                                                                _ticket_details.net_payoutreturnTime}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-sm-12">
                                        <div className="cnTableWrap">
                                            <div className="divTable">
                                                <div className="divTableBody">
                                                    <div className="divTableRow">
                                                        <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                {
                                                                    Language.translations.Slip.time[
                                                                    _user_language
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                {
                                                                    Language.translations.Slip.selection[
                                                                    _user_language
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                {
                                                                    Language.translations.Slip.event_result[
                                                                    _user_language
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                {
                                                                    Language.translations.Slip.outcome[
                                                                    _user_language
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                Max{" "}
                                                                {
                                                                    Language.translations.Slip.odds[
                                                                    _user_language
                                                                    ]
                                                                }
                                                            </div>
                                                        </div> */}
                                                        <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                {
                                                                    Language.translations.Slip.stake[
                                                                    _user_language
                                                                    ]
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="divTableCell">
                                                            <div className="cnVertical">
                                                                {Language.translations.Slip.win[_user_language]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {_ticket_details.bet_list.map((object, i) => (
                                                        <div className="divTableRow" key={i}>
                                                            <div className="divTableCell">
                                                                <div className="cnVertical">
                                                                    {_ticket_details.book_time != undefined &&
                                                                        _ticket_details.book_time != null &&
                                                                        returnTime(_ticket_details.book_time)}
                                                                </div>
                                                            </div>
                                                            <div className="divTableCell">
                                                                {
                                                                    object.selection != null && object.selection != undefined &&
                                                                    <ul>
                                                                        {object.selection.map(
                                                                            (ball, j) =>
                                                                                ball != null &&
                                                                                ball != undefined && (
                                                                                    <li className="ball" key={j}>
                                                                                        {ball}
                                                                                    </li>
                                                                                )
                                                                        )}
                                                                    </ul>
                                                                }
                                                            </div>
                                                            <div className="divTableCell">
                                                                {
                                                                    object.event_result !== null && object.event_result !== undefined &&
                                                                    <ul>
                                                                        {object.event_result.map(
                                                                            (ball, j) =>
                                                                                ball != null &&
                                                                                ball != undefined && (
                                                                                    <li className="ball" key={j}>
                                                                                        {ball}
                                                                                    </li>
                                                                                )
                                                                        )}
                                                                    </ul>
                                                                }
                                                            </div>
                                                            <div className="divTableCell">
                                                                <div className="cnVertical">
                                                                    {object.outcome && object.outcome}
                                                                </div>
                                                            </div>
                                                            {/* <div className="divTableCell">
                                                                <div className="cnVertical">
                                                                    {object.max_odds && object.max_odds}
                                                                </div>
                                                            </div> */}
                                                            <div className="divTableCell">
                                                                <div className="cnVertical">{object.stake && object.stake}</div>
                                                            </div>
                                                            <div className={"divTableCell " + object.win}>
                                                                {object.win}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            )}

            {/* HOTLINKS */}
            <div className="cnHotLinks">
                <ul className="cnClear">
                    <li className="cnDel">
                        <button
                            onClick={() => {
                                _delete_all_bets();
                            }}
                        >
                            <img src="./images/icn-del.svg" alt="Keno" />
                            <p>{Language.translations.Slip.delete[_user_language]}</p>
                        </button>
                    </li>
                    <li
                        className="cnCan"
                        onClick={() => {
                            _set_cancel_ticket_modal_show(true);
                        }}
                    >
                        <button
                            disabled={_to_disable_cancel(data._remaining_seconds)}
                        >
                            <img src="./images/icn-cancel.svg" alt="Keno" />
                            <p>{Language.translations.Slip.cancel_ticket[_user_language]}</p>
                        </button>
                    </li>
                    <li
                        className="cnPay"
                        onClick={() => {
                            _set_payout_ticket_modal_show(true);
                        }}
                    >
                        <button>
                            <img src="./images/icn-payout.svg" alt="Keno" />
                            <p>{Language.translations.Slip.payout_ticket[_user_language]}</p>
                        </button>
                    </li>
                    <li className="cnPrint">
                        <button
                            ref={inputRef}
                            disabled={_check_to_disable_print(
                                _shop_credit,
                                _add_stakes(_stakes),
                                data._slip.length
                            )}
                            onClick={() => { _print_slip(); }}
                        >
                            <img src="./images/icn-print.svg" alt="Keno" />
                            <p>{Language.translations.Slip.print[_user_language]}</p>
                        </button>
                    </li>
                </ul>
            </div>

            {/* JACKPOT TICKET */}
            <div ref={componentRef}>
                <JackpotTicket
                    bets={_ticket_bets}
                    min_odds={_ticket_min_odds}
                    max_odds={_ticket_max_odds}
                    min_potential_win={_ticket_min_potential_win}
                    max_potential_win={_ticket_max_potential_win}
                    total_stakes={_ticket_total_stakes}
                    max_total_win={_ticket_max_total_win}
                    date={_ticket_date}
                    time={_ticket_time}
                    name={localStorage.getItem("name")}
                    shop_name={localStorage.getItem("shop_name")}
                    barcode={_barcode}
                />
            </div>

            {/* CANCEL TICKET */}
            {_cancel_ticket_modal_show == true && (
                <div className="modal-settings cnCenter">
                    <div className="cnInner">
                        <div className="cnTop">
                            <h1>
                                {Language.translations.Slip.cancel_ticket[_user_language]}
                            </h1>
                            <div className="cnClose">
                                <button
                                    onClick={() => {
                                        _remove_bet_list();
                                        _set_did_cancel(false);
                                        _set_cancel_ticket_modal_show(false);
                                        _set_cancel_ticket_error();
                                        _set_cancel_ticket_number("");
                                    }}
                                ></button>
                            </div>
                        </div>
                        <div className="cnBody">
                            <div className="center-element">
                                {_did_cancel == false && (
                                    <div className="form">
                                        <label>
                                            {
                                                Language.translations.Slip.enter_ticket_number[
                                                _user_language
                                                ]
                                            }
                                        </label>
                                        <input
                                            value={_cancel_ticket_number}
                                            onChange={(e) => {
                                                _set_cancel_ticket_number(e.target.value);
                                            }}
                                            type="text"
                                        ></input>
                                        <button
                                            onClick={() => {
                                                _cancel_played_ticket();
                                            }}
                                        >
                                            {Language.translations.Slip.apply[_user_language]}
                                        </button>
                                        {_cancel_ticket_error != undefined &&
                                            _cancel_ticket_error != null && (
                                                <div className="error-message">
                                                    <ul>
                                                        {_cancel_ticket_error.map((item, index) => (
                                                            <li key={index}>{item}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                    </div>
                                )}
                                {_did_cancel == true && (
                                    <h1 className="cnCancelTicket">{Language.translations.Slip.ticket_was_successfully_cancelled[_user_language]}</h1>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* PAYOUT TICKET */}
            {_payout_ticket_modal_show == true && (
                <div className="modal-settings cnCenter">
                    <div className="cnInner">
                        <div className="cnTop">
                            <h1>
                                {Language.translations.Slip.payout_ticket[_user_language]}
                            </h1>
                            <div className="cnClose">
                                <button
                                    onClick={() => {
                                        _remove_bet_list();
                                        _set_payout_ticket_modal_show(false);
                                        _set_payout_ticket_error();
                                    }}
                                ></button>
                            </div>
                        </div>
                        <div className="cnBody">
                            <div className="center-element">
                                <div className="form">
                                    <label>
                                        {
                                            Language.translations.Slip.enter_ticket_number[
                                            _user_language
                                            ]
                                        }
                                    </label>
                                    <input

                                        value={_payout_ticket_number}
                                        onChange={(e) => {
                                            _set_payout_ticket_number(e.target.value);
                                        }}
                                        type="text"
                                    ></input>
                                    <button
                                        onClick={() => {
                                            _pay_ticket(_payout_ticket_number);
                                        }}
                                    >
                                        {Language.translations.Slip.apply[_user_language]}
                                    </button>
                                    {_payout_ticket_error != undefined &&
                                        _payout_ticket_error != null && (
                                            <div className="error-message">
                                                <ul>
                                                    {_payout_ticket_error.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {_payout_confirm_modal == true &&
                _payout_confirm_data != undefined &&
                _payout_confirm_data != null && (
                    <div className="modal-settings cnCenter">
                        <div className="cnInner">
                            <div className="cnTop">
                                <h1>{Language.translations.Slip.confirm_payout[_user_language]}</h1>
                                <div className="cnClose">
                                    <button
                                        onClick={() => {
                                            _remove_bet_list();
                                            _set_payout_ticket_number("");
                                            _set_payout_confirm_modal(false);
                                            _set_payout_confirm_data();
                                            _set_confirm_payout(false);

                                        }}
                                    ></button>
                                </div>
                            </div>
                            <div className="cnBody">
                                <div className="center-element">
                                    <div className="form">
                                        {_confirm_payout == false && (
                                            <>
                                                {
                                                    _payout_confirm_data.jackpot == false &&
                                                    <>
                                                        <div className="desc">
                                                            {Language.translations.Slip.are_you_sure_you_want_to_pay[_user_language]}{" "}
                                                            <strong>
                                                                {_payout_confirm_data.net_payout}
                                                                {localStorage.getItem("currency_symbol")}
                                                            </strong>{" "}
                                                            ?
                                                        </div>
                                                        <button
                                                            className="cancel"
                                                            onClick={() => {
                                                                _set_payout_ticket_number("");
                                                                _set_payout_confirm_modal(false);
                                                                _set_payout_confirm_data();
                                                                _set_confirm_payout(false);
                                                            }}
                                                        >
                                                            {Language.translations.Header.no[_user_language]}
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                _confirm_payment();
                                                            }}
                                                        >
                                                            {Language.translations.Header.yes[_user_language]}
                                                        </button>
                                                    </>
                                                }
                                                {
                                                    _payout_confirm_data.jackpot == true &&
                                                    <>
                                                        <div className="desc desc-jackpot">
                                                            This person won jackpot of{" "}
                                                            <strong>
                                                                {_payout_confirm_data.net_payout}
                                                                {localStorage.getItem("currency_symbol")}
                                                            </strong>{" "}
                                                        </div>
                                                        {
                                                            jackpot_user_verification == false &&
                                                            <div className="form-jackpot cnFormJackWinner">
                                                                <div className="form-wrapper">
                                                                    <div className="form-label">
                                                                        <b>Full Name</b>
                                                                    </div>
                                                                    <div className="form-input">
                                                                        <input value={jackpot_winner_fullName} onChange={(e) => { set_jackpot_winner_fullName(e.target.value) }} type="text" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-wrapper">
                                                                    <div className="form-label">
                                                                        <b>Full Address</b>
                                                                    </div>
                                                                    <div className="form-input">
                                                                        <input value={jackpot_winner_fullAddress} onChange={(e) => { set_jackpot_winner_fullAddress(e.target.value) }} type="text" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-wrapper">
                                                                    <div className="form-label">
                                                                        <b>Phone Number</b>
                                                                    </div>
                                                                    <div className="form-input">
                                                                        <input value={jackpot_winner_phoneNumber} onChange={(e) => { set_jackpot_winner_phoneNumber(e.target.value) }} type="text" />
                                                                    </div>
                                                                </div>
                                                                <div className="form-wrapper">
                                                                    <div className="form-label">
                                                                        <b>CIN</b>
                                                                    </div>
                                                                    <div className="form-input">
                                                                        <input value={jackpot_winner_cin} onChange={(e) => { set_jackpot_winner_cin(e.target.value) }} type="text" />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    confirm_jackpot_submit == false &&
                                                                    <div className="submit">
                                                                        <button onClick={() => { set_confirm_jackpot_submit(true) }}>Submit</button>
                                                                    </div>
                                                                }
                                                                {
                                                                    confirm_jackpot_submit == true &&
                                                                    <div className="confirm-submit">
                                                                        <button onClick={() => { updateJackpotWinnerInfo(jackpot_winner_fullName, jackpot_winner_fullAddress, jackpot_winner_phoneNumber, _payout_ticket_number, jackpot_winner_cin) }}>Confirm Submission</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            jackpot_user_verification == true &&
                                                            <div className="loader-check-connection">
                                                                <ColorRing
                                                                    visible={true}
                                                                    height="80"
                                                                    width="80"
                                                                    ariaLabel="blocks-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass="blocks-wrapper"
                                                                    colors={[
                                                                        "#003779",
                                                                        "#ff171b",
                                                                        "#ff8400",
                                                                        "#008032",
                                                                        "#ffcc00",
                                                                    ]}
                                                                />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        )}
                                        {_confirm_payout == true && (
                                            <>
                                                {
                                                    _payout_confirm_data.jackpot == false &&
                                                    <>
                                                        <h1 className="success">
                                                            {Language.translations.Slip.you_can_pay[_user_language]}{" "}
                                                            <strong>
                                                                {_payout_confirm_data.net_payout}
                                                                {localStorage.getItem("currency_symbol")}
                                                            </strong>
                                                        </h1>
                                                        <div className="optional">
                                                            <div className="optional-desc">
                                                                <p>* {Language.translations.Slip.optional[_user_language]}</p>
                                                            </div>
                                                            <button onClick={() => { handlePrintReceipt() }}>{Language.translations.Slip.proof_of_payment[_user_language]}</button>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    _payout_confirm_data.jackpot == true &&
                                                    <>
                                                        <h1 className="success">
                                                            Kindly reach out to Mr. Chris and Mr. Vipin for guidance on what steps to take next.
                                                        </h1>
                                                        <div className="optional">
                                                            <div className="optional-desc">
                                                                <b>
                                                                    <p>[FR] : * Imprimez deux reçus comme preuve que le jackpot a été gagné. Un reçu sera remis à l'heureux gagnant, et l'autre sera protégé par plastification et conservé en lieu sûr dans la boutique.</p>
                                                                    <br></br>
                                                                    <p>[MG] : * Manova rosia roa ho porofo fa nahazo ny jackpot. Ny tapakila iray dia homena ny mpandresy tsara vintana, ary ny iray hafa dia harovana amin'ny plastification voaaro ao amin'ny fivarotana.</p>
                                                                </b>
                                                            </div>
                                                            <button onClick={() => { handlePrintReceipt() }}>Jackpot Proof</button>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            }

            <div ref={receiptComponent} className={'wapa'}>
                <JackpotWinningReceipt
                    info={winning_ticket_data}
                    person_info={jackpot_lucky_winner_infos}
                />
            </div>

        </>
    )
}

export default JackpotSlip

function changeTimeZone(date, timeZone) {
    if (typeof date === "string") {
        return new Date(
            new Date(date).toLocaleString("en-US", {
                timeZone,
            })
        );
    }

    return new Date(
        date.toLocaleString("en-US", {
            timeZone,
        })
    );
}

function _multiply(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
        sum += array[i]["odds"] * array[i]["stakes"];
    }
    return sum;
}

function _add_stakes(array) {
    var sum = 0;
    for (var i = 0; i < array.length; i++) {
        sum += parseInt(array[i]["stakes"]);
    }
    return sum;
}

function returnTime(string) {
    var arr = string.split(" ");
    return arr[1];
}

function _check_to_disable_print(_shop_credit, added_stakes, slip_length) {
    if (added_stakes > _shop_credit) {
        return true;
    }
    if (isNaN(added_stakes)) {
        return true;
    }
    if (added_stakes < 1000) {
        return true;
    }
    if (slip_length == 0) {
        return true;
    }
    return false;
}

function _to_disable_cancel(time) {
    if (time <= 1920) {
        return true;
    } else {
        return false;
    }
}