import React, { useEffect, useState, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Language from "../assets/language.json";
import { useReactToPrint } from "react-to-print";
import KenoTicket from "./KenoTicket";
import { ToastContainer, toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import Keyboard from "react-simple-keyboard";
import WinningReceipt from "./WinningReceipt";
import "react-simple-keyboard/build/css/index.css";
import $ from "jquery";

function KenoSlip(data) {
	/*
	 */
	const keyboard = useRef();
	const componentRef = useRef();
	const receiptComponent = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	const handlePrintReceipt = useReactToPrint({
		content: () => receiptComponent.current,
	});
	const inputEl = useRef(null);
	const inputElTotalStakes = useRef(null);
	const inputRef = React.useRef(null)

	/*
	 */
	const [_stakes, _set_stakes] = useState(data._slip);
	const [_user_language, _set_user_language] = useState(
		localStorage.getItem("language")
	);

	/*
	 */
	const [please_wait, set_please_wait] = useState(false);
	const [_cancel_ticket_modal_show, _set_cancel_ticket_modal_show] =
		useState(false);
	const [_payout_ticket_modal_show, _set_payout_ticket_modal_show] =
		useState(false);

	/*
	 */
	const [_barcode, _set_barcode] = useState();
	let [_ticket_bets, _set_ticket_bets] = useState();
	let [_ticket_date, _set_ticket_date] = useState();
	let [_ticket_max_odds, _set_ticket_max_odds] = useState();
	let [_ticket_max_potential_win, _set_ticket_max_potential_win] = useState();
	let [_ticket_max_total_win, _set_ticket_max_total_win] = useState();
	let [_ticket_min_odds, _set_ticket_min_odds] = useState();
	let [_ticket_min_potential_win, _set_ticket_min_potential_win] = useState();
	let [_ticket_time, _set_ticket_time] = useState();
	let [_ticket_total_stakes, _set_ticket_total_stakes] = useState();
	let [_total_stake_modal, _set_total_stake_modal] = useState(false);
	let [_stake_modal, _set_stake_modal] = useState();

	/*
	 */
	let [_cancel_ticket_number, _set_cancel_ticket_number] = useState("");
	let [_payout_ticket_number, _set_payout_ticket_number] = useState("");
	let [_cancel_ticket_error, _set_cancel_ticket_error] = useState();
	let [_payout_ticket_error, _set_payout_ticket_error] = useState();

	/*
	 */
	let [individual_stake_modal, set_individual_stake_modal] = useState(false);
	let [individual_stake_amount, set_individual_stake_amount] = useState();
	let [individual_stake_data, set_individual_stake_data] = useState();
	let [_ticket_details, _set_ticket_details] = useState();
	let [_ticket_details_modal, _set_ticket_details_modal] = useState(false);
	let [_payout_confirm_modal, _set_payout_confirm_modal] = useState(false);
	let [_payout_confirm_data, _set_payout_confirm_data] = useState();
	let [_confirm_payout, _set_confirm_payout] = useState(false);
	let [_did_cancel, _set_did_cancel] = useState(false);
	let [_shop_credit, _set_shop_credit] = useState();
	let [winning_ticket_data, set_winning_ticket_data] = useState();


	/*
	 */
	const _change_stakes = (i, val) => {
		if (val == undefined || val == null || isNaN(val)) {
			let tmp = _stakes;
			tmp[i]["stakes"] = 0;
			_set_stakes(tmp);
		} else {
			let tmp = _stakes;
			tmp[i]["stakes"] = val;
			_set_stakes(tmp);
		}
	};

	/*
	 */
	const _remove_single_bet = async (i) => {
		data._remove_row(i);
	};

	/*
	 */
	const _delete_all_bets = async () => {
		data._remove_all();
	};

	/*
	 */
	const allEqual = (arr) => arr.every((v) => v.stakes === arr[0]["stakes"]);

	/*
	 */
	const _set_all_stakes_to_same = (val) => {
		let value = val;
		if (value == "") {
			value = 0;
		}
		let tmp = _stakes;
		for (let i = 0; i < tmp.length; i++) {
			const element = tmp[i];
			element["stakes"] = val;
		}
		_set_stakes(tmp);
	};

	/*
	 */
	const _print_slip = async () => {

		if (_stakes[0].ball.length > 0) {
			for (let i = 0; i < _stakes[0].ball.length; i++) {
				const ball = _stakes[0].ball[i];
				if (ball > 80) {
					toast.error(Language.translations.login.toast.something_wrong);
					return;
				}
			}
		}


		set_please_wait(true);
		var today = changeTimeZone(new Date(), localStorage.getItem("timezone"));
		/*
		 */
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem("auth_token"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				bets: _stakes,
				min_odds: Math.min(..._stakes.map((item) => item.odds)),
				max_odds: Math.max(..._stakes.map((item) => item.odds)),
				min_potential_win: Math.min(
					..._stakes.map((item) => item.odds * item.stakes)
				),
				max_potential_win: _multiply(_stakes),
				total_stakes: _add_stakes(_stakes),
				max_total_win: _multiply(_stakes),
				user_id: localStorage.getItem("user_id"),
				date:
					today.getFullYear() +
					"-" +
					(today.getMonth() + 1) +
					"-" +
					today.getDate(),
				time:
					today.getHours() +
					":" +
					today.getMinutes() +
					":" +
					today.getSeconds(),
				name: localStorage.getItem("name"),
				on_going_id_bet: data._on_going_play,
			}),
		};

		fetch(
			process.env.REACT_APP_API_URL +
			"game-one-post-bet/" +
			localStorage.getItem("user_id"),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.message == "success") {
					_set_ticket_bets(data.request.bets);
					_set_ticket_date(data.date);
					_set_ticket_max_odds(data.request.max_odds);
					_set_ticket_max_potential_win(data.request.max_potential_win);
					_set_ticket_max_total_win(data.request.max_total_win);
					_set_ticket_max_total_win(data.request.max_total_win);
					_set_ticket_min_odds(data.request.min_odds);
					_set_ticket_min_potential_win(data.request.min_potential_win);
					_set_ticket_time(data.request.time);
					_set_ticket_total_stakes(data.request.total_stakes);
					_set_barcode(data.barcode);
					setTimeout(function () {
						handlePrint();
						_delete_all_bets();
						_update_money();
					}, 200);
					setTimeout(function () {
						_set_ticket_bets(null);
						_set_ticket_date(null);
						_set_ticket_max_odds(null);
						_set_ticket_max_potential_win(null);
						_set_ticket_max_total_win(null);
						_set_ticket_max_total_win(null);
						_set_ticket_min_odds(null);
						_set_ticket_min_potential_win(null);
						_set_ticket_time(null);
						_set_ticket_total_stakes(null);
						_set_barcode(null);
						set_please_wait(false);
					}, 1500);
					return;
				} else {
					_delete_all_bets();
					set_please_wait(false);
					toast.error(Language.translations.login.toast.something_wrong);
					return;
				}
			});
	};

	const _get_details_about_ticket = async (ticket_number) => {
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem("auth_token"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				barcode: ticket_number,
			}),
		};
		fetch(
			process.env.REACT_APP_API_URL +
			"game-one-bet-details/" +
			localStorage.getItem("user_id"),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.message == "bet_found") {
					_set_ticket_details(data);
					_set_ticket_details_modal(true);
				}
			});
	};

	const _pay_ticket = async (ticket_number) => {
		let error = [];
		if (
			ticket_number == "" ||
			ticket_number == undefined ||
			ticket_number == null
		) {
			error.push(Language.translations.Slip.cannot_be_empty[_user_language]);
			_set_payout_ticket_error(error);
			setTimeout(function () {
				_set_payout_ticket_error();
			}, 20000);
			return;
		}
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem("auth_token"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ barcode: ticket_number }),
		};
		fetch(
			process.env.REACT_APP_API_URL +
			"keno-payout/" +
			localStorage.getItem("user_id"),
			requestOptions
		)
			.then((response) => response.json())
			.then(async (data) => {
				if (data.message == "not_paid_out") {
					_set_payout_ticket_modal_show(false);
					_set_payout_confirm_data(data);
					_set_payout_confirm_modal(true);
					return;
				}
				if (data.message == "pending") {
					_set_payout_ticket_number("");
					error.push(Language.translations.Slip.awaiting_draw[_user_language]);
					_set_payout_ticket_error(error);
					setTimeout(function () {
						_set_payout_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "paid_out") {
					_set_payout_ticket_number("");
					error.push(Language.translations.Slip.paid_out[_user_language]);
					_set_payout_ticket_error(error);
					setTimeout(function () {
						_set_payout_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "lost") {
					_set_payout_ticket_number("");
					error.push(Language.translations.Slip.lost_ticket[_user_language]);
					_set_payout_ticket_error(error);
					setTimeout(function () {
						_set_payout_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "cancelled") {
					_set_payout_ticket_number("");
					error.push(Language.translations.Slip.ticket_cancelled[_user_language]);
					_set_payout_ticket_error(error);
					setTimeout(function () {
						_set_payout_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_not_found") {
					_set_payout_ticket_number(Language.translations.Slip.ticket_invalid[_user_language]);
					error.push("invalid");
					_set_payout_ticket_error(error);
					setTimeout(function () {
						_set_payout_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "expired") {
					_set_payout_ticket_number("");
					error.push(Language.translations.Slip.ticket_expired[_user_language]);
					_set_payout_ticket_error(error);
					setTimeout(function () {
						_set_payout_ticket_error();
					}, 20000);
					return;
				}

				error.push(Language.translations.Slip.something_wrong_try_again[_user_language]);
				_set_payout_ticket_error(error);
				setTimeout(function () {
					_set_payout_ticket_error();
				}, 20000);
				return;
			});
	};

	const onKeyPressIndividual = (button) => {
		if (button == "{enter}") {
			if (inputEl.current.value < 1000) {
				_change_stakes(individual_stake_data, 1000);
			} else {
				_change_stakes(individual_stake_data, inputEl.current.value);
			}
			set_individual_stake_modal(false);
			set_individual_stake_amount();
			set_individual_stake_data();
			return;
		}
		if (button == "{bksp}") {
			set_individual_stake_amount(individual_stake_amount.toString().slice(0, -1));
		} else {
			set_individual_stake_amount(parseInt(individual_stake_amount + "" + button));
		}
	}

	const onKeyPress = (button) => {
		if (button == "{enter}") {
			if (inputElTotalStakes.current.value < 1000) {
				_set_all_stakes_to_same(1000);
				_set_total_stake_modal(false);
			} else {
				_set_all_stakes_to_same(inputElTotalStakes.current.value);
				_set_total_stake_modal(false);
			}
			return;
		}

		if (button == "{bksp}") {
			_set_stake_modal(_stake_modal.toString().slice(0, -1));
		} else {
			_set_stake_modal(parseInt(_stake_modal + "" + button));
		}
	};

	const _update_money = async () => {
		data._tab_update_money();
		update_shop_credit();
	};

	const _confirm_payment = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem("auth_token"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				barcode: _payout_ticket_number,
			}),
		};
		fetch(
			process.env.REACT_APP_API_URL +
			"keno-confirm-payout/" +
			localStorage.getItem("user_id"),
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.message == "paid_out") {
					_update_money();
					_set_confirm_payout(true);
					set_winning_ticket_data(data);
					_remove_bet_list();
				} else {
					_update_money();
					_set_payout_ticket_number("");
					_set_payout_confirm_modal(false);
					_set_payout_confirm_data();
					_set_confirm_payout(false);
				}
			});
	};

	const _cancel_played_ticket = async () => {
		let error = [];
		if (
			_cancel_ticket_number == "" ||
			_cancel_ticket_number == undefined ||
			_cancel_ticket_number == null
		) {
			error.push("cannot be empty");
			_set_cancel_ticket_error(error);
			setTimeout(function () {
				_set_cancel_ticket_error();
			}, 20000);
			return;
		}

		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem("auth_token"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ barcode: _cancel_ticket_number }),
		};
		fetch(
			process.env.REACT_APP_API_URL +
			"keno-cancel-bet/" +
			localStorage.getItem("user_id"),
			requestOptions
		)
			.then((response) => response.json())
			.then(async (data) => {
				if (data.message == "bet_not_found") {
					error.push(Language.translations.Slip.ticket_invalid[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_already_finalized") {
					error.push(Language.translations.Slip.ticket_finalized[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_already_cancelled") {
					error.push(Language.translations.Slip.ticket_already_cancelled[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_already_paid_out") {
					error.push(Language.translations.Slip.paid_out[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_not_paid_out") {
					error.push(Language.translations.Slip.not_paid_out[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_lost") {
					error.push(Language.translations.Slip.bet_lost[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_expired") {
					error.push(Language.translations.Slip.expired_ticket[_user_language]);
					_set_cancel_ticket_error(error);
					setTimeout(function () {
						_set_cancel_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "bet_cancelled") {
					_update_money();
					_set_did_cancel(true);
					_remove_bet_list();
					return;
				}
				error.push(Language.translations.Slip.something_wrong_try_again[_user_language]);
				_set_cancel_ticket_error(error);
				setTimeout(function () {
					_set_cancel_ticket_error();
				}, 20000);
				return;
			});
	};

	const _selection_change_stake = async (i, amount) => {
		set_individual_stake_amount(amount);
		set_individual_stake_modal(true);
		set_individual_stake_data(i);
	}

	useEffect(() => {
		if (data._current_time <= 11) {
			_set_total_stake_modal(false);
			set_individual_stake_modal(false);
			set_individual_stake_amount();
			set_individual_stake_data();
		}
	});

	const update_shop_credit = async () => {
		if (localStorage.getItem('user_id')) {
			const requestOptions = {
				method: "POST",
				headers: {
					Authorization: localStorage.getItem('auth_token'),
					"Content-Type": "application/json",
				},
			};
			fetch(process.env.REACT_APP_API_URL + "shop-credit/" + localStorage.getItem('user_id'), requestOptions)
				.then((response) => response.json())
				.then(async (data) => {
					_set_shop_credit(data.credit)
				});
		}
	}

	const _remove_bet_list = async () => {
		data.remove_bet_list();
	}

	function enterPrint() {
		let _is_possible_to_print = _check_to_disable_print(_shop_credit, _add_stakes(_stakes), data._slip.length);
		if (_is_possible_to_print == false) {
			inputRef.current.click()
		}
	}

	useEffect(() => {
		update_shop_credit();
	}, [])

	React.useEffect(() => {
		data.childFunc.current = () => _from_tab_check_ticket(data.childFunc);
		data.enterFunc.current = () => enterPrint();
	}, [])


	function _from_tab_check_ticket(tab_data) {
		_set_payout_ticket_modal_show(true);
		_set_payout_ticket_number(tab_data.current.fastbet);
		_pay_ticket(tab_data.current.fastbet);
	}

	useEffect(() => {
		if (individual_stake_modal == true) {
			inputEl.current.select();
			document.querySelector('.indiv-stakes').addEventListener('keypress', function (e) {
				if (e.key === 'Enter') {
					if (inputEl.current.value < 1000) {
						_change_stakes(individual_stake_data, 1000);
					} else {
						_change_stakes(individual_stake_data, inputEl.current.value);
					}
					set_individual_stake_modal(false);
					set_individual_stake_amount();
					set_individual_stake_data();
				}
			});
		}
	}, [individual_stake_modal])

	useEffect(() => {
		if (_total_stake_modal == true) {
			inputElTotalStakes.current.select();
			document.querySelector('.total-indiv-stakes').addEventListener('keypress', function (e) {
				if (e.key === 'Enter') {
					if (inputElTotalStakes.current.value < 1000) {
						_set_all_stakes_to_same(1000);
						_set_total_stake_modal(false);
					} else {
						_set_all_stakes_to_same(inputElTotalStakes.current.value);
						_set_total_stake_modal(false);
					}
				}
			});
		}
	}, [_total_stake_modal])

	useEffect(() => {
		if (individual_stake_modal == false && _total_stake_modal == false && data._is_settings_open == false && _payout_ticket_modal_show == false && _payout_confirm_modal == false && data._show_ticket_of_the_day == false && _cancel_ticket_modal_show == false) {
			if ($('#root').hasClass('open-sidebar')) {
				//do nothing 
			} else {
				document.getElementById("fastbet-input").focus();
			}
		}
	})




	return (
		<>
			{
				individual_stake_modal == true &&
				<div className="modal-settings indiv-stakes">
					<div className="cnInner">
						<div className="cnTop">
							<h1>
								{Language.translations.Slip.individual_stake[_user_language]}
							</h1>
							<div className="cnClose">
								<button
									onClick={() => {
										if (inputEl.current.value < 1000) {
											_change_stakes(individual_stake_data, 1000);
										} else {
											_change_stakes(individual_stake_data, inputEl.current.value);
										}
										set_individual_stake_modal(false);
										set_individual_stake_amount();
										set_individual_stake_data();
									}}
								></button>
							</div>
						</div>
						<div className="cnBody">
							<input
								id="indiv-stakes-id"
								ref={inputEl}
								step="1000"
								max="100000000"
								min="1000"
								type="number"
								className="cnInputCal"
								onChange={(e) => {
									set_individual_stake_amount(e.target.value)
								}}
								value={individual_stake_amount}
							/>
							<Keyboard
								keyboardRef={(r) => (keyboard.current = r)}
								theme={"hg-theme-default hg-layout-numeric numeric-theme"}
								layoutName={"default"}
								disableCaretPositioning={true}
								layout={{
									default: [
										"1 2 3",
										"4 5 6",
										"7 8 9",
										"0",
										"{bksp} {enter}",
									],
								}}
								onKeyPress={onKeyPressIndividual}
							/>
						</div>
					</div>
				</div>
			}
			{_payout_confirm_modal == true &&
				_payout_confirm_data != undefined &&
				_payout_confirm_data != null && (
					<div className="modal-settings cnCenter">
						<div className="cnInner">
							<div className="cnTop">
								<h1>{Language.translations.Slip.confirm_payout[_user_language]}</h1>
								<div className="cnClose">
									<button
										onClick={() => {
											_remove_bet_list();
											_set_payout_ticket_number("");
											_set_payout_confirm_modal(false);
											_set_payout_confirm_data();
											_set_confirm_payout(false);

										}}
									></button>
								</div>
							</div>
							<div className="cnBody">
								<div className="center-element">
									<div className="form">
										{_confirm_payout == false && (
											<>
												<div className="desc">
													{Language.translations.Slip.are_you_sure_you_want_to_pay[_user_language]}{" "}
													<strong>
														{_payout_confirm_data.net_payout}
														{localStorage.getItem("currency_symbol")}
													</strong>{" "}
													?
												</div>
												<button
													className="cancel"
													onClick={() => {
														_set_payout_ticket_number("");
														_set_payout_confirm_modal(false);
														_set_payout_confirm_data();
														_set_confirm_payout(false);
													}}
												>
													{Language.translations.Header.no[_user_language]}
												</button>
												<button
													onClick={() => {
														_confirm_payment();
													}}
												>
													{Language.translations.Header.yes[_user_language]}
												</button>
											</>
										)}
										{_confirm_payout == true && (
											<>
												<h1 className="success">
													{Language.translations.Slip.you_can_pay[_user_language]}{" "}
													<strong>
														{_payout_confirm_data.net_payout}
														{localStorage.getItem("currency_symbol")}
													</strong>
												</h1>
												<div className="optional">
													<div className="optional-desc">
														<p>* {Language.translations.Slip.optional[_user_language]}</p>
													</div>
													<button onClick={() => { handlePrintReceipt() }}>{Language.translations.Slip.proof_of_payment[_user_language]}</button>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			{_total_stake_modal == true &&
				_stakes != null &&
				_stakes != undefined &&
				_stakes.length > 0 && (
					<div className="modal-settings total-stakes">
						<div className="cnInner">
							<div className="cnTop">
								<h1>
									{
										Language.translations.Slip.total_stakes_modal[
										_user_language
										]
									}
								</h1>
								<div className="cnClose">
									<button
										onClick={() => {
											if (inputElTotalStakes.current.value < 1000) {
												_set_all_stakes_to_same(1000);
												_set_total_stake_modal(false);
											} else {
												_set_all_stakes_to_same(inputElTotalStakes.current.value);
												_set_total_stake_modal(false);
											}
										}}
									></button>
								</div>
							</div>
							<div className="cnBody">
								<input className="cnInputCal total-indiv-stakes"
									ref={inputElTotalStakes}
									step="1000"
									max="100000000"
									min="1000"
									type="number"
									onChange={(e) => {
										_set_stake_modal(e.target.value);
									}}
									value={_stake_modal}
								/>
								<Keyboard
									keyboardRef={(r) => (keyboard.current = r)}
									theme={"hg-theme-default hg-layout-numeric numeric-theme"}
									layoutName={"default"}
									disableCaretPositioning={true}
									layout={{
										default: [
											"1 2 3",
											"4 5 6",
											"7 8 9",
											"0",
											"{bksp} {enter}",
										],
									}}
									onKeyPress={onKeyPress}
								/>
							</div>
						</div>
					</div>
				)}
			{please_wait == true && (
				<div className="no-more-bets">
					<div className="cnInner">
						<div className="cnTopLogoKeno"></div>
						<h1>{Language.translations.Slip.please_wait[_user_language]}</h1>
					</div>
				</div>
			)}
			{data._show_ticket_of_the_day == false && (
				<>
					<Scrollbars>
						<div className="divTable">
							<div className="divTableBody">
								<div className="divTableRow">
									<div className="divTableCell1 cellSelect cnTableHead noBorderTop noBorderLeft noBorderBottom">
										{Language.translations.Slip.selection[_user_language]}
									</div>
									<div className="divTableCell1 cellOdds cnTableHead noBorderTop noBorderBottom">
										{Language.translations.Slip.odds[_user_language]}
									</div>
									<div className="divTableCell1 cellStake cnTableHead noBorderTop noBorderBottom">
										{Language.translations.Slip.stake[_user_language]}
									</div>
									<div className="divTableCell1 cellRemove cnTableHead noBorderTop noBorderRight noBorderBottom">
										&nbsp;
									</div>
								</div>
								{_stakes !== undefined &&
									_stakes !== null &&
									_stakes.map((object, i) => (
										<div className="divTableRow cnColor" key={i}>
											<div className="divTableCell1 cellSelect cnVerticalAlign cnClear">
												<div className="cnImg">
													<img
														className="cnSmall"
														src="./images/keno-black.svg"
														alt="Keno"
													/>
												</div>
												<ul>
													{object.ball !== undefined &&
														object.ball !== null &&
														object.ball.map((ball, index_2) => (
															<li className="ball" key={index_2}>
																{ball}
															</li>
														))}
												</ul>
											</div>
											<div className="divTableCell1 cellOdds cnVerticalAlign">
												{object.ball.length == 8
													? "2500"
													: object.ball.length == 7
														? "1000"
														: object.ball.length == 6
															? "500"
															: object.ball.length == 5
																? "150"
																: object.ball.length == 4
																	? "80"
																	: object.ball.length == 3
																		? "50"
																		: object.ball.length == 2
																			? "10"
																			: object.ball.length == 1
																				? "3.6"
																				: null}
											</div>
											<div className="divTableCell1 cellStake cnVerticalAlign">
												<div className="inner">
													<input
														onClick={() => {
															_selection_change_stake(i, object.stakes);
														}}
														value={
															object.stakes == "" ? 0 : Math.round(object.stakes)
														}
														type="text"
														onChange={(e) => {
															_change_stakes(i, e.target.value);
														}}
														onFocus={(e) => {
															e.target.select();
														}}
													/>
													<button
														className="button-calc"
														onClick={() => {
															_selection_change_stake(i, object.stakes);
														}}
													></button>
												</div>
											</div>
											<div className="divTableCell1 cellRemove cnVerticalAlign">
												<button
													onClick={() => {
														_remove_single_bet(i);
													}}
												></button>
											</div>
										</div>
									))}
							</div>
						</div>

						<div className="divTable">
							<div className="divTableBody">
								{_stakes !== undefined &&
									_stakes !== null &&
									_stakes.length > 0 && (
										<>
											<div className="divTableRow">
												<div className="divTableCell1 cnAlignCenter cnRed cnSingle noBorderLeft noBorderRight">
													{Language.translations.Slip.single[_user_language]}
												</div>
											</div>
										</>
									)}
							</div>
						</div>

						<table className="mergeCell">
							<tbody>
								{_stakes !== undefined &&
									_stakes !== null &&
									_stakes.length > 0 && (
										<>
											<tr className="cnTop">
												<td
													rowSpan="2"
													colSpan="1"
													width="10%"
													className="noBorderTop noBorderLeft"
												>
													GR
												</td>
												<td
													rowSpan="2"
													colSpan="1"
													width="15%"
													className="noBorderTop"
												>
													Combi
												</td>
												<td
													rowSpan="1"
													colSpan="2"
													width="45%"
													className="noBorderTop"
												>
													{Language.translations.Slip.odds[_user_language]}
												</td>
												<td
													rowSpan="2"
													colSpan="1"
													width="30%"
													className="noBorderTop noBorderRight"
												>
													{
														Language.translations.Slip.stakes_bet[
														_user_language
														]
													}
												</td>
											</tr>
											<tr className="cnTop">
												<td>Min</td>
												<td>Max</td>
											</tr>
											<tr>
												<td className="noBorderBottom">1</td>
												<td className="noBorderBottom">{_stakes.length}</td>
												<td className="noBorderBottom">
													{Math.min(..._stakes.map((item) => item.odds))}
												</td>
												<td className="noBorderBottom">
													{Math.max(..._stakes.map((item) => item.odds))}
												</td>
												<td className="noBorderBottom cnPadTop">
													<input
														onClick={() => {
															_set_total_stake_modal(true);
															_set_stake_modal(Math.round(_stakes[0]["stakes"]));
														}}
														onChange={(e) => {
															_set_all_stakes_to_same(
																e.target.value.replace(/[^\d.]/g, "")
															);
														}}
														value={
															allEqual(_stakes)
																? _stakes[0]["stakes"] == ""
																	? 0
																	: Math.round(_stakes[0]["stakes"])
																: Math.min(
																	..._stakes.map((item) => item.stakes)
																) +
																"-" +
																Math.max(
																	..._stakes.map((item) => item.stakes)
																)
														}
													/>
													<button
														className="button-calc"
														onClick={() => {
															_set_total_stake_modal(true);
															_set_stake_modal(1000);
														}}
													></button>
												</td>
											</tr>
										</>
									)}
							</tbody>
						</table>

						{_stakes !== undefined && _stakes !== null && _stakes.length > 0 && (
							<>
								<div className="win-payout">
									<div className="potential-min-win cnClear">
										<div className="win-wrapper">
											<div className="cnInner cnClear">
												<div className="cn-left">
													<p>
														{
															Language.translations.Slip.potential_min_win[
															_user_language
															]
														}
													</p>
												</div>
												<div className="cn-right">
													{Math.round(Math.min(
														..._stakes.map((item) => item.odds * item.stakes)
													))}
													{localStorage.getItem("currency_symbol")}
												</div>
											</div>
										</div>
									</div>
									<div className="potential-max-win cnClear">
										<div className="win-wrapper">
											<div className="cnInner cnClear">
												<div className="cn-left">
													<p>
														{
															Language.translations.Slip.potential_max_win[
															_user_language
															]
														}
													</p>
												</div>
												<div className="cn-right">
													{Math.round(_multiply(_stakes))}
													{localStorage.getItem("currency_symbol")}
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</Scrollbars>
				</>
			)}
			{data._show_ticket_of_the_day == true && (
				<>
					<Scrollbars>
						<div className="divTable cnDocument">
							<div className="divTableBody">
								<div className="divTableRow">
									<div className="divTableCell1 cnTableHead noBorderTop noBorderLeft noBorderBottom">
										Info
									</div>
									<div className="divTableCell1 cnTableHead noBorderTop noBorderBottom">
										{Language.translations.Slip.ticket[_user_language]} N0
									</div>
									<div className="divTableCell1 cnTableHead noBorderTop noBorderBottom">
										{Language.translations.Slip.stake[_user_language]}
									</div>
									<div className="divTableCell1 cnTableHead noBorderTop noBorderRight noBorderBottom">
										{Language.translations.Slip.status[_user_language]}
									</div>
								</div>
								{data.all_tickets !== undefined && data.all_tickets !== null ? (
									data.all_tickets
										.slice(0)
										.reverse()
										.map((object, i) => (
											<div className="divTableRow cnColor" key={i}>
												<div className="divTableCell1 noBorderTop noBorderLeft noBorderBottom cnWarning">
													<button
														onClick={() => {
															_get_details_about_ticket(object.ticket_number);
														}}
													>
														<img src="./images/icon-warning.svg" alt="Keno" />
													</button>
												</div>
												<div className="divTableCell1 noBorderTop noBorderBottom">
													{object.ticket_number}
												</div>
												<div className="divTableCell1 noBorderTop noBorderBottom">
													{object.total_stake}
													{localStorage.getItem("currency_symbol")}
												</div>
												<div className="divTableCell1 noBorderTop noBorderRight noBorderBottom">
													{object.payout_status == "paid_out" && Language.translations.Slip.paid[_user_language]}
													{object.payout_status == "not_paid_out" && object.total_payout &&
														<b> {object.total_payout} {localStorage.getItem('currency_symbol')} </b>
													}
													{object.payout_status == "lost" && Language.translations.Slip.lost[_user_language]}
													{object.payout_status == "expired" && Language.translations.Slip.expired[_user_language]}
													{object.payout_status == "cancelled" && Language.translations.Slip.cancelled[_user_language]}
													{object.payout_status == "pending" && Language.translations.Slip.pending[_user_language]}
												</div>
											</div>
										))
								) : (
									<div className="cnTable">
										<div className="cnTableCell">
											<div className="vertical-allign-no-bacground">
												<ColorRing
													visible={true}
													height="80"
													width="80"
													ariaLabel="blocks-loading"
													wrapperStyle={{}}
													wrapperClass="blocks-wrapper"
													colors={[
														"#003779",
														"#ff171b",
														"#ff8400",
														"#008032",
														"#ffcc00",
													]}
												/>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</Scrollbars>
				</>
			)}
			{data._show_ticket_of_the_day == false && (
				<>
					<div className="cnTotalStake">
						<div className="cnInner cnClear">
							<div className="cn-left">
								{Language.translations.Slip.total_stakes[_user_language]}
							</div>
							<div className="cn-right">
								{Math.round(_add_stakes(_stakes))}
								{localStorage.getItem("currency_symbol")}
							</div>
						</div>
						<div className="cnInner cnClear">
							<div className="cn-left">
								{Language.translations.Slip.max_total_win[_user_language]}
							</div>
							<div className="cn-right">
								{Math.round(_multiply(_stakes))}
								{localStorage.getItem("currency_symbol")}
							</div>
						</div>
					</div>
				</>
			)}
			{_cancel_ticket_modal_show == true && (
				<div className="modal-settings cnCenter">
					<div className="cnInner">
						<div className="cnTop">
							<h1>
								{Language.translations.Slip.cancel_ticket[_user_language]}
							</h1>
							<div className="cnClose">
								<button
									onClick={() => {
										_remove_bet_list();
										_set_did_cancel(false);
										_set_cancel_ticket_modal_show(false);
										_set_cancel_ticket_error();
										_set_cancel_ticket_number("");
									}}
								></button>
							</div>
						</div>
						<div className="cnBody">
							<div className="center-element">
								{_did_cancel == false && (
									<div className="form">
										<label>
											{
												Language.translations.Slip.enter_ticket_number[
												_user_language
												]
											}
										</label>
										<input
											value={_cancel_ticket_number}
											onChange={(e) => {
												_set_cancel_ticket_number(e.target.value);
											}}
											type="text"
										></input>
										<button
											onClick={() => {
												_cancel_played_ticket();
											}}
										>
											{Language.translations.Slip.apply[_user_language]}
										</button>
										{_cancel_ticket_error != undefined &&
											_cancel_ticket_error != null && (
												<div className="error-message">
													<ul>
														{_cancel_ticket_error.map((item, index) => (
															<li key={index}>{item}</li>
														))}
													</ul>
												</div>
											)}
									</div>
								)}
								{_did_cancel == true && (
									<h1 className="cnCancelTicket">{Language.translations.Slip.ticket_was_successfully_cancelled[_user_language]}</h1>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			{_payout_ticket_modal_show == true && (
				<div className="modal-settings cnCenter">
					<div className="cnInner">
						<div className="cnTop">
							<h1>
								{Language.translations.Slip.payout_ticket[_user_language]}
							</h1>
							<div className="cnClose">
								<button
									onClick={() => {
										_remove_bet_list();
										_set_payout_ticket_modal_show(false);
										_set_payout_ticket_error();
									}}
								></button>
							</div>
						</div>
						<div className="cnBody">
							<div className="center-element">
								<div className="form">
									<label>
										{
											Language.translations.Slip.enter_ticket_number[
											_user_language
											]
										}
									</label>
									<input

										value={_payout_ticket_number}
										onChange={(e) => {
											_set_payout_ticket_number(e.target.value);
										}}
										type="text"
									></input>
									<button
										onClick={() => {
											_pay_ticket(_payout_ticket_number);
										}}
									>
										{Language.translations.Slip.apply[_user_language]}
									</button>
									{_payout_ticket_error != undefined &&
										_payout_ticket_error != null && (
											<div className="error-message">
												<ul>
													{_payout_ticket_error.map((item, index) => (
														<li key={index}>{item}</li>
													))}
												</ul>
											</div>
										)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{_ticket_details_modal == true && (
				<div className="modal-settings cnLong">
					<div className="cnInner">
						<Scrollbars>
							<div className="cnTop">
								<h1>
									{Language.translations.Slip.ticket_details[_user_language]}
								</h1>
								<div className="cnClose">
									<button
										onClick={() => {
											_set_ticket_details_modal(false);
											_set_ticket_details();
										}}
									></button>
								</div>
							</div>
							<div className="cnBody">
								<div className={"stamp " + _ticket_details.draw_outcome}>
									{
										_ticket_details.draw_outcome == "lost" &&
										<h1>
											{Language.translations.Slip.lost[_user_language]}
										</h1>
									}
									{
										_ticket_details.draw_outcome == "pending" &&
										<h1>
											{Language.translations.Slip.pending[_user_language]}
										</h1>
									}
									{
										_ticket_details.draw_outcome == "won" &&
										<h1>
											{Language.translations.Slip.won[_user_language]}
										</h1>
									}
									{
										_ticket_details.draw_outcome == "cancelled" &&
										<h1>
											{Language.translations.Slip.cancelled[_user_language]}
										</h1>
									}
								</div>
								<div className="row">
									<div className="col col-sm-6">
										<div className="cnTableWrap">
											<div className="divTable">
												<div className="divTableBody">
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.ticket_number[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.ticket_number != undefined &&
																_ticket_details.ticket_number != null &&
																_ticket_details.ticket_number}
														</div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{Language.translations.Slip.time[_user_language]}
														</div>
														<div className="divTableCell">
															{_ticket_details.book_time != undefined &&
																_ticket_details.book_time != null &&
																_ticket_details.book_time}
														</div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.selection[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.selections != undefined &&
																_ticket_details.selections != null &&
																_ticket_details.selections}
														</div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.ticket_type[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.ticket_type != undefined &&
																_ticket_details.ticket_type != null &&
																_ticket_details.ticket_type}
														</div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.status[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.ticket_status != undefined &&
																_ticket_details.ticket_status != null &&
																_ticket_details.ticket_status}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col col-sm-6">
										<div className="cnTableWrap">
											<div className="divTable">
												<div className="divTableBody">
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.total_stakes[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.total_stake != undefined &&
																_ticket_details.total_stake != null &&
																_ticket_details.total_stake}
														</div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.jackpot[
																_user_language
																]
															}
														</div>
														<div className="divTableCell"></div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.total_payout[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.total_payout != undefined &&
																_ticket_details.total_payout != null &&
																_ticket_details.total_payout}
														</div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.winning_tax[
																_user_language
																]
															}
														</div>
														<div className="divTableCell"></div>
													</div>
													<div className="divTableRow">
														<div className="divTableCell">
															{
																Language.translations.Slip.net_payout[
																_user_language
																]
															}
														</div>
														<div className="divTableCell">
															{_ticket_details.net_payout != undefined &&
																_ticket_details.net_payout != null &&
																_ticket_details.net_payoutreturnTime}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col col-sm-12">
										<div className="cnTableWrap">
											<div className="divTable">
												<div className="divTableBody">
													<div className="divTableRow">
														<div className="divTableCell">
															<div className="cnVertical">
																{
																	Language.translations.Slip.time[
																	_user_language
																	]
																}
															</div>
														</div>
														<div className="divTableCell">
															<div className="cnVertical">
																{
																	Language.translations.Slip.selection[
																	_user_language
																	]
																}
															</div>
														</div>
														<div className="divTableCell">
															<div className="cnVertical">
																{
																	Language.translations.Slip.event_result[
																	_user_language
																	]
																}
															</div>
														</div>
														<div className="divTableCell">
															<div className="cnVertical">
																{
																	Language.translations.Slip.outcome[
																	_user_language
																	]
																}
															</div>
														</div>
														<div className="divTableCell">
															<div className="cnVertical">
																Max{" "}
																{
																	Language.translations.Slip.odds[
																	_user_language
																	]
																}
															</div>
														</div>
														<div className="divTableCell">
															<div className="cnVertical">
																{
																	Language.translations.Slip.stake[
																	_user_language
																	]
																}
															</div>
														</div>
														<div className="divTableCell">
															<div className="cnVertical">
																{Language.translations.Slip.win[_user_language]}
															</div>
														</div>
													</div>
													{_ticket_details.bet_list.map((object, i) => (
														<div className="divTableRow" key={i}>
															<div className="divTableCell">
																<div className="cnVertical">
																	{_ticket_details.book_time != undefined &&
																		_ticket_details.book_time != null &&
																		returnTime(_ticket_details.book_time)}
																</div>
															</div>
															<div className="divTableCell">
																{
																	object.selection != null && object.selection != undefined &&
																	<ul>
																		{object.selection.map(
																			(ball, j) =>
																				ball != null &&
																				ball != undefined && (
																					<li className="ball" key={j}>
																						{ball}
																					</li>
																				)
																		)}
																	</ul>
																}
															</div>
															<div className="divTableCell">
																{
																	object.event_result !== null && object.event_result !== undefined &&
																	<ul>
																		{object.event_result.map(
																			(ball, j) =>
																				ball != null &&
																				ball != undefined && (
																					<li className="ball" key={j}>
																						{ball}
																					</li>
																				)
																		)}
																	</ul>
																}
															</div>
															<div className="divTableCell">
																<div className="cnVertical">
																	{object.outcome && object.outcome}
																</div>
															</div>
															<div className="divTableCell">
																<div className="cnVertical">
																	{object.max_odds && object.max_odds}
																</div>
															</div>
															<div className="divTableCell">
																<div className="cnVertical">{object.stake && object.stake}</div>
															</div>
															<div className={"divTableCell " + object.win}>
																{object.win}
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Scrollbars>
					</div>
				</div>
			)}
			<div className="cnHotLinks">
				<ul className="cnClear">
					<li className="cnDel">
						<button
							onClick={() => {
								_delete_all_bets();
							}}
						>
							<img src="./images/icn-del.svg" alt="Keno" />
							<p>{Language.translations.Slip.delete[_user_language]}</p>
						</button>
					</li>
					<li
						className="cnCan"
						onClick={() => {
							_set_cancel_ticket_modal_show(true);
						}}
					>
						<button>
							<img src="./images/icn-cancel.svg" alt="Keno" />
							<p>{Language.translations.Slip.cancel_ticket[_user_language]}</p>
						</button>
					</li>
					<li
						className="cnPay"
						onClick={() => {
							_set_payout_ticket_modal_show(true);
						}}
					>
						<button>
							<img src="./images/icn-payout.svg" alt="Keno" />
							<p>{Language.translations.Slip.payout_ticket[_user_language]}</p>
						</button>
					</li>
					<li className="cnPrint">
						<button
							ref={inputRef}
							disabled={_check_to_disable_print(
								_shop_credit,
								_add_stakes(_stakes),
								data._slip.length
							)}
							onClick={() => {
								_print_slip();
							}}
						>
							<img src="./images/icn-print.svg" alt="Keno" />
							<p>{Language.translations.Slip.print[_user_language]}</p>
						</button>
					</li>
				</ul>
			</div>
			<div ref={componentRef}>
				<KenoTicket
					bets={_ticket_bets}
					min_odds={_ticket_min_odds}
					max_odds={_ticket_max_odds}
					min_potential_win={_ticket_min_potential_win}
					max_potential_win={_ticket_max_potential_win}
					total_stakes={_ticket_total_stakes}
					max_total_win={_ticket_max_total_win}
					date={_ticket_date}
					time={_ticket_time}
					name={localStorage.getItem("name")}
					shop_name={localStorage.getItem("shop_name")}
					barcode={_barcode}
				/>
			</div>

			<div ref={receiptComponent} className={'wapa'}>
				<WinningReceipt
					info={winning_ticket_data}
				/>
			</div>
			<ToastContainer position="top-right" />
		</>
	);
}

export default KenoSlip;

function _multiply(array) {
	var sum = 0;
	for (var i = 0; i < array.length; i++) {
		sum += array[i]["odds"] * array[i]["stakes"];
	}
	return sum;
}

function _add_stakes(array) {
	var sum = 0;
	for (var i = 0; i < array.length; i++) {
		sum += parseInt(array[i]["stakes"]);
	}
	return sum;
}

function _check_to_disable_print(_shop_credit, added_stakes, slip_length) {
	if (added_stakes > _shop_credit) {
		return true;
	}
	if (isNaN(added_stakes)) {
		return true;
	}
	if (added_stakes < 1000) {
		return true;
	}
	if (slip_length == 0) {
		return true;
	}
	return false;
}

function changeTimeZone(date, timeZone) {
	if (typeof date === "string") {
		return new Date(
			new Date(date).toLocaleString("en-US", {
				timeZone,
			})
		);
	}

	return new Date(
		date.toLocaleString("en-US", {
			timeZone,
		})
	);
}

function returnTime(string) {
	var arr = string.split(" ");
	return arr[1];
}
