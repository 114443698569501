import React from "react";
import Barcode from 'react-barcode';

function WinningReceipt(data) {
    return (
        <>
            {
                data.info != null &&
                <div>
                    {
                        data.info.barcode !== undefined && data.info.barcode != null && data.info.userBetDetails != undefined && data.info.userBetDetails !== null && data.info.userBetDetails.length > 0 &&
                        <div id="invoice-POS" className="winning-receipt">
                            <div id="top">
                                <div className="logo">
                                    <img src="./images/keno-ticket-black.svg" />
                                </div>
                                <div className="info">
                                    <h2>BetShop</h2>
                                </div>
                            </div>
                            <div id="mid">
                                <div className="info">
                                    <span>{localStorage.getItem('shop_name')} - {localStorage.getItem('name')}</span>
                                    <span>{changeTimeZone(new Date(), localStorage.getItem("timezone")).getDate().toString().padStart(2, "0") + '-' + (changeTimeZone(new Date(), localStorage.getItem("timezone")).getMonth() + 1).toString().padStart(2, "0") + '-' + changeTimeZone(new Date(), localStorage.getItem("timezone")).getFullYear()}{' - '}{changeTimeZone(new Date(), localStorage.getItem("timezone")).getHours().toString().padStart(2, "0") + ':' + changeTimeZone(new Date(), localStorage.getItem("timezone")).getMinutes().toString().padStart(2, "0") + ':' + changeTimeZone(new Date(), localStorage.getItem("timezone")).getSeconds().toString().padStart(2, "0")}</span>
                                    {
                                        data.info.barcode !== undefined && data.info.barcode != null &&
                                        <span>Ticket: {data.info.barcode.toString()}</span>
                                    }
                                    {
                                        data.info.barcode !== undefined && data.info.barcode != null &&
                                        <div className="cnBarcode">
                                            <Barcode value={data.info.barcode.toString()} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div id="bot">
                                {
                                    data.info.userBetDetails != undefined && data.info.userBetDetails !== null && data.info.userBetDetails.length > 0 &&
                                    data.info.userBetDetails.map((element, i) => (
                                        <div className="divTable cnTableTop" key={i}>
                                            <div className="divTableBody">
                                                <div className="divTableRow">
                                                    <div className="divTableCell  cnWidth1">{element.event_id}</div>
                                                    <div className="divTableCell">&nbsp;</div>
                                                    <div className="divTableCell cnWidth2"><strong>Keno {element.payout == "0.00" ? '( *LOSE )' : '( *WIN )'}</strong></div>
                                                    <div className="divTableCell "><strong>HIT/PLAYED</strong></div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell  cnWidth1">{returnTime(element.bet_time)}</div>
                                                    <div className="divTableCell">&nbsp;</div>
                                                    <div className="divTableCell cnWidth2">
                                                        <ul>
                                                            {
                                                                element.ball_1 && element.ball_1 != null &&
                                                                <li>
                                                                    {element.ball_1}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_2 && element.ball_2 != null &&
                                                                <li>
                                                                    {element.ball_2}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_3 && element.ball_3 != null &&
                                                                <li>
                                                                    {element.ball_3}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_4 && element.ball_4 != null &&
                                                                <li>
                                                                    {element.ball_4}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_5 && element.ball_5 != null &&
                                                                <li>
                                                                    {element.ball_5}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_6 && element.ball_6 != null &&
                                                                <li>
                                                                    {element.ball_6}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_7 && element.ball_7 != null &&
                                                                <li>
                                                                    {element.ball_7}
                                                                </li>
                                                            }
                                                            {
                                                                element.ball_8 && element.ball_8 != null &&
                                                                <li>
                                                                    {element.ball_8}
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="divTableCell ">
                                                        <strong>{element.win}/{element.played}</strong>
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">&nbsp;</div>
                                                    <div className="divTableCell">&nbsp;</div>
                                                    <div className="divTableCell"><strong>Payout:</strong></div>
                                                    <div className="divTableCell"><strong>{element.payout}{localStorage.getItem('currency_symbol')}&nbsp;</strong></div>

                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="divTable cnTableBottom cnHeight" >
                                    <div className="divTableBody">
                                        <div className="divTableRow">
                                            <div className="divTableCell disclaimer"><strong>Congratulations for winning with Baron Bet.</strong>
                                                For further inquiries, please contact us on
                                                <strong>support@baron.bet</strong> or on our hotline <strong>{process.env.REACT_APP_KENO_GAME_HOTLINE}</strong>.
                                                Baron Bet, rue du Tamatave, Antananarivo.
                                                ** Please play responsibly. **
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </>

    )
}

export default WinningReceipt

function returnDate(string) {
    var arr = string.split(" ");
    return arr[0];
}

function returnTime(string) {
    var arr = string.split(" ");
    return arr[1];
}

function changeTimeZone(date, timeZone) {
    if (typeof date === "string") {
        return new Date(
            new Date(date).toLocaleString("en-US", {
                timeZone,
            })
        );
    }

    return new Date(
        date.toLocaleString("en-US", {
            timeZone,
        })
    );
}