import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Language from "../assets/language.json";

function Inactivity() {
	/*
	 */
	const navigate = useNavigate();
	const [_user_language, _set_user_language] = useState(
		localStorage.getItem("language")
	);
	const [_seconds, _set_seconds] = useState(1800);

	document.onmousemove = async () => {
		_reset_timer();
	};

	document.onkeydown = async () => {
		_reset_timer();
	};

	const _logout = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem('auth_token'),
				"Content-Type": "application/json",
			},
		};
		fetch(process.env.REACT_APP_API_URL + "logout/" + localStorage.getItem('user_id'), requestOptions)
			.then((response) => response.json())
			.then(async (data) => {
				localStorage.clear();
				// navigate("/");
				window.location.href = "/";
			});
	};

	const _reset_timer = () => {
		_set_seconds(1800);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (_seconds > 0) {
				const tt = setTimeout(() => {
					if (_seconds < 10) {
						toast.error(
							Language.translations.Inactivity.logging[_user_language] + _seconds + " " + Language.translations.Inactivity.seconds[_user_language]
						);
					}
					_set_seconds(_seconds - 1);
				}, 1000);
				return () => {
					clearTimeout(tt);
				};
			} else {
				_logout();
			}
		}
		return function cleanup() {
			mounted = false;
		};
	}, [_seconds]);

	return (
		<>
			<ToastContainer position="top-right" />
		</>
	);
}

export default Inactivity;
