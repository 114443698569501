import React from "react";
import { useNavigate } from "react-router-dom";

function Televisions() {
	const navigate = useNavigate();

	const _logout_user = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem('auth_token'),
				"Content-Type": "application/json",
			},
		};
		fetch(process.env.REACT_APP_API_URL + "logout/" + localStorage.getItem('user_id'), requestOptions)
			.then((response) => response.json())
			.then(async (data) => {
				localStorage.clear();
				navigate("/");
			});
	}

	return (
		<div className="cnTelWrap">
			<div className="cnInner">
				<div className="cnTop">
					<h1>Login: {localStorage.getItem('name')}</h1>
					<div className="cnLogOut">
						<button onClick={() => {
							_logout_user();
						}}></button>
					</div>
				</div>
				<div className="cnBottom">
					<ul>
						<li className="cnTel1">
							<div className="cnTele">
								<button onClick={() => { navigate("/keno-draw"); }}></button>
							</div>
						</li>
						<li className="cnTel2 d-none">
							<div className="cnTele">
								<button></button>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Televisions;
