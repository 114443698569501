import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import KenoTab from "./KenoTab";
import Inactivity from './Inactivity';

function Keno(data) {
	/*
	*/
	const navigate = useNavigate();

	/*
	*/
	let [_ticket_day, _set_ticket_day] = useState(false);
	let [_settings, _set_settings] = useState(false);
	let [_open_tickets, _set_open_tickets] = useState();
	const [_shop_credit, _set_shop_credit] = useState();
	let [_show_iframe, _set_show_iframe] = useState(false);


	const _check_session = async () => {
		if (localStorage.getItem("user_logged") === null) {
			navigate("/");
			return;
		}
	};

	const _set_ticket_of_the_day = async (e) => {
		_set_ticket_day(e);
	};

	const _change_settings_modal = async (e) => {
		_set_settings(e)
	}

	const _fetch_shop_money = async () => {
		/*
		*/
		if (localStorage.getItem('user_id')) {
			const requestOptions = {
				method: "POST",
				headers: {
					Authorization: localStorage.getItem('auth_token'),
					"Content-Type": "application/json",
				},
			};
			fetch(process.env.REACT_APP_API_URL + "shop-credit/" + localStorage.getItem('user_id'), requestOptions)
				.then((response) => response.json())
				.then(async (data) => {
					if (data.credit) {
						_set_shop_credit(data.credit)
					} else {
						navigate("/");
						return;
					}
				});
		}
	}

	const show_frame = async () => {
		_set_show_iframe(true)
	}

	useEffect(() => {
		_check_session();
		_fetch_shop_money();
	}, []);

	return (
		<>
			<div className="board-wrapper">
				<Header
					_shop_credit={_shop_credit}
					_change_open_tickets={(e) => { _set_open_tickets(e) }}
					_ticket_of_the_day={(e) => { _set_ticket_of_the_day(e); }}
					_settings={_settings}
					_on_change_settings={(e) => { _change_settings_modal(e); }}
					_toktok_day={_ticket_day}
				/>
				{
					_show_iframe == true &&
					<>
						<div className="modal-settings game-Popup">
							<div className="cnInner">
								<div className="cnBody">
									<div className="live-game">
										<div className="cnClose close-live-game">
											<button
												onClick={() => {
													_set_show_iframe(false)
												}}
											></button>
										</div>
										<iframe className="keno-game-draw" src={process.env.REACT_APP_KENO_GAME_URL + "/?game=" + generateRandomNumber() + "&id=" + generateRandomNumber() + "&authID=" + generateRandomNumber() + "&display=true&fullscreen=allow&secure=yes"} height="100%" width="100%"></iframe>
									</div>
								</div>
							</div>
						</div>
					</>
				}
				<KenoTab
					_update_slip={() => { _set_ticket_of_the_day(false) }}
					_show_frame={() => { show_frame() }}
					keno_inside_update={_fetch_shop_money}
					tickets={_open_tickets}
					all_ticket={_ticket_day}
					_settings={_settings}
					_on_change_settings={(e) => { _change_settings_modal(e); }}
					_shop_credit={_shop_credit}
					iframe_status={_show_iframe}
				/>
			</div>
			<Inactivity />
		</>
	);
}

export default Keno;

function generateRandomNumber() {
	var minm = 100000;
	var maxm = 999999;
	return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
}
