import React, { useEffect, useState, useReducer, useRef } from "react";
import socketIOClient from "socket.io-client";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Language from "../assets/language.json";
import JackpotBoard from "./JackpotBoard";
import JackpotSlip from "./JackpotSlip";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ColorRing } from "react-loader-spinner";
import { useReactToPrint } from "react-to-print";
import JackpotTicketCopy from "./JackpotTicketCopy";
import JackpotLastNumbers from "./JackpotLastNumbers";
import JackpotSettlement from "./JackpotSettlement";

function JackpotTab(data) {

    const ENDPOINT = process.env.REACT_APP_KENO_GAME_URL;
    const componentRef = useRef();
    const last_draw_ref = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const handleLastDrawPrint = useReactToPrint({
        content: () => last_draw_ref.current,
    });


    const settlmentSlip = useRef();
    const reprint_settlement_handle = useReactToPrint({
        content: () => settlmentSlip.current,
    });

    const childFunc = React.useRef(null)
    const enterFunc = React.useRef(null)

    let [_is_internet_good, _set_is_internet_good] = useState(true);
    let [_remaining_seconds, _set_remaining_seconds] = useState();
    let [_time_difference, _set_time_difference] = useState();
    let [_no_more_bets, _set_no_more_bets] = useState(false);
    let [_jackpot_id, _set_jackpot_id] = useState();
    let [_event_id, _set_event_id] = useState();
    const [_track_tab, _set_track_tab] = useState(0);
    let [_show_error, _set_show_error] = useState(false);
    const [_user_language, _set_user_language] = useState(localStorage.getItem("language"));
    let [_fastbet, _set_fastbet] = useState("");
    let [_slip, _set_slip] = useState([]);

    let [_reprint_ticket_number, _set_reprint_ticket_number] = useState();
    let [_show_connection_spinner, _set_show_connection_spinner] = useState(false);
    let [_reprint_ticket_error, _set_reprint_ticket_error] = useState();
    const [please_wait, set_please_wait] = useState(false);

    const [_barcode, _set_barcode] = useState();
    let [_copy_ticket_bets, _set_copy_ticket_bets] = useState();
    let [_copy_ticket_date, _set_copy_ticket_date] = useState();
    let [_copy_ticket_max_odds, _set_copy_ticket_max_odds] = useState();
    let [_copy_ticket_max_potential_win, _set_copy_ticket_max_potential_win] = useState();
    let [_copy_ticket_max_total_win, _set_copy_ticket_max_total_win] = useState();
    let [_copy_ticket_min_odds, _set_copy_ticket_min_odds] = useState();
    let [_copy_ticket_min_potential_win, _set_copy_ticket_min_potential_win] = useState();
    let [_copy_ticket_time, _set_copy_ticket_time] = useState();
    let [_copy_ticket_total_stakes, _set_copy_ticket_total_stakes] = useState();

    const [_settlement_data, _set_settlement_data] = useState();
    const [last_settlement_print_in_progress, set_last_settlement_print_in_progress] = useState(false);

    let [jackpot_amount, set_jackpot_amount] = useState();
    let [_last_draw_info, _set_last_draw_info] = useState([]);
    let [_last_draw_winning_number, _set_last_draw_winning_number] = useState([]);

    let [_last_draw_info_loader, _set_last_draw_info_loader] = useState(false);
    let [_sync, _set_sync] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);


    const _check_internet_connection = async () => {
        var startTime = new Date().getTime();
        var img = new Image();
        img.onload = function () {
            var loadtime = new Date().getTime() - startTime;
            let x = checkConnectionSpeed(loadtime);
            if (x == 'GOOD') {
                _set_is_internet_good(true);

            } else {
                _set_is_internet_good(false);
            }
        };
        img.src = "https://baronbet.starly.mu/images/pexels-photo-531880.jpeg?" + startTime;
        setInterval(() => {
            var startTime = new Date().getTime();
            var img = new Image();
            img.onload = function () {
                var loadtime = new Date().getTime() - startTime;
                let x = checkConnectionSpeed(loadtime);
                if (x == 'GOOD') {
                    _set_is_internet_good(true);

                } else {
                    _set_is_internet_good(false);
                }
            };
            img.src = "https://baronbet.starly.mu/images/pexels-photo-531880.jpeg?" + startTime;
        }, 60000);
    };

    const _manipulate_slip = async (_event_id, values, _jackpot_id) => {

        data._update_slip();

        var is_not_decimal = values.every(function (element) {
            return Number.isInteger(element);
        });

        if (is_not_decimal == false) {
            return;
        }

        if (values.length < 8) {
            return;
        }

        values = values.sort(function (a, b) {
            return a - b;
        });
        /*
         */
        var arr = _slip;

        /*
         */
        let _is_same = false;
        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            if (areEqual(element.ball, values) && element.id == _jackpot_id) {
                _is_same = true;
                return;
            }
        }

        /*
         */
        if (_is_same == true) {
            _is_same = false;
            return;
        }

        /*
         */
        if (checkIfDuplicateExists(values) == true) {
            return;
        }

        for (let i = 0; i < values.length; i++) {
            if (values[i] > 80) {
                _set_show_error(true);
                setTimeout(function () {
                    _set_show_error(false);
                }, 1000);
                return;
            }
        }


        var obj = {};
        obj["id"] = _jackpot_id;
        obj["ball"] = values;
        obj["stakes"] = 1000;
        obj["display_id"] = _event_id;
        obj["odds"] =
            values.length == 8
                ? jackpot_amount
                : values.length == 7
                    ? 200
                    : values.length == 6
                        ? 60
                        : values.length == 5
                            ? 20
                            : values.length == 4
                                ? 10
                                : values.length == 3
                                    ? 5
                                    : values.length == 2
                                        ? 1
                                        : values.length == 1
                                            ? 0
                                            : 0;
        arr.push(obj);
        _set_slip(arr);

    };

    const _remove_row_bet = async (i) => {
        _slip.splice(i, 1);
    };

    const _remove_all_bets = async () => {
        let tmp = _slip;
        for (var i = 0; i < tmp.length; i++) {
            tmp.splice(i, 1);
            _remove_all_bets();
        }
        _set_slip(tmp);
    };

    const _keno_tab_update_money = async () => {
        data.keno_inside_update();
    };

    const remove_bet_list = async () => {
        data._update_slip();
    }

    const _refresh_check_internet_connection = async () => {
        _set_show_connection_spinner(true);
        var startTime = new Date().getTime();
        var img = new Image();
        img.onload = function () {
            var loadtime = new Date().getTime() - startTime;
            let x = checkConnectionSpeed(loadtime);
            if (x == 'GOOD') {
                _set_is_internet_good(true);
                _set_show_connection_spinner(false);
            } else {
                _set_is_internet_good(false);
                _set_show_connection_spinner(false);
            }
        };
        img.src = "https://baronbet.starly.mu/images/pexels-photo-531880.jpeg?" + startTime;
    };

    const _check_if_valid_ticket = async () => {
        let error = [];

        if (_reprint_ticket_number == '' || _reprint_ticket_number == undefined || _reprint_ticket_number == null) {
            error.push("cannot be empty");
            _set_reprint_ticket_error(error);
            _set_reprint_ticket_number("");
            setTimeout(function () {
                _set_reprint_ticket_error();
            }, 20000);
        }
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem("auth_token"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ barcode: _reprint_ticket_number + "" }),
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/bet-copy/" +
            localStorage.getItem("user_id"),
            requestOptions
        )
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message == "bet_not_found") {
                    error.push("Invalid");
                    _set_reprint_ticket_error(error);
                    set_please_wait(false);
                    _set_reprint_ticket_number("");
                    setTimeout(function () {
                        _set_reprint_ticket_error();
                    }, 20000);
                    return;
                }
                if (data.message == "copy_found") {
                    _set_copy_ticket_bets(data.userBetDetails);
                    _set_copy_ticket_date(data.bet_time);
                    _set_copy_ticket_max_potential_win(data.max_potential_win);
                    _set_copy_ticket_min_potential_win(data.min_potential_win);
                    _set_copy_ticket_time(data.bet_time);
                    _set_copy_ticket_total_stakes(data.total_stakes);
                    _set_barcode(data.barcode);
                    setTimeout(function () {
                        handlePrint();
                    }, 200);
                    setTimeout(function () {
                        _set_copy_ticket_bets(null);
                        _set_copy_ticket_date(null);
                        _set_copy_ticket_max_potential_win(null);
                        _set_copy_ticket_min_potential_win(null);
                        _set_copy_ticket_time(null);
                        _set_copy_ticket_total_stakes(null);
                        _set_barcode(null);
                        set_please_wait(false);
                        _set_reprint_ticket_number("");
                    }, 1500);
                    return;
                }
                error.push(Language.translations.Slip.something_wrong_try_again[_user_language]);
                _set_reprint_ticket_error(error);
                set_please_wait(false);
                _set_reprint_ticket_number("");
                setTimeout(function () {
                    _set_reprint_ticket_error();
                }, 20000);
                return;
            });
    }

    const _reprint_last_settlement = async () => {
        set_last_settlement_print_in_progress(true);
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "jackpot/print-settlement/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                _set_settlement_data(data);
                setTimeout(function () {
                    reprint_settlement_handle();
                    set_last_settlement_print_in_progress(false);
                }, 3000);
            })

    }

    const _fast_bet_quick_pick = async (_fast_bet) => {
        let tmp = _fast_bet.split("");
        _generate_quick_pick_random(tmp[1]);
    };

    const _generate_quick_pick_random = async (_quantity) => {
        let _rand_numbs = [];
        for (let i = 0; i < _quantity; i++) {
            let _tmp = _generate_random(1, 40, _rand_numbs);
            _rand_numbs.push(parseInt(_tmp));
        }
        _manipulate_slip(_event_id, _rand_numbs, _jackpot_id);
    };

    const fetch_previous_jackpot_numbers = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + process.env.REACT_APP_BEARER,
                "Content-Type": "application/json",
            },
        };
        fetch(
            process.env.REACT_APP_API_URL +
            "jackpot/jackpot-data",
            requestOptions
        )
            .then((response) => response.json())
            .then(async (data) => {
                _set_last_draw_info(data);
                let _tmp = data.lastDrawWinnerNumber.split(",");
                _set_last_draw_winning_number(_tmp);
                setTimeout(function () {
                    handleLastDrawPrint();
                    _set_last_draw_info_loader(false);
                }, 5000);
            })
    }

    useEffect(() => {
        _check_internet_connection();
        const socket = socketIOClient(ENDPOINT);
        socket.on("jakpot_countdown_timer_seconds", (data) => {
            _set_remaining_seconds(data);
        });
        socket.on("jackpot_draw_id", (data) => {
            _set_jackpot_id(data);
        });
        socket.on("jackpot_reference", (data) => {
            _set_event_id(data);
        });
        socket.on("jackpot_draw_difference", (data) => {
            _set_time_difference(data);
        });
        socket.on("jackpot_amount", (data) => {
            set_jackpot_amount(data);
        });
    }, []);

    // useEffect(() => {
    //     if (!document.hasFocus()) {
    //         _set_sync(true);
    //     } else {
    //         if (_sync == true) {
    //             setTimeout(function () {
    //                 window.location.reload();
    //             }, 200);
    //         }
    //     }
    // }, [document.hasFocus()]);

    useEffect(() => {
        const handleFocus = () => {
            // Update the refresh key to trigger a re-render
            setRefreshKey(prevKey => prevKey + 1);
        };

        window.addEventListener('focus', handleFocus);

        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, []);

    return (
        <>

            {_sync == true && (
                <div className="no-more-bets no-more-bets-jackpot">
                    <div className="cnInner">
                        <div className="cnTopLogoKeno"></div>
                        <h1>{Language.translations.Tab.sync[_user_language]}</h1>
                    </div>
                </div>
            )}
            <div className="cnMainBoard" key={refreshKey}>
                <div className="cn-left">
                    {
                        _no_more_bets == false && _remaining_seconds &&
                        <div className="tabs">
                            <button className="cnLiveDraw" onClick={() => { data._show_frame() }}>{Language.translations.Tab.live_draw[_user_language]}</button>
                            <Tabs
                                selectedIndex={_track_tab}
                                onSelect={(value) => _set_track_tab(value)}
                            >
                                <TabList>
                                    <Tab>
                                        {_event_id}
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <JackpotBoard
                                        jackpot_amount={jackpot_amount}
                                        _jackpot_id={_jackpot_id}
                                        _event_id={_event_id}
                                        _remaining_seconds={_remaining_seconds}
                                        _time_diff={_time_difference}
                                        _send_to_tab={(_event_id, values, _jackpot_id) => {
                                            _manipulate_slip(_event_id, values, _jackpot_id);
                                        }}
                                    />
                                </TabPanel>
                            </Tabs>
                        </div>
                    }
                </div>
                <div className="cn-right">
                    <div className="fastbet">
                        {
                            _show_error == true &&
                            <div className="error-fastbet">
                                <p className="danger">{Language.translations.Tab.error[_user_language]}</p>
                            </div>
                        }
                        <input
                            type="text"
                            id="fastbet-input-jackpot"
                            autoComplete="off"
                            value={_fastbet}
                            onChange={(e) => {
                                _set_fastbet(e.target.value.toUpperCase());
                            }}
                            placeholder={Language.translations.Slip.fastbet[_user_language]}
                            onKeyPress={(ev) => {
                                if (ev.key === "Enter") {

                                    if (_fastbet == "") {
                                        _set_fastbet("");
                                        enterFunc.current.key = "Enter";
                                        enterFunc.current();
                                        ev.preventDefault();
                                        ev.target.blur();
                                        return;
                                    }

                                    // example: 10005166331417898432
                                    if (isNumeric(_fastbet) && _fastbet.length > 18) {
                                        remove_bet_list();
                                        childFunc.current.fastbet = _fastbet;
                                        childFunc.current();
                                        _set_fastbet("");
                                        ev.preventDefault();
                                        ev.target.blur();
                                        return;
                                    }

                                    if (_remaining_seconds > 1920) {
                                        if (_fastbet == "Q8" || _fastbet == "q8") {
                                            remove_bet_list();
                                            _fast_bet_quick_pick(_fastbet);
                                            _set_fastbet("");
                                            ev.preventDefault();
                                            ev.target.blur();
                                            return;
                                        }
                                    }

                                    _set_fastbet("");
                                    ev.preventDefault();
                                    ev.target.blur();
                                    _set_show_error(true);
                                    setTimeout(function () {
                                        _set_show_error(false);
                                    }, 1000);
                                    return;
                                }
                            }}
                        />
                        <button onClick={() => { window.open('/pdf/keno_shortcut_' + localStorage.getItem('language') + '.pdf', '_blank') }}></button>
                    </div>
                    <div
                        className={
                            data.all_ticket == true ? "jackpot-slip keno-slip true" : "jackpot-slip keno-slip false"
                        }
                    >
                        <JackpotSlip
                            _is_settings_open={data._settings}
                            _remaining_seconds={_remaining_seconds}
                            _slip={_slip}
                            _show_ticket_of_the_day={data.all_ticket}
                            _remove_row={(i) => {
                                _remove_row_bet(i);
                            }}
                            _remove_all={_remove_all_bets}
                            all_tickets={data.tickets}
                            _tab_update_money={_keno_tab_update_money}
                            _on_going_play={_jackpot_id}
                            remove_bet_list={remove_bet_list}
                            jackpot_amount={jackpot_amount}
                            childFunc={childFunc}
                            enterFunc={enterFunc}
                        />
                    </div>
                </div>
            </div>

            {
                data._settings == true &&
                <div className="modal-settings cnGeneral">
                    <div className="cnInner">
                        <Scrollbars>
                            <div className="cnTop">
                                <h1>
                                    {Language.translations.Tab.general_settings[_user_language]}
                                </h1>
                                <div className="cnClose">
                                    <button
                                        onClick={() => {
                                            data._on_change_settings(false);
                                            _set_reprint_ticket_number("");
                                        }}
                                    ></button>
                                </div>
                            </div>
                            <div className="cnBody">
                                <div className="cnTitle">
                                    <p>{Language.translations.Tab.general_information[_user_language]}</p>
                                </div>
                                <div className="cnTableWrap cnGeneralSetting">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell cnleft">{Language.translations.Tab.username[_user_language]}</div>
                                                <div className="divTableCell">
                                                    {localStorage.getItem("name")}
                                                </div>
                                            </div>
                                            <div className="divTableRow">
                                                <div className="divTableCell">{Language.translations.Tab.balance[_user_language]}</div>
                                                <div className="divTableCell">
                                                    {data._shop_credit}
                                                    {localStorage.getItem("currency_symbol")}
                                                </div>
                                            </div>
                                            <div className="divTableRow">
                                                <div className="divTableCell">{Language.translations.Tab.language[_user_language]}</div>
                                                <div className="divTableCell">
                                                    <select
                                                        name="lang"
                                                        id="lang"
                                                        value={_user_language}
                                                        onChange={(e) => {
                                                            localStorage.setItem("language", e.target.value);
                                                            window.location.reload();
                                                        }}
                                                    >
                                                        <option value="en">EN</option>
                                                        <option value="fr">FR</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="divTableRow">
                                                <div className="divTableCell">{Language.translations.Tab.connection_quality[_user_language]}</div>
                                                <div className="divTableCell">

                                                    {_is_internet_good == true && (
                                                        <div className="internet internet-good">{Language.translations.Tab.good[_user_language]}</div>
                                                    )}
                                                    {_is_internet_good == false && (
                                                        <div className="internet internet-bad">{Language.translations.Tab.bad[_user_language]}</div>
                                                    )}
                                                    <div className="cnBut">
                                                        <button onClick={() => { _refresh_check_internet_connection() }}>{Language.translations.Tab.refresh[_user_language]}</button>
                                                    </div>
                                                    {
                                                        _show_connection_spinner == true &&
                                                        <div className="loader-check-connection">
                                                            <ColorRing
                                                                visible={true}
                                                                height="80"
                                                                width="80"
                                                                ariaLabel="blocks-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass="blocks-wrapper"
                                                                colors={[
                                                                    "#003779",
                                                                    "#ff171b",
                                                                    "#ff8400",
                                                                    "#008032",
                                                                    "#ffcc00",
                                                                ]}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cnTitle">
                                    <p>{Language.translations.Tab.keyboard_shortcuts[_user_language]}</p>
                                </div>
                                <div className="cnTableWrap">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell">Q[8] + {Language.translations.Tab.enter[_user_language]}</div>
                                                <div className="divTableCell">
                                                    Add 8 unique numbers between 1 to 80 <i>(eg.) 1,2,3,4,5,6,7,8</i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cnTitle">
                                    <p>{Language.translations.Tab.settings[_user_language]}</p>
                                </div>
                                <div className="cnTableWrap cnGeneralSetting">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell">Last Jackpot Numbers</div>
                                                <div className="divTableCell">
                                                    <div className="cnButton">
                                                        {
                                                            _last_draw_info_loader == true &&
                                                            <div className="loader-check-connection">
                                                                <ColorRing
                                                                    visible={true}
                                                                    height="80"
                                                                    width="80"
                                                                    ariaLabel="blocks-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass="blocks-wrapper"
                                                                    colors={[
                                                                        "#003779",
                                                                        "#ff171b",
                                                                        "#ff8400",
                                                                        "#008032",
                                                                        "#ffcc00",
                                                                    ]}
                                                                />
                                                            </div>
                                                        }
                                                        <button className="cnRightBut" onClick={() => { _set_last_draw_info_loader(true); fetch_previous_jackpot_numbers(); }}>
                                                            Print
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cnTableWrap cnGeneralSetting">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell">{Language.translations.Tab.reprint_ticket[_user_language]}</div>
                                                <div className="divTableCell">
                                                    <div className="cnInput">
                                                        <input className="reprint-input" placeholder={Language.translations.Tab.ticket_number[_user_language]} type="text" value={_reprint_ticket_number} onChange={(e) => { _set_reprint_ticket_number(e.target.value) }} />
                                                        {_reprint_ticket_error != undefined &&
                                                            _reprint_ticket_error != null && (
                                                                <div className="error-message">
                                                                    <ul>
                                                                        {_reprint_ticket_error.map((item, index) => (
                                                                            <li key={index}>{item}</li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="divTableCell">
                                                    <div className="cnButton">
                                                        <button className="cnRightBut" onClick={() => { _check_if_valid_ticket() }}>{Language.translations.Tab.apply[_user_language]}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cnTableWrap cnGeneralSetting">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell">Reprint Last Settlement</div>
                                                <div className="divTableCell">
                                                    <div className="cnButton">
                                                        {
                                                            last_settlement_print_in_progress == true &&
                                                            <div className="loader-check-connection">
                                                                <ColorRing
                                                                    visible={true}
                                                                    height="80"
                                                                    width="80"
                                                                    ariaLabel="blocks-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClass="blocks-wrapper"
                                                                    colors={[
                                                                        "#003779",
                                                                        "#ff171b",
                                                                        "#ff8400",
                                                                        "#008032",
                                                                        "#ffcc00",
                                                                    ]}
                                                                />
                                                            </div>
                                                        }
                                                        <button className="cnRightBut" onClick={() => { _reprint_last_settlement(); }}>Reprint</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cnTableWrap cnGeneralSetting">
                                    <div className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell">{Language.translations.Tab.printer_driver[_user_language]}</div>
                                                <div className="divTableCell">
                                                    <div className="cnButton">
                                                        <button className="cnRightBut" onClick={() => { window.open('driver/printer_driver.zip', '_blank').focus(); }}>{Language.translations.Tab.download[_user_language]}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div >
            }

            <div ref={componentRef} className="wa-copy">
                <JackpotTicketCopy
                    bets={_copy_ticket_bets}
                    min_potential_win={_copy_ticket_min_potential_win}
                    max_potential_win={_copy_ticket_max_potential_win}
                    total_stakes={_copy_ticket_total_stakes}
                    date={_copy_ticket_date}
                    time={_copy_ticket_time}
                    name={localStorage.getItem("name")}
                    shop_name={localStorage.getItem("shop_name")}
                    barcode={_barcode}
                />
            </div>

            <div ref={last_draw_ref} className="wa-copy wa-jackpot-long-recu">
                <JackpotLastNumbers
                    _last_draw_winning_number={_last_draw_winning_number}
                    jackpot_info={_last_draw_info}
                />
            </div>

            <div ref={settlmentSlip} className="waza">
                <JackpotSettlement
                    print_data={_settlement_data}
                />
            </div>

        </>
    )
}

export default JackpotTab


function checkIfDuplicateExists(arr) {
    return new Set(arr).size !== arr.length;
}

function areEqual(array1, array2) {
    if (array1.length === array2.length) {
        return array1.every((element, index) => {
            if (element === array2[index]) {
                return true;
            }
            return false;
        });
    }
    return false;
}

function _generate_random(min, max, arr) {
    let difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + min;
    if (arr.includes(rand)) {
        rand = _generate_random(1, 40, arr);
    }
    return rand;
}

function checkConnectionSpeed(millisecond) {
    var x = document.getElementById("connection-message");
    if (millisecond > 5000) {
        return 'BAD';
    } else if (millisecond > 3000) {
        return 'BAD';
    } else {
        return 'GOOD';
    }
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}