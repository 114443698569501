import React, { useState, useEffect, useCallback } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Clock from 'react-live-clock';
import $ from "jquery";
import Language from "../assets/language.json";


const _remainign_time = ({ remainingTime }) => {
	let minutes = Math.floor(remainingTime / 60);
	if (/^\d$/.test(minutes)) {
		minutes = "0" + minutes;
	}
	let seconds = remainingTime % 60;
	if (/^\d$/.test(seconds)) {
		seconds = "0" + seconds;
	}
	return `${minutes}:${seconds}`;
};

function KenoBoard(data) {
	/*
	*/
	const [_board_id, _set_board_id] = useState(data._board_id);
	const [_selected_values, _set_selected_values] = useState([]);
	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);
	const [_circle_color, _set_circle_color] = useState("#ffcc00");
	const [_repeat_play, _set_repeat_play] = useState("1x");
	const [_user_language, _set_user_language] = useState(localStorage.getItem("language"));

	const _click_col_1 = async () => {
		_set_selected_values([1, 11, 21, 31, 41, 51, 61, 71]);
	};

	const _click_col_2 = async () => {
		_set_selected_values([2, 12, 22, 32, 42, 52, 62, 72]);
	};

	const _click_col_3 = async () => {
		_set_selected_values([3, 13, 23, 33, 43, 53, 63, 73]);
	};

	const _click_col_4 = async () => {
		_set_selected_values([4, 14, 24, 34, 44, 54, 64, 74]);
	};

	const _click_col_5 = async () => {
		_set_selected_values([5, 15, 25, 35, 45, 55, 65, 75]);
	};

	const _click_col_6 = async () => {
		_set_selected_values([6, 16, 26, 36, 46, 56, 66, 76]);
	};

	const _click_col_7 = async () => {
		_set_selected_values([7, 17, 27, 37, 47, 57, 67, 77]);
	};

	const _click_col_8 = async () => {
		_set_selected_values([8, 18, 28, 38, 48, 58, 68, 78]);
	};

	const _click_col_9 = async () => {
		_set_selected_values([9, 19, 29, 39, 49, 59, 69, 79]);
	};

	const _click_col_10 = async () => {
		_set_selected_values([10, 20, 30, 40, 50, 60, 70, 80]);
	};

	const _circle_tap = async (number) => {
		if ($(".btn-" + number).prop("disabled")) {
			return;
		}
		if (!$(".btn-" + number).hasClass("active")) {
			_set_selected_values([..._selected_values, number]);
		} else {
			let tmp = _selected_values;
			const index = tmp.indexOf(number);
			if (index > -1) {
				tmp.splice(index, 1);
			}
			$(".btn-" + number).removeClass("active");
			$("button.circle").prop("disabled", false);
			_set_selected_values(tmp);
		}
	};

	const _generate_random_numbers = async (_quantity) => {
		_set_selected_values([]);
		$("button.circle").prop("disabled", false);
		let _rand_numbs = [];
		for (let i = 0; i < _quantity; i++) {
			let _tmp = _generate_random(1, 80, _rand_numbs);
			_rand_numbs.push(_tmp);
		}
		_set_selected_values(_rand_numbs);
	};

	const _controller_selected_values = async () => {
		if (_selected_values !== null) {
			var i = 0,
				len = _selected_values.length;
			$("button.circle").removeClass("active");
			while (i < len && len <= 8) {
				$(".btn-" + _selected_values[i]).addClass("active");
				i++;
			}
			if (len === 8) {
				$("button.circle").prop("disabled", true);
				$("button.circle.active").prop("disabled", false);
			}
		} else {
			$("button.circle").removeClass("active");
		}
	};

	const _place_bets = async () => {
		if (_selected_values.length > 0) {
			data._send_to_tab(_board_id, _selected_values, _repeat_play, data._display_id);
		}
	};

	const _reset_board = async () => {
		_set_selected_values([]);
		$("button.circle").prop("disabled", false);
	};

	useEffect(() => {
		_controller_selected_values();
	}, [_selected_values]);

	return (
		<>
			{
				data._is_betting_allowed == true &&
				<div className="no-more-bets">
					<div className="cnInner">
						<div className="cnTopLogoKeno"></div>
						<h1>{Language.translations.KenoBoard.no_more_bets[_user_language]}</h1>
					</div>
				</div>
			}
			<div className="cnTabsWrap">
				<div className="cnTabsWapTop">
					<div className="cnTabsLeft cnClear">
						<div className="cnKenoLogo">
							<img src="./images/keno-logo.svg" alt="Keno" />
						</div>
						<div className="cnClockWrap">
							<div className="cnClock">
								<Clock format={'HH:mm:ss'} ticking={true} timezone={localStorage.getItem('timezone')} />
							</div>
							<CountdownCircleTimer
								isPlaying
								size={110}
								strokeWidth={3}
								key={0}
								duration={data._remaining_duration}
								initialRemainingTime={data._remaining_time_to_bet}
								colors={[_circle_color]}
								onComplete={() => {
									forceUpdate();
								}}
							>
								{_remainign_time}
							</CountdownCircleTimer>
							<div className="event">
								<h1>{Language.translations.KenoBoard.event[_user_language]}: #{data._display_id}</h1>
							</div>
						</div>
					</div>
					<div className="cnTabsRight">
						<div className="cnTop cnClear">
							<div className="random-selection cnSelectRan">
								<div className="title">{Language.translations.KenoBoard.random[_user_language]}</div>
								<ul>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(1);
											}}
										>
											1
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(2);
											}}
										>
											2
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(3);
											}}
										>
											3
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(4);
											}}
										>
											4
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(5);
											}}
										>
											5
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(6);
											}}
										>
											6
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(7);
											}}
										>
											7
										</button>
									</li>
									<li>
										<button
											onClick={() => {
												_generate_random_numbers(8);
											}}
										>
											8
										</button>
									</li>
								</ul>
							</div>
							<div className="play-same-number cnSelectRan">
								<p className="title">{Language.translations.KenoBoard.repeat[_user_language]}</p>
								<ul>
									<li>
										<button
											className={_repeat_play == "1x" ? "active" : ""}
											onClick={() => {
												_set_repeat_play("1x");
											}}
										>
											1x
										</button>
									</li>
									<li>
										<button
											className={_repeat_play == "2x" ? "active" : ""}
											onClick={() => {
												_set_repeat_play("2x");
											}}
										>
											2x
										</button>
									</li>
									<li>
										<button
											className={_repeat_play == "3x" ? "active" : ""}
											onClick={() => {
												_set_repeat_play("3x");
											}}
										>
											3x
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className="cnBottom">
							<div className="reset-selected-numbers">
								<button
									className={
										_selected_values.length > 0
											? "btn btn-danger"
											: "btn btn-secondary"
									}
									disabled={_selected_values.length > 0 ? false : true}
									onClick={() => {
										_reset_board();
									}}
								>
									{Language.translations.KenoBoard.reset_numbers[_user_language]}
								</button>
								<button
									className={
										_selected_values.length > 0
											? "btn btn-success"
											: "btn btn-secondary"
									}
									disabled={_selected_values.length > 0 ? false : true}
									onClick={() => {
										_place_bets();
										_reset_board();
									}}
								>
									{Language.translations.KenoBoard.place_bets[_user_language]}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="keno-table">
					<div className="cnTableWrap">
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_1();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(1);
									}}
									className="circle btn-1"
								>
									<div className="cnInner">1</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(11);
									}}
									className="circle btn-11"
								>
									<div className="cnInner">11</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(21);
									}}
									className="circle btn-21"
								>
									<div className="cnInner">21</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(31);
									}}
									className="circle btn-31"
								>
									<div className="cnInner">31</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(41);
									}}
									className="circle btn-41"
								>
									<div className="cnInner">41</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(51);
									}}
									className="circle btn-51"
								>
									<div className="cnInner">51</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(61);
									}}
									className="circle btn-61"
								>
									<div className="cnInner">61</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(71);
									}}
									className="circle btn-71"
								>
									<div className="cnInner">71</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_2();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(2);
									}}
									className="circle btn-2"
								>
									<div className="cnInner">2</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(12);
									}}
									className="circle btn-12"
								>
									<div className="cnInner">12</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(22);
									}}
									className="circle btn-22"
								>
									<div className="cnInner">22</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(32);
									}}
									className="circle btn-32"
								>
									<div className="cnInner">32</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(42);
									}}
									className="circle btn-42"
								>
									<div className="cnInner">42</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(52);
									}}
									className="circle btn-52"
								>
									<div className="cnInner">52</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(62);
									}}
									className="circle btn-62"
								>
									<div className="cnInner">62</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(72);
									}}
									className="circle btn-72"
								>
									<div className="cnInner">72</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_3();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(3);
									}}
									className="circle btn-3"
								>
									<div className="cnInner">3</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(13);
									}}
									className="circle btn-13"
								>
									<div className="cnInner">13</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(23);
									}}
									className="circle btn-23"
								>
									<div className="cnInner">23</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(33);
									}}
									className="circle btn-33"
								>
									<div className="cnInner">33</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(43);
									}}
									className="circle btn-43"
								>
									<div className="cnInner">43</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(53);
									}}
									className="circle btn-53"
								>
									<div className="cnInner">53</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(63);
									}}
									className="circle btn-63"
								>
									<div className="cnInner">63</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(73);
									}}
									className="circle btn-73"
								>
									<div className="cnInner">73</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_4();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(4);
									}}
									className="circle btn-4"
								>
									<div className="cnInner">4</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(14);
									}}
									className="circle btn-14"
								>
									<div className="cnInner">14</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(24);
									}}
									className="circle btn-24"
								>
									<div className="cnInner">24</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(34);
									}}
									className="circle btn-34"
								>
									<div className="cnInner">34</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(44);
									}}
									className="circle btn-44"
								>
									<div className="cnInner">44</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(54);
									}}
									className="circle btn-54"
								>
									<div className="cnInner">54</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(64);
									}}
									className="circle btn-64"
								>
									<div className="cnInner">64</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(74);
									}}
									className="circle btn-74"
								>
									<div className="cnInner">74</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_5();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(5);
									}}
									className="circle btn-5"
								>
									<div className="cnInner">5</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(15);
									}}
									className="circle btn-15"
								>
									<div className="cnInner">15</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(25);
									}}
									className="circle btn-25"
								>
									<div className="cnInner">25</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(35);
									}}
									className="circle btn-35"
								>
									<div className="cnInner">35</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(45);
									}}
									className="circle btn-45"
								>
									<div className="cnInner">45</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(55);
									}}
									className="circle btn-55"
								>
									<div className="cnInner">55</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(65);
									}}
									className="circle btn-65"
								>
									<div className="cnInner">65</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(75);
									}}
									className="circle btn-75"
								>
									<div className="cnInner">75</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_6();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(6);
									}}
									className="circle btn-6"
								>
									<div className="cnInner">6</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(16);
									}}
									className="circle btn-16"
								>
									<div className="cnInner">16</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(26);
									}}
									className="circle btn-26"
								>
									<div className="cnInner">26</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(36);
									}}
									className="circle btn-36"
								>
									<div className="cnInner">36</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(46);
									}}
									className="circle btn-46"
								>
									<div className="cnInner">46</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(56);
									}}
									className="circle btn-56"
								>
									<div className="cnInner">56</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(66);
									}}
									className="circle btn-66"
								>
									<div className="cnInner">66</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(76);
									}}
									className="circle btn-76"
								>
									<div className="cnInner">76</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_7();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(7);
									}}
									className="circle btn-7"
								>
									<div className="cnInner">7</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(17);
									}}
									className="circle btn-17"
								>
									<div className="cnInner">17</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(27);
									}}
									className="circle btn-27"
								>
									<div className="cnInner">27</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(37);
									}}
									className="circle btn-37"
								>
									<div className="cnInner">37</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(47);
									}}
									className="circle btn-47"
								>
									<div className="cnInner">47</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(57);
									}}
									className="circle btn-57"
								>
									<div className="cnInner">57</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(67);
									}}
									className="circle btn-67"
								>
									<div className="cnInner">67</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(77);
									}}
									className="circle btn-77"
								>
									<div className="cnInner">77</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_8();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(8);
									}}
									className="circle btn-8"
								>
									<div className="cnInner">8</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(18);
									}}
									className="circle btn-18"
								>
									<div className="cnInner">18</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(28);
									}}
									className="circle btn-28"
								>
									<div className="cnInner">28</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(38);
									}}
									className="circle btn-38"
								>
									<div className="cnInner">38</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(48);
									}}
									className="circle btn-48"
								>
									<div className="cnInner">48</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(58);
									}}
									className="circle btn-58"
								>
									<div className="cnInner">58</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(68);
									}}
									className="circle btn-68"
								>
									<div className="cnInner">68</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(78);
									}}
									className="circle btn-78"
								>
									<div className="cnInner">78</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_9();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(9);
									}}
									className="circle btn-9"
								>
									<div className="cnInner">9</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(19);
									}}
									className="circle btn-19"
								>
									<div className="cnInner">19</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(29);
									}}
									className="circle btn-29"
								>
									<div className="cnInner">29</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(39);
									}}
									className="circle btn-39"
								>
									<div className="cnInner">39</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(49);
									}}
									className="circle btn-49"
								>
									<div className="cnInner">49</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(59);
									}}
									className="circle btn-59"
								>
									<div className="cnInner">59</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(69);
									}}
									className="circle btn-69"
								>
									<div className="cnInner">69</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(79);
									}}
									className="circle btn-79"
								>
									<div className="cnInner">79</div>
								</button>
							</div>
						</div>
						<div className="col">
							<div className="divTableCell cnTableHead">
								<button
									className="col-select"
									onClick={() => {
										_click_col_10();
									}}
								></button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(10);
									}}
									className="circle btn-10"
								>
									<div className="cnInner">10</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(20);
									}}
									className="circle btn-20"
								>
									<div className="cnInner">20</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(30);
									}}
									className="circle btn-30"
								>
									<div className="cnInner">30</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(40);
									}}
									className="circle btn-40"
								>
									<div className="cnInner">40</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(50);
									}}
									className="circle btn-50"
								>
									<div className="cnInner">50</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(60);
									}}
									className="circle btn-60"
								>
									<div className="cnInner">60</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(70);
									}}
									className="circle btn-70"
								>
									<div className="cnInner">70</div>
								</button>
							</div>
							<div className="divTableCell">
								<button
									onClick={() => {
										_circle_tap(80);
									}}
									className="circle btn-80"
								>
									<div className="cnInner">80</div>
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* {_selected_values &&
				[..._selected_values].map((x, i) => <h1 key={i}>{x}</h1>)} */}
			</div>
		</>
	);
}

export default KenoBoard;

function _generate_random(min, max, arr) {
	let difference = max - min;
	let rand = Math.random();
	rand = Math.floor(rand * difference);
	rand = rand + min;
	if (arr.includes(rand)) {
		rand = _generate_random(1, 80, arr);
	}
	return rand;
}
