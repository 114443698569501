import React from "react";
import Barcode from 'react-barcode';


function KenoTicket(data) {
    return (
        <div>
            <div id="invoice-POS" className="ticket-bet">
                {/* <div id="watermark" className="copy">
                    <h1>Copy</h1>
                </div> */}
                <div id="top">
                    <div className="logo">
                        <img src="./images/keno-ticket-black.svg" />
                    </div>
                    <div className="info">
                        <h2>BetShop</h2>
                    </div>
                </div>
                <div id="mid">
                    <div className="info">
                        <span>{data.shop_name} - {data.name}</span>
                        <span>{data.date}</span>
                        {
                            data.barcode !== undefined && data.barcode != null &&
                            <span>Ticket: {data.barcode.toString()}</span>
                        }
                        {
                            data.barcode !== undefined && data.barcode != null &&
                            <div className="cnBarcode">
                                <Barcode value={data.barcode.toString()} />
                            </div>
                        }
                    </div>
                </div>
                <div id="bot">
                    {
                        data.bets != undefined && data.bets !== null &&
                        data.bets.map((element, i) => (
                            <div className="divTable cnTableTop" key={i}>
                                <div className="divTableBody">
                                    <div className="divTableRow">
                                        <div className="divTableCell  cnWidth1">{element.display_id}</div>
                                        <div className="divTableCell">&nbsp;</div>
                                        <div className="divTableCell cnWidth2"><strong>Keno</strong></div>
                                        <div className="divTableCell">&nbsp;</div>
                                        <div className="divTableCell cnAlignRight cnWidth3">
                                            {element.odds}
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell  cnWidth1">{data.time}</div>
                                        <div className="divTableCell">&nbsp;</div>
                                        <div className="divTableCell cnWidth2">
                                            <ul>
                                                {
                                                    element.ball.map((ball, j) => (
                                                        <li key={j}>{ball}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <div className="divTableCell">&nbsp;</div>
                                        <div className="divTableCell cnAlignRight cnWidth3">{element.stakes} {localStorage.getItem('currency_symbol')}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className="divTable cnTableBottom" >
                        <div className="divTableBody">
                            <div className="divTableRow">
                                <div className="divTableCell cnBig">Total Stake</div>
                                <div className="divTableCell">&nbsp;</div>
                                <div className="divTableCell cnAlignRight cnBig">{data.total_stakes} {localStorage.getItem('currency_symbol')}</div>
                            </div>
                            <div className="divTableRow">
                                <div className="divTableCell cnMed">Min/Max Win</div>
                                <div className="divTableCell">&nbsp;</div>
                                <div className="divTableCell cnAlignRight cnMed">
                                    {data.min_potential_win} / {data.max_potential_win} {localStorage.getItem('currency_symbol')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="divTable cnTableBottom cnHeight" >
                        <div className="divTableBody">
                            <div className="divTableRow">
                                <div className="divTableCell disclaimer"><strong>Thank you for playing with Baron Bet.</strong>
                                    Winners should bring <strong>original receipts</strong> for payouts.
                                    Any errors, discrepancy or tampering will be not be entertained and can be reported to the police.
                                    Validity of winning tickets is <strong>30 days</strong>.For further inquiries, please contact us on <strong>support@baron.bet</strong> or on our hotline <strong>{process.env.REACT_APP_KENO_GAME_HOTLINE}</strong>.
                                    ** Please play responsibly. **
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KenoTicket;

