import React, { useEffect, useState, useRef } from "react";
import Language from "../assets/language.json";

function JackpotSettlement(data) {

    const [_user_language, _set_user_language] = useState(localStorage.getItem("language"));

    return (
        <>
            <>
                {
                    data.print_data != undefined && data.print_data != null &&
                    <div>
                        <div id="invoice-POS" className='settlement-receipt'>
                            <div id="top">
                                <div className="logo">
                                    <img src="./images/keno-jackpot-black.svg" />
                                </div>
                                <br />
                                <div className="info">
                                    <h2>BetShop</h2>
                                </div>
                            </div>
                            <div id="mid">
                                <div className="info">
                                    <span>{localStorage.getItem('shop_name')} - {localStorage.getItem('name')}</span>
                                </div>
                            </div>
                            <div id="bot">
                                <div className="divTable">
                                    <div className="divTableBody">
                                        <div className="divTableRow">
                                            <div className="divTableCell">Number</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.nextSettlement &&
                                                    data.print_data.nextSettlement
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Last settlement</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.lastSettlement &&
                                                    data.print_data.lastSettlement
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Actual Date / Time</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.actualTime &&
                                                    data.print_data.actualTime
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Total In</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.totalIn &&
                                                    data.print_data.totalIn
                                                }
                                                {
                                                    localStorage.getItem('currency_symbol')
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Total Out</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.totalOut &&
                                                    data.print_data.totalOut
                                                }
                                                {
                                                    localStorage.getItem('currency_symbol')
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Reversal</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.reversal &&
                                                    data.print_data.reversal
                                                }
                                                {
                                                    localStorage.getItem('currency_symbol')
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Profit</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.netProfit &&
                                                    data.print_data.netProfit
                                                }
                                                {
                                                    localStorage.getItem('currency_symbol')
                                                }
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">Open Payouts</div>
                                            <div className="divTableCell">
                                                {
                                                    data.print_data.openPayouts &&
                                                    data.print_data.openPayouts
                                                }
                                                {
                                                    localStorage.getItem('currency_symbol')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                }
            </>
        </>
    )
}

export default JackpotSettlement