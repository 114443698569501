import React from 'react'

function Settlement(data) {
    return (
        <>
            {
                data.print_data != undefined && data.print_data != null &&
                <div>
                    <div id="invoice-POS" className='settlement-receipt'>
                        <div id="top">
                            <div className="logo">
                                <img src="./images/keno-ticket-black.svg" />
                            </div>
                            <div className="info">
                                <h2>BetShop</h2>
                            </div>
                        </div>
                        <div id="mid">
                            <div className="info">
                                <span>{localStorage.getItem('shop_name')} - {localStorage.getItem('name')}</span>
                            </div>
                        </div>
                        <div id="bot">
                            <div className="divTable">
                                <div className="divTableBody">
                                    <div className="divTableRow">
                                        <div className="divTableCell">Number</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.nextSettlement &&
                                                data.print_data.nextSettlement
                                            }
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell">Last settlement</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.lastSettlement &&
                                                data.print_data.lastSettlement
                                            }
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell">Actual Date / Time</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.actualTime &&
                                                data.print_data.actualTime
                                            }
                                        </div>
                                    </div>



                                    {/* <div className="divTableRow">
                                        <div className="divTableCell">keno In</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.kenoIn &&
                                                data.print_data.kenoIn
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>

                                    <div className="divTableRow">
                                        <div className="divTableCell">keno Out</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.kenoOut &&
                                                data.print_data.kenoOut
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div> */}


                                    {/* <div className="divTableRow">
                                        <div className="divTableCell">Jackpot In</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.jackpotIn &&
                                                data.print_data.jackpotIn
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell">Jackpot Out</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.jackpotOut &&
                                                data.print_data.jackpotOut
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div> */}

                                    <div className="divTableRow">
                                        <div className="divTableCell">Total In</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.totalIn &&
                                                data.print_data.totalIn
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell">Total Out</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.totalOut &&
                                                data.print_data.totalOut
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>



                                    <div className="divTableRow">
                                        <div className="divTableCell">Reversal</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.reversal &&
                                                data.print_data.reversal
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell">Profit</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.netProfit &&
                                                data.print_data.netProfit
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>
                                    <div className="divTableRow">
                                        <div className="divTableCell">Open Payouts</div>
                                        <div className="divTableCell">
                                            {
                                                data.print_data.openPayouts &&
                                                data.print_data.openPayouts
                                            }
                                            {
                                                localStorage.getItem('currency_symbol')
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default Settlement