import React, { useState, useEffect, useCallback, useReducer } from 'react';
import Table from 'react-bootstrap/Table';
import DateTimePicker from 'react-datetime-picker';
import { Scrollbars } from "react-custom-scrollbars-2";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ColorRing } from "react-loader-spinner";
import Language from "../assets/language.json";

function Sidebar() {

    const [array_of_persons, set_array_of_persons] = useState([]);

    const [sidebar, setSidebar] = useState(false);
    const [show_bar, set_show_bar] = useState(false);
    const [send_receive_money, set_send_receive_money] = useState(false);
    const [balance_sheet, set_balance_sheet] = useState(false);
    const [support, set_support] = useState(false);

    const [money_send, set_money_send] = useState(false);
    const [money_receive, set_money_receive] = useState(false);

    const [value, onChange] = useState(new Date());
    const [balance_value, balance_onChange] = useState(new Date());
    const [confirm_submit_send, set_confirm_submit_send] = useState(false);

    const [send_money_form_amount, set_send_money_form_amount] = useState("");
    const [send_money_form_type, set_send_money_form_type] = useState("null");
    const [send_money_form_comments, set_send_money_form_comments] = useState("");
    const [send_money_form_to, set_send_money_form_to] = useState("nul");
    const [send_money_form_ref, set_send_money_form_ref] = useState("");

    const [sent_transations, set_sent_transations] = useState([]);
    const [receive_transations, set_receive_transations] = useState([]);




    const [deposit_cash, set_deposit_cash] = useState(0);
    const [deposit_online, set_deposit_online] = useState(0);
    const [football_stake, set_football_stake] = useState(0);
    const [keno_profit_loss, set_keno_profit_loss] = useState(0);
    const [keno_jackpot_profit_loss, set_keno_jackpot_profit_loss] = useState(0);
    const [virtual_profit_loss, set_virtual_profit_loss] = useState(0);
    const [football_payment, set_football_payment] = useState(0);
    const [widthdrawal_online, set_widthdrawal_online] = useState(0);
    const [widthdrawal_cash, set_widthdrawal_cash] = useState(0);
    const [expenses, set_expenses] = useState([{ "description": "", "amount": 0 }]);

    const [is_profit_or_loss_keno, set_is_profit_or_loss_keno] = useState('profit');
    const [is_profit_or_loss_keno_jackpot, set_is_profit_or_loss_keno_jackpot] = useState('profit');
    const [is_profit_or_loss_virtual, set_is_profit_or_loss_virtual] = useState('profit');

    const [fake_update, forceUpdate] = useReducer(x => x + 1, 0);

    const [teller_comments, set_teller_comments] = useState("");

    const [submit_balance_sheet, set_submit_balance_sheet] = useState(false);

    const [_show_connection_spinner, set_show_connection_spinner] = useState(false);



    const [support_problem, set_support_problem] = useState("null");
    const [support_message, set_support_message] = useState("");
    const [support_barcode, set_support_barcode] = useState("");
    const [support_confirm_submit, set_support_confirm_submit] = useState(false);
    const [support_history, set_support_history] = useState([]);

    const [balance_opening_balance, set_balance_opening_balance] = useState(0);

    const [balance_closing_balance, set_balance_closing_balance] = useState(0);

    const [balance_sub_total, set_balance_sub_total] = useState(0);

    const [block_interface, set_block_interface] = useState(false);

    const [_user_language, _set_user_language] = useState(localStorage.getItem("language"));


    const [send_money_please_wait, set_send_money_please_wait] = useState(false);
    const [support_please_wait, set_support_please_wait] = useState(false);

    const toggle_sidebar = () => {
        if ($('#root').hasClass('open-sidebar')) {
            $('#root').removeClass('open-sidebar');
            set_send_receive_money(false);
            set_balance_sheet(false);
            set_money_send(false);
            set_money_receive(false);
            set_confirm_submit_send(false);
            set_support(false);
        } else {
            $('#root').addClass('open-sidebar');
            setSidebar(true);
        }
    }

    const add_more_fields = () => {
        set_expenses([...expenses, { "description": "", "amount": "" }]);
    }

    const reset_sent_form = async () => {
        $('.reset-form').addClass('rotate');
        setTimeout(() => {
            set_send_money_form_amount("");
            set_send_money_form_type("null");
            set_send_money_form_comments("");
            set_confirm_submit_send(false);
            set_send_money_form_ref("");
            set_send_money_form_to("null");
            $('.reset-form').removeClass('rotate');
        }, 1000);
    }

    const _get_users = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "cash-transaction-users/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                set_array_of_persons(data.data)
            });
    };

    const submit_send_money = async () => {
        set_send_money_please_wait(true);
        let date = value;
        let date_string = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

        if (send_money_form_amount == 0 || send_money_form_amount == undefined || send_money_form_amount == "") {
            toast.error("Please enter a valid amount");
            set_send_money_please_wait(false);
            return;
        }
        if (send_money_form_type == "null" || send_money_form_type == undefined || send_money_form_type == "") {
            toast.error("Please select a transaction type");
            set_send_money_please_wait(false);
            return;
        }

        if (date_string == "" || date_string == undefined) {
            toast.error("Please select a valid date");
            set_send_money_please_wait(false);
            return;
        }

        if (send_money_form_to == "null" || send_money_form_to == undefined || send_money_form_to == "") {
            toast.error("Please select a valid receiver");
            set_send_money_please_wait(false);
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                amount_sent: send_money_form_amount,
                transaction_type: send_money_form_type,
                transaction_ref: send_money_form_ref || "",
                transaction_date: date_string,
                comments: send_money_form_comments || "",
                receiver_id: send_money_form_to
            }),
        };
        fetch(process.env.REACT_APP_API_URL + "add-cash-transaction/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message == "cash_transaction_added") {
                    refresh_send_history();
                    reset_sent_form();
                    toast.success("Transaction Successful");
                    setTimeout(() => {
                        set_send_money_please_wait(false);
                    }, 2000);
                } else {
                    toast.error("Transaction Failed. Please try again later.");
                    set_send_money_please_wait(false);
                }
            });

        refresh_check_receive_interval();
    }

    const refresh_send_history = async () => {
        $('.reset-form').addClass('rotate');
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "cash-transaction-sent/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                set_sent_transations(data.sent_transactions)
                $('.reset-form').removeClass('rotate');
            });
    }

    const refresh_check_receive = async () => {
        $('.reset-form').addClass('rotate');
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "cash-transaction-received/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                set_receive_transations(data.received_transactions);
                $('.reset-form').removeClass('rotate');
            });
    }

    const accept_receive_money = async (transaction_id) => {
        $('.reset-form').addClass('rotate');
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id_transaction: transaction_id,
            }),
        };
        fetch(process.env.REACT_APP_API_URL + "cash-transaction-acknowledgement/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message == "cash_transaction_acknowledged") {
                    refresh_check_receive();
                    toast.success("Transaction Accepted");
                    refresh_check_receive_interval();
                }
            });
    }

    const update_opening_balance = async () => {
        $('.reset-form').addClass('rotate');
        update_balance();
        set_deposit_cash(localStorage.getItem('deposit_cash') || "");
        set_deposit_online(localStorage.getItem('deposit_online') || "");
        set_football_stake(localStorage.getItem('football_stake') || "");
        set_keno_profit_loss(localStorage.getItem('keno_profit_loss') || "");
        set_keno_jackpot_profit_loss(localStorage.getItem('keno_jackpot_profit_loss') || "");
        set_virtual_profit_loss(localStorage.getItem('virtual_profit_loss') || "");
        set_football_payment(localStorage.getItem('football_payment') || "");
        set_widthdrawal_online(localStorage.getItem('widthdrawal_online') || "");
        set_widthdrawal_cash(localStorage.getItem('widthdrawal_cash') || "");
        set_expenses(JSON.parse(localStorage.getItem('expenses')) || [{ "description": "", "amount": "" }]);

        set_is_profit_or_loss_keno(localStorage.getItem('is_profit_or_loss_keno') || "profit");
        set_is_profit_or_loss_keno_jackpot(localStorage.getItem('is_profit_or_loss_keno_jackpot') || "profit");
        set_is_profit_or_loss_virtual(localStorage.getItem('is_profit_or_loss_virtual') || "profit");
        set_teller_comments(localStorage.getItem('teller_comments') || "");
        $('.reset-form').removeClass('rotate');
    }

    const update_balance = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "get-user-closing-balance/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                set_balance_opening_balance(data.openingBalance);
            });
    }

    const save_values_to_local_storage = async () => {
        localStorage.setItem('deposit_cash', deposit_cash);
        localStorage.setItem('deposit_online', deposit_online);
        localStorage.setItem('football_stake', football_stake);
        localStorage.setItem('keno_profit_loss', keno_profit_loss);
        localStorage.setItem('keno_jackpot_profit_loss', keno_jackpot_profit_loss);
        localStorage.setItem('virtual_profit_loss', virtual_profit_loss);
        localStorage.setItem('football_payment', football_payment);
        localStorage.setItem('widthdrawal_online', widthdrawal_online);
        localStorage.setItem('widthdrawal_cash', widthdrawal_cash);
        localStorage.setItem('expenses', JSON.stringify(expenses));

        localStorage.setItem('is_profit_or_loss_keno', is_profit_or_loss_keno);
        localStorage.setItem('is_profit_or_loss_keno_jackpot', is_profit_or_loss_keno_jackpot);
        localStorage.setItem('is_profit_or_loss_virtual', is_profit_or_loss_virtual);
        localStorage.setItem('teller_comments', teller_comments);
    }

    const reset_balance_sheet = async () => {
        $('.reset-form').addClass('rotate');
        update_balance();
        setTimeout(() => {
            set_submit_balance_sheet(false);
            localStorage.removeItem('deposit_cash');
            localStorage.removeItem('deposit_online');
            localStorage.removeItem('football_stake');
            localStorage.removeItem('keno_profit_loss');
            localStorage.removeItem('keno_jackpot_profit_loss');
            localStorage.removeItem('virtual_profit_loss');
            localStorage.removeItem('football_payment');
            localStorage.removeItem('widthdrawal_online');
            localStorage.removeItem('widthdrawal_cash');
            localStorage.removeItem('expenses');
            localStorage.removeItem('is_profit_or_loss_keno');
            localStorage.removeItem('is_profit_or_loss_keno_jackpot');
            localStorage.removeItem('is_profit_or_loss_virtual');
            localStorage.removeItem('teller_comments');
            $('textarea').val('')
            $('.expenses-field input').val('')
            $('.reset-form').removeClass('rotate');
            update_opening_balance();
        }, 500)
    }

    const confirm_submission_balance_sheet = async () => {
        set_show_connection_spinner(true);
        if (deposit_cash == "" || deposit_online == "" || football_stake == "" || keno_profit_loss == "" || keno_jackpot_profit_loss == "" || virtual_profit_loss == "" || football_payment == "" || widthdrawal_online == "" || widthdrawal_cash == "") {
            toast.error("Please fill all fields in Balance Sheet section");
            set_show_connection_spinner(false);
            return;
        }

        if (expenses.length > 0) {
            for (let i = 0; i < expenses.length; i++) {
                if (expenses[i].amount !== "" && expenses[i].description == "") {
                    toast.error("Please fill all fields in Expenses section");
                    set_show_connection_spinner(false);
                    return;
                }
            }
        }

        let date = balance_value;
        let date_string = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();


        let temp = parseInt(balance_opening_balance) || 0;
        let subtotal = balance_sub_total + temp;

        let expenses_total = 0;
        if (expenses.length > 0) {
            for (let i = 0; i < expenses.length; i++) {
                if (expenses[i].amount !== "") {
                    expenses_total += parseInt(expenses[i].amount);
                }
            }
        }
        let closing_balance = subtotal - expenses_total;

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                date_created: date_string,
                opening_balance: balance_opening_balance,
                deposit: deposit_cash,
                deposit_online: deposit_online,
                football_stakes: football_stake,
                keno_stakes: is_profit_or_loss_keno == "loss" ? makeNegative(keno_profit_loss) : makePositive(keno_profit_loss),
                keno_jackpot: is_profit_or_loss_keno_jackpot == "loss" ? makeNegative(keno_jackpot_profit_loss) : makePositive(keno_jackpot_profit_loss),
                virtual: is_profit_or_loss_virtual == "loss" ? makeNegative(virtual_profit_loss) : makePositive(virtual_profit_loss),
                football_payments: football_payment,
                withdrawal_online: widthdrawal_online,
                withdrawal_cash: widthdrawal_cash,
                closing_balance: closing_balance,
                comments_teller: teller_comments || "",
                expenses: expenses
            }),
        };
        fetch(process.env.REACT_APP_API_URL + "create-balance-sheet/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.message == "balance_sheet_added") {
                    update_balance();
                    toast.success("Balance Sheet added successfully");
                    reset_balance_sheet();
                    set_show_connection_spinner(false);
                } else {
                    toast.error("Something went wrong");
                    set_show_connection_spinner(false);
                }
            });
    }

    const support_confirm_submission = async () => {
        set_support_please_wait(true);
        if (support_problem == "" || support_problem == "null") {
            toast.error("Please fill all fields in Support section");
            set_support_please_wait(false);
            return;
        }
        if (support_message == "") {
            toast.error("Please fill all fields in Support section");
            set_support_please_wait(false);
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: "Issue Regarding " + support_problem + " at shop" + localStorage.getItem('shop_name') || "",
                event: support_problem || "",
                barcode: support_barcode || "",
                description: support_message || ""
            }),
        };
        fetch(process.env.REACT_APP_API_URL + "create-support/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {

                if (data.message == "support_received") {
                    toast.success("Support request submitted successfully");
                    reset_support_form();
                    refresh_support_history();
                    set_support_please_wait(false);
                } else {
                    toast.error("Something went wrong. Try again later.");
                    reset_support_form()
                    set_support_please_wait(false);
                }
            })


    }

    const reset_support_form = async () => {
        set_support_problem("null");
        set_support_message("");
        set_support_confirm_submit(false);
        set_support_barcode("");
    }

    const calculate_sub_balance = () => {
        let temp = parseInt(balance_opening_balance) || 0;
        let temp2 = balance_sub_total + temp;
        return temp2.toLocaleString();
    }

    const calculate_total_balance = () => {
        let temp = parseInt(balance_opening_balance) || 0;
        let subtotal = balance_sub_total + temp;

        let expenses_total = 0;
        if (expenses.length > 0) {
            for (let i = 0; i < expenses.length; i++) {
                if (expenses[i].amount !== "") {
                    expenses_total += parseInt(expenses[i].amount);
                }
            }
        }
        let closing_balance = subtotal - expenses_total;
        return closing_balance.toLocaleString();
    }

    const refresh_check_receive_interval = async () => {
        set_block_interface(false);
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "cash-transaction-received/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                if (data.received_transactions.length > 0) {
                    data.received_transactions.map((transaction) => {
                        if (transaction.received_status == "0") {
                            set_block_interface(true);
                            return;
                        }
                    });
                }
            });
    }

    const refresh_support_history = async () => {
        $('.reset-form').addClass('rotate');
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "get-support/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                set_support_history(data.support);
                $('.reset-form').addClass('rotate');
            });
    }

    useEffect(() => {
        if (window.location.pathname == '/') {
            set_show_bar(false);
        } else {
            set_show_bar(true);
        }
    }, [])

    useEffect(() => {

        if (balance_sheet == true) {

            let deposit_cash_value = parseInt(deposit_cash) || 0;
            let deposit_online_value = parseInt(deposit_online) || 0;
            let football_stake_value = parseInt(football_stake) || 0;
            let keno_profit_loss_value = parseInt(keno_profit_loss) || 0;
            let keno_jackpot_profit_loss_value = parseInt(keno_jackpot_profit_loss) || 0;
            let virtual_profit_loss_value = parseInt(virtual_profit_loss) || 0;
            let football_payment_value = parseInt(football_payment) || 0;

            let widthdrawal_online_value = parseInt(widthdrawal_online) || 0;
            let widthdrawal_cash_value = parseInt(widthdrawal_cash) || 0;


            let total = deposit_cash_value + deposit_online_value + football_stake_value - football_payment_value - widthdrawal_online_value - widthdrawal_cash_value;

            if (is_profit_or_loss_keno == "profit") {
                total = total + keno_profit_loss_value;
            } else {
                total = total - keno_profit_loss_value;
            }

            if (is_profit_or_loss_keno_jackpot == "profit") {
                total = total + keno_jackpot_profit_loss_value;
            } else {
                total = total - keno_jackpot_profit_loss_value;
            }

            if (is_profit_or_loss_virtual == "profit") {
                total = total + virtual_profit_loss_value;
            } else {
                total = total - virtual_profit_loss_value;
            }

            set_balance_sub_total(parseInt(total));
        }

    }, [deposit_cash, deposit_online, football_stake, keno_profit_loss, keno_jackpot_profit_loss, virtual_profit_loss, football_payment, widthdrawal_online, widthdrawal_cash, expenses, is_profit_or_loss_keno, is_profit_or_loss_keno_jackpot, is_profit_or_loss_virtual]);

    useEffect(() => {
        refresh_check_receive_interval();
        const interval = setInterval(() => {
            refresh_check_receive_interval();
        }, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, [])

    return (
        <>
            {
                show_bar == true &&
                <>
                    <div className='sideBarButtonWrap'>
                        <div className='sidebarButton'>
                            <button onClick={() => { if (balance_sheet == true) { save_values_to_local_storage(); } toggle_sidebar(); _get_users(); update_balance(); }}></button>
                        </div>
                    </div>
                    <div className='mainSidebar'>

                        {
                            sidebar == true && send_receive_money == false && balance_sheet == false &&
                            <div className="financial-options">
                                <div className="buttons-vertical">
                                    <button className='cnButtonTop' onClick={() => { set_send_receive_money(true) }}><b>{Language.translations.Sidebar.send_receive[_user_language]}</b> </button>
                                    <button className='cnButtonBottom' onClick={() => { set_balance_sheet(true); update_opening_balance(); }}><b>{Language.translations.Sidebar.balance[_user_language]}</b></button>
                                    <button className='cnButtonMid' onClick={() => { set_support(true); refresh_support_history(); }}><b>{Language.translations.Sidebar.support[_user_language]}</b></button>
                                </div>
                            </div>
                        }

                        {
                            send_receive_money == true && money_send == false && money_receive == false &&
                            <div className='money-transfer-options'>
                                <button className='transaction-back' onClick={() => { set_send_receive_money(false); set_balance_sheet(false) }}>{Language.translations.Sidebar.back[_user_language]}</button>
                                <div className="buttons-vertical">
                                    <button className='cnSend' onClick={() => { set_money_send(true); refresh_send_history(); }}>{Language.translations.Sidebar.send[_user_language]}</button>
                                    <button className='cnReceive' onClick={() => { set_money_receive(true); refresh_check_receive(); }}>{Language.translations.Sidebar.receive[_user_language]}</button>
                                </div>
                            </div>
                        }

                        {
                            support == true && balance_sheet == false &&
                            <div className='money-transfer-options support-message'>
                                <button className='transaction-back' onClick={() => { set_support(false); }}>{Language.translations.Sidebar.back[_user_language]}</button>
                                {
                                    support_please_wait == false &&
                                    <>
                                        <div className="send-money-form-wrap support-24-7">
                                            <div className='cnInner cnFormH'>
                                                <h1>{Language.translations.Sidebar.support[_user_language]}</h1>
                                                <span className="reset-form" onClick={() => { reset_support_form(); }}>
                                                    <img src="./images/refresh.svg" />
                                                </span>
                                                <div className="field">
                                                    <select name="problems" value={support_problem} onChange={(e) => { set_support_problem(e.target.value) }} >
                                                        <option value="null">{Language.translations.Sidebar.please_choose[_user_language]}</option>
                                                        <option value="settlement_issue">{Language.translations.Sidebar.settlement_issue[_user_language]}</option>
                                                        <option value="ticket_issue">{Language.translations.Sidebar.ticket_issue[_user_language]}</option>
                                                        <option value="game_issue">{Language.translations.Sidebar.game_problems[_user_language]}</option>
                                                        <option value="players_complaining">{Language.translations.Sidebar.players_complaining[_user_language]}</option>
                                                        <option value="others">{Language.translations.Sidebar.others[_user_language]}</option>
                                                    </select>
                                                </div>
                                                <div className="field">
                                                    <input type="text" value={support_barcode} onChange={(e) => { set_support_barcode(e.target.value) }} placeholder={Language.translations.Sidebar.barcode[_user_language]} />
                                                </div>
                                                <div className="field">
                                                    <textarea name="message" value={support_message} onChange={(e) => { set_support_message(e.target.value) }} placeholder={Language.translations.Sidebar.describe_problem[_user_language]}></textarea>
                                                </div>

                                                {
                                                    support_confirm_submit == false &&
                                                    <div className="submit-button">
                                                        <button onClick={() => { set_support_confirm_submit(true); }}>{Language.translations.Sidebar.submit[_user_language]}</button>
                                                    </div>
                                                }
                                                {
                                                    support_confirm_submit == true &&
                                                    <div className="submit-button-danger">
                                                        <button onClick={() => { support_confirm_submission(); }}>{Language.translations.Sidebar.confirm_submission[_user_language]}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="send-money-form-wrap support-historic">
                                            <div className='cnInner'>
                                                <h1>{Language.translations.Sidebar.support_history[_user_language]}</h1>
                                                <span className="reset-form" onClick={() => { refresh_support_history(); }}>
                                                    <img src="./images/refresh.svg" />
                                                </span>
                                                <div className='history-money'>
                                                    {
                                                        support_history && support_history.length > 0 &&
                                                        <Scrollbars>
                                                            <Table striped bordered hover className='send-table-history'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{Language.translations.Sidebar.date[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.issue[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.barcode[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.status[_user_language]}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        support_history.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{item.created_at && item.created_at}</td>
                                                                                    <td>{item.event && item.event}</td>
                                                                                    <td>{item.barcode && item.barcode}</td>
                                                                                    <td>
                                                                                        {item.status == 0 ? Language.translations.Sidebar.pending[_user_language] : Language.translations.Sidebar.resolved[_user_language]}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Scrollbars>
                                                    }
                                                    {
                                                        support_history.length == 0 &&
                                                        <p className="danger danger-table">
                                                            {Language.translations.Sidebar.no_history_found[_user_language]}
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    support_please_wait == true &&
                                    <>
                                        <div className="loader-check-connection">
                                            <ColorRing
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                                colors={[
                                                    "#003779",
                                                    "#ff171b",
                                                    "#ff8400",
                                                    "#008032",
                                                    "#ffcc00",
                                                ]}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        }

                        {
                            send_receive_money == true && money_send == true &&
                            <div className='money-transfer-options send-money-form'>
                                <button className='transaction-back' onClick={() => { set_money_send(false); set_confirm_submit_send(false); }}>{Language.translations.Sidebar.back[_user_language]}</button>
                                {
                                    send_money_please_wait == false &&
                                    <>
                                        <div className="send-money-form-wrap">
                                            <div className='cnInner'>
                                                <h1>{Language.translations.Sidebar.send_money[_user_language]}</h1>
                                                <span className="reset-form" onClick={() => { reset_sent_form(); }}>
                                                    <img src="./images/refresh.svg" />
                                                </span>
                                                <div className="field">
                                                    <div className="input">
                                                        <DateTimePicker format='dd-MM-yyyy h:mm:ss a ' onChange={onChange} value={value} />
                                                    </div>
                                                </div>
                                                <div className="field cnLeft">
                                                    <div className="input">
                                                        <input value={send_money_form_amount} onChange={(e) => { set_send_money_form_amount(e.target.value) }} placeholder={Language.translations.Sidebar.amount[_user_language]} type="number" /> <span><b>Ar</b></span>
                                                    </div>
                                                </div>
                                                <div className="field cnRight">
                                                    <div className="input">
                                                        <select value={send_money_form_type} onChange={(e) => { set_send_money_form_type(e.target.value) }}>
                                                            <option value="null" selected>{Language.translations.Sidebar.type[_user_language]}</option>
                                                            <option value="cash">{Language.translations.Sidebar.cash[_user_language]}</option>
                                                            <option value="mvola">Mvola</option>
                                                            <option value="orange_money">Orange Money</option>
                                                            <option value="bank_transfer">{Language.translations.Sidebar.bank_transfer[_user_language]}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {
                                                    array_of_persons && array_of_persons.length > 0 &&
                                                    <div className="field cnLast">
                                                        <div className="input">
                                                            <select className='input-person' onChange={(e) => { set_send_money_form_to(e.target.value) }} value={send_money_form_to}>
                                                                <option value={"null"}>{Language.translations.Sidebar.choose_person[_user_language]}</option>
                                                                {
                                                                    array_of_persons.map((item, index) => {
                                                                        return (
                                                                            <option value={item.id} key={index}>
                                                                                {item.name}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="cnRight">
                                                            <div className="input">
                                                                <input value={send_money_form_ref} onChange={(e) => { set_send_money_form_ref(e.target.value) }} type="text" placeholder={Language.translations.Sidebar.reference[_user_language]} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                                <div className="field cnTextarea">
                                                    <div className="input">
                                                        <textarea value={send_money_form_comments} onChange={(e) => { set_send_money_form_comments(e.target.value) }} placeholder={Language.translations.Sidebar.comments[_user_language]}></textarea>
                                                    </div>
                                                </div>
                                                {
                                                    confirm_submit_send == false &&
                                                    <div className="submit-button">
                                                        <button onClick={() => { set_confirm_submit_send(true); }}>{Language.translations.Sidebar.submit[_user_language]}</button>
                                                    </div>
                                                }
                                                {
                                                    confirm_submit_send == true &&
                                                    <div className="submit-button-danger">
                                                        <button onClick={() => { submit_send_money(); }}>{Language.translations.Sidebar.confirm_submission[_user_language]}</button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className='history-money-wrap'>
                                            <div className='cnInner'>
                                                <h1>{Language.translations.Sidebar.send_history[_user_language]}</h1>
                                                <span className="reset-form" onClick={() => { refresh_send_history(); }}>
                                                    <img src="./images/refresh.svg" />
                                                </span>
                                                <div className='history-money'>
                                                    {
                                                        sent_transations && sent_transations.length > 0 &&
                                                        <Scrollbars>
                                                            <Table striped bordered hover className='send-table-history'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{Language.translations.Sidebar.date[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.to_whom[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.type[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.amount[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.status[_user_language]}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        sent_transations.map((item, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{item.transaction_date}</td>
                                                                                    <td>{item.receiver.name}</td>
                                                                                    <td>{item.transaction_type}</td>
                                                                                    <td>{item.amount_sent} Ar</td>
                                                                                    <td>
                                                                                        {item.received_status == 0 ? Language.translations.Sidebar.pending[_user_language] : Language.translations.Sidebar.received[_user_language]}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Scrollbars>
                                                    }
                                                    {
                                                        sent_transations.length == 0 &&
                                                        <p className="danger danger-table">
                                                            No History Found
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    send_money_please_wait == true &&
                                    <>
                                        <div className="loader-check-connection">
                                            <ColorRing
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                                colors={[
                                                    "#003779",
                                                    "#ff171b",
                                                    "#ff8400",
                                                    "#008032",
                                                    "#ffcc00",
                                                ]}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        }

                        {
                            send_receive_money == true && money_receive == true &&
                            <div className='money-transfer-options send-money-form cnSecondForm'>
                                <button className='transaction-back' onClick={() => { set_money_receive(false); }}>{Language.translations.Sidebar.back[_user_language]}</button>
                                <div className="send-money-form-wrap">
                                    <div className='cnInner'>
                                        <h1>{Language.translations.Sidebar.receive_money[_user_language]}</h1>
                                        <span className="reset-form" onClick={() => { refresh_check_receive(); }}>
                                            <img src="./images/refresh.svg" />
                                        </span>
                                        <div className="received-money">
                                            <div className="received-money-inner">
                                                {
                                                    receive_transations && receive_transations.length > 0 &&
                                                    <div className="received-all">
                                                        <Scrollbars>
                                                            <Table striped bordered hover className='send-table-history'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{Language.translations.Sidebar.date[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.from[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.type[_user_language]}</th>
                                                                        <th>{Language.translations.Sidebar.amount[_user_language]}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        receive_transations.map((item, index) => {
                                                                            return (
                                                                                item.received_status == 0 &&
                                                                                <tr key={index}>
                                                                                    <td>{item.transaction_date}</td>
                                                                                    <td>{item.sender.name}</td>
                                                                                    <td>{item.transaction_type}</td>
                                                                                    <td>{item.amount_sent} Ar</td>
                                                                                    <td className='cnButtonTd'>
                                                                                        <button onClick={() => { accept_receive_money(item.id) }}>{Language.translations.Sidebar.accept[_user_language]}</button>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </Scrollbars>
                                                    </div>
                                                }
                                                {
                                                    receive_transations.length == 0 || receive_transations.filter(item => item.received_status == 0).length == 0 &&
                                                    <p className='danger danger-table'>{Language.translations.Sidebar.no_receivable[_user_language]}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='history-money-wrap'>
                                    <div className='cnInner'>
                                        <h1>{Language.translations.Sidebar.receive_history[_user_language]}</h1>
                                        <span className="reset-form" onClick={() => { refresh_check_receive(); }}>
                                            <img src="./images/refresh.svg" />
                                        </span>
                                        {
                                            receive_transations && receive_transations.length > 0 &&
                                            <div className='history-money'>
                                                <Scrollbars>
                                                    <Table striped bordered hover className='send-table-history'>
                                                        <thead>
                                                            <tr>
                                                                <th>{Language.translations.Sidebar.date[_user_language]}</th>
                                                                <th>{Language.translations.Sidebar.from[_user_language]}</th>
                                                                <th>{Language.translations.Sidebar.type[_user_language]}</th>
                                                                <th>{Language.translations.Sidebar.amount[_user_language]}</th>
                                                                {/* <th>Status</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                receive_transations.map((item, index) => {
                                                                    return (
                                                                        item.received_status == 1 &&
                                                                        <tr key={index}>
                                                                            <td>{item.transaction_date}</td>
                                                                            <td>{item.sender.name}</td>
                                                                            <td>{item.transaction_type}</td>
                                                                            <td>{item.amount_sent} Ar</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Scrollbars>
                                            </div>
                                        }
                                        {
                                            receive_transations.length == 0 || receive_transations.filter(item => item.received_status == 1).length == 0 &&
                                            <p className='danger danger-table'><th>{Language.translations.Sidebar.no_history_found[_user_language]}</th></p>
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            sidebar == true && send_receive_money == false && balance_sheet == true &&
                            <>
                                <div className='money-transfer-options'>
                                    <button className='transaction-back' onClick={() => { set_balance_sheet(false); save_values_to_local_storage(); }}>{Language.translations.Sidebar.back[_user_language]}</button>
                                    <div className="send-money-form-wrap cnFormBal">
                                        {
                                            block_interface == true &&
                                            <>
                                                <div className="baron-financial sidebar-warning">
                                                    <div className="cnInner">
                                                        <div className="cnTopLogoKeno"></div>
                                                        <h1>{Language.translations.Sidebar.pending_account[_user_language]}</h1>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className='cnInner cnFormH'>
                                            <h1>{Language.translations.Sidebar.balance[_user_language]}</h1>
                                            <span className="reset-form" onClick={() => { reset_balance_sheet(); }}>
                                                <img src="./images/refresh.svg" />
                                            </span>
                                            <Scrollbars>
                                                {
                                                    _show_connection_spinner == false &&
                                                    <div className="cn-form cnBalanceForm">
                                                        <div className="cn-form-inner">
                                                            <div className="balance-field-wrapper cnDateTime">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.date_time[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <DateTimePicker format='dd-MM-yyyy h:mm:ss a ' onChange={balance_onChange} value={balance_value} />
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.opening_balance[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={parseInt(balance_opening_balance)} disabled={true} type="text" className="opening_balance" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.deposit[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={deposit_cash} onChange={(e) => { set_deposit_cash(e.target.value.replace(/\D/g, "")); }} type="text" className="deposit" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.deposit_online[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={deposit_online} onChange={(e) => { set_deposit_online(e.target.value.replace(/\D/g, "")); }} type="text" className="deposit_online" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.football_stake[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={football_stake} onChange={(e) => { set_football_stake(e.target.value.replace(/\D/g, "")); }} type="text" className="fotball_stake" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p><b>Keno</b> {Language.translations.Sidebar.profit_loss[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={keno_profit_loss} onChange={(e) => { set_keno_profit_loss(e.target.value.replace(/\D/g, "")); }} type="text" className="p_l_keno cnPad" /> <strong className='cnAr'>Ar</strong>
                                                                    <div className={is_profit_or_loss_keno == "profit" ? 'p_toggle_pos p_l_toggle plus-minus-toggle collapsed' : 'p_toggle_pos p_l_toggle plus-minus-toggle'} onClick={() => { if ($('.p_l_toggle.plus-minus-toggle').hasClass('collapsed')) { $('.p_l_toggle.plus-minus-toggle').removeClass('collapsed'); set_is_profit_or_loss_keno('loss'); } else { $('.p_l_toggle.plus-minus-toggle').addClass('collapsed'); set_is_profit_or_loss_keno('profit'); } }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p><b>Keno Jackpot</b> {Language.translations.Sidebar.profit_loss[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={keno_jackpot_profit_loss} onChange={(e) => { set_keno_jackpot_profit_loss(e.target.value.replace(/\D/g, "")); }} type="text" className="p_l_keno_jackpot cnPad" /> <strong className='cnAr'>Ar</strong>
                                                                    <div className={is_profit_or_loss_keno_jackpot == "profit" ? 'p_toggle_pos p_l_jackpot_toggle plus-minus-toggle collapsed' : 'p_toggle_pos p_l_jackpot_toggle plus-minus-toggle'} onClick={() => { if ($('.p_l_jackpot_toggle.plus-minus-toggle').hasClass('collapsed')) { $('.p_l_jackpot_toggle.plus-minus-toggle').removeClass('collapsed'); set_is_profit_or_loss_keno_jackpot('loss'); } else { $('.p_l_jackpot_toggle.plus-minus-toggle').addClass('collapsed'); set_is_profit_or_loss_keno_jackpot('profit'); } }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p><b>{Language.translations.Sidebar.virtual[_user_language]}</b> {Language.translations.Sidebar.profit_loss[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={virtual_profit_loss} onChange={(e) => { set_virtual_profit_loss(e.target.value.replace(/\D/g, "")); }} type="text" className="p_l_virtual cnPad" /> <strong className='cnAr'>Ar</strong>
                                                                    <div className={is_profit_or_loss_virtual == "profit" ? 'p_toggle_pos p_l_virtual_toggle plus-minus-toggle collapsed' : 'p_toggle_pos p_l_virtual_toggle plus-minus-toggle'} onClick={() => { if ($('.p_l_virtual_toggle.plus-minus-toggle').hasClass('collapsed')) { $('.p_l_virtual_toggle.plus-minus-toggle').removeClass('collapsed'); set_is_profit_or_loss_virtual('loss'); } else { $('.p_l_virtual_toggle.plus-minus-toggle').addClass('collapsed'); set_is_profit_or_loss_virtual('profit'); } }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.football_payment[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={football_payment} onChange={(e) => { set_football_payment(e.target.value.replace(/\D/g, "")); }} type="text" className="fotball_payment" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.withdraw_online[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={widthdrawal_online} onChange={(e) => { set_widthdrawal_online(e.target.value.replace(/\D/g, "")); }} type="text" className="withdrawal_online" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className="balance-field-wrapper cnTwoCol">
                                                                <div className="input-label">
                                                                    <p>{Language.translations.Sidebar.withdraw_cash[_user_language]}</p>
                                                                </div>
                                                                <div className="balance-field">
                                                                    <input placeholder='0' value={widthdrawal_cash} onChange={(e) => { set_widthdrawal_cash(e.target.value.replace(/\D/g, "")); }} type="text" className="withdrawal_cash" /> <strong className='cnAr'>Ar</strong>
                                                                </div>
                                                            </div>
                                                            <div className='cnSubTotalWrap'>
                                                                <div className='left-col'>
                                                                    <p>{Language.translations.Sidebar.sub_total[_user_language]}</p>
                                                                </div>
                                                                <div className='right-col'>
                                                                    <p>
                                                                        {calculate_sub_balance()}  <strong>Ar</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="subHeading">
                                                                <h2>{Language.translations.Sidebar.expenses[_user_language]}</h2>
                                                            </div>
                                                            <div className="expenses">
                                                                <button className='addMoreExpenses' onClick={() => { add_more_fields() }}></button>
                                                                {
                                                                    expenses.map((obj, index) => {
                                                                        return (
                                                                            <div className="expenses-field-wrapper" key={index}>
                                                                                <div className="left-col">
                                                                                    <div className="input-label">
                                                                                        <p>{Language.translations.Sidebar.description[_user_language]}</p>
                                                                                    </div>
                                                                                    <div className="expenses-field">
                                                                                        <textarea defaultValue={obj.description} onChange={(e) => { let aa = expenses; aa[index].description = e.target.value; set_expenses(aa); forceUpdate(); }} className='expenses_description'></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="right-col">
                                                                                    <div className="input-label">
                                                                                        <p>{Language.translations.Sidebar.amount[_user_language]} <b>AR</b></p>
                                                                                    </div>
                                                                                    <div className="expenses-field">
                                                                                        <input defaultValue={obj.amount} onChange={(e) => { let aa = expenses; aa[index].amount = e.target.value.replace(/\D/g, ""); set_expenses(aa); forceUpdate(); }} type="text" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <div className="subHeading cnComments">
                                                                <h2>{Language.translations.Sidebar.comments[_user_language]}</h2>
                                                            </div>
                                                            <div className="teller-commetns">
                                                                <textarea value={teller_comments} onChange={(e) => { set_teller_comments(e.target.value) }} placeholder={Language.translations.Sidebar.comments[_user_language]}></textarea>
                                                            </div>
                                                            <div className='cnClosingWrap'>
                                                                <div className='left-col'>
                                                                    <p>{Language.translations.Sidebar.closing_balance[_user_language]}</p>
                                                                </div>
                                                                <div className='right-col'>
                                                                    <p>{calculate_total_balance()} <strong>Ar</strong></p>
                                                                </div>
                                                                <div className='full-col'>
                                                                    {
                                                                        submit_balance_sheet == false &&
                                                                        <button onClick={() => { set_submit_balance_sheet(true); }} className='cnSubmitBtn'>{Language.translations.Sidebar.submit[_user_language]}</button>
                                                                    }
                                                                    {
                                                                        submit_balance_sheet == true &&
                                                                        <button onClick={() => { confirm_submission_balance_sheet(); }} className='cnSubmitBtn danger'>{Language.translations.Sidebar.confirm_submission[_user_language]}</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    _show_connection_spinner == true &&
                                                    <div className="loader-check-connection">
                                                        <ColorRing
                                                            visible={true}
                                                            height="80"
                                                            width="80"
                                                            ariaLabel="blocks-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass="blocks-wrapper"
                                                            colors={[
                                                                "#003779",
                                                                "#ff171b",
                                                                "#ff8400",
                                                                "#008032",
                                                                "#ffcc00",
                                                            ]}
                                                        />
                                                    </div>
                                                }
                                            </Scrollbars>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                    <ToastContainer position="top-right" />
                </>
            }
        </>
    )
}

export default Sidebar

function makeNegative(num) {
    return num * -1;
}

function makePositive(num) {
    return num * 1;
}
