import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Keyboard from "react-simple-keyboard";
import Language from "../assets/language.json";
import "react-toastify/dist/ReactToastify.min.css";
import "react-simple-keyboard/build/css/index.css";

function Login() {
	/*
	*/
	const navigate = useNavigate();
	const [_username, _set_username] = useState("");
	const [_password, _set_password] = useState("");
	const [_focus, _set_focus] = useState("username");
	const [_input, _set_input] = useState("");
	const [_show_password, _set_show_password] = useState(false);
	const [layout, setLayout] = useState("default");
	const [_user_language, _set_user_language] = useState("en");
	const keyboard = useRef();



	const handleShift = () => {
		const newLayoutName = layout === "default" ? "shift" : "default";
		setLayout(newLayoutName);
	};

	const handleBackspace = async () => {
		if (_focus == "username") {
			let tmp = _username;
			let new_tmp = tmp.slice(0, -1);
			_set_username(new_tmp);
		}
		if (_focus == "password") {
			let tmp = _password;
			let new_tmp = tmp.slice(0, -1);
			_set_password(new_tmp);
		}
	};

	const onKeyPress = (button) => {
		/**
		 * If you want to handle the shift and caps lock buttons
		 */

		if (button === "{shift}" || button === "{lock}") {
			handleShift();
		}

		if (button === "{bksp}") {
			handleBackspace();
		}

		if (
			_focus == "username" &&
			button !== "{shift}" &&
			button !== "{lock}" &&
			button !== "{tab}" &&
			button !== "{enter}" &&
			button !== "{bksp}"
		) {
			_set_username(_username + button);
		}

		if (
			_focus == "password" &&
			button !== "{shift}" &&
			button !== "{lock}" &&
			button !== "{tab}" &&
			button !== "{enter}" &&
			button !== "{bksp}"
		) {
			_set_password(_password + button);
		}
	};

	const _submit_login = async () => {
		/*
		*/
		if (_username === "" || _username === null || _username === undefined) {
			toast.error(Language.translations.login.toast._username[_user_language]);
			return;
		}

		/*
		*/
		if (_password === "" || _password === null || _password === undefined) {
			toast.error(Language.translations.login.toast.password[_user_language]);
			return;
		}

		/*
		*/
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: "Bearer " + process.env.REACT_APP_BEARER,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ email: _username, password: _password }),
		};
		fetch(process.env.REACT_APP_API_URL + "login-user", requestOptions)
			.then((response) => response.json())
			.then(async (data) => {
				if (data && data.email && data.email[0] == "This email address does not exists.") {
					toast.error(Language.translations.login.toast.error_creds[_user_language]);
					return;
				}
				if (data && data.message == "invalid_password") {
					toast.error(Language.translations.login.toast.error_creds[_user_language]);
					return;
				}
				if (data.message == "login_success") {
					localStorage.setItem("new_features", data.new_features)
					localStorage.setItem("user_logged", "true");
					localStorage.setItem("user_id", data.user_id);
					localStorage.setItem("auth_token", data.token);
					localStorage.setItem("name", data.name);
					localStorage.setItem("shop_name", data.shop_name);
					localStorage.setItem("shop_id", data.shop_id);
					// navigate("/keno-game");
					window.location.href = "/keno-game";
					return;
				} else {
					toast.error(Language.translations.login.toast.something_wrong[_user_language]);
					return;
				}
			});
	};

	const _check_language = async () => {
		let lang = localStorage.getItem("language");
		if (lang == undefined || lang == null) {
			localStorage.setItem("language", "en");
			_set_user_language("en");
		} else {
			_set_user_language(lang);
		}
	};

	const _toggle_password = () => {
		_set_show_password(!_show_password);
	};

	const _get_informations = async () => {
		/*
		*/
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: "Bearer " + process.env.REACT_APP_BEARER,
				"Content-Type": "application/json",
			},
		};
		fetch(process.env.REACT_APP_API_URL + "country-details", requestOptions)
			.then((response) => response.json())
			.then(async (data) => {
				localStorage.setItem("currency", data.currency);
				localStorage.setItem("currency_symbol", data.currency_symbol);
				localStorage.setItem("locale", data.locale);
				localStorage.setItem("timezone", data.timezone);
			});
	}

	useEffect(() => {
		_check_language();
		_get_informations();
	}, []);



	return (
		<>
			<div className="section fondLogin">
				<div className="container h-100">
					<div className="row align-items-center h-100">
						<div className="col col-sm-3">
							<div className="cnInner cnLeft">
								<div className="cnLogo">
									<img src="./images/keno-logo-trans.svg" alt="Keno" />
									<div className="cnSlogan">
										<p>
											{Language.translations.login.keno_logo[_user_language]}
										</p>
									</div>
								</div>
								<div className="cnImage">
									<img src="./images/lady-with-round-ball.png" alt="Keno" />
								</div>
							</div>
						</div>
						<div className="col col-sm-9">
							<div className="cnInner cnRight">
								<div className="cnLang">
									<select
										name="lang"
										id="lang"
										value={_user_language}
										onChange={(e) => {
											localStorage.setItem("language", e.target.value);
											_set_user_language(e.target.value);
										}}
									>
										<option value="en">EN</option>
										<option value="fr">FR</option>
									</select>
								</div>
								<h1>
									{Language.translations.login.authentication[_user_language]}
								</h1>
								<div className="form">
									<div className="cnTop">
										<div className="form-item username-textfield">
											<input
												value={_username}
												onChange={(e) => {
													_set_username(e.target.value);
												}}
												type="text"
												className="username"
												placeholder={
													Language.translations.login.username[_user_language]
												}
												autoComplete="false"
												onFocus={() => {
													_set_focus("username");
												}}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														_submit_login();
													}
												}}
											/>
										</div>
										<div className="form-item password-textfield">
											<input
												value={_password}
												onChange={(e) => {
													_set_password(e.target.value);
												}}
												type={_show_password ? "text" : "password"}
												className="password"
												placeholder={
													Language.translations.login.password[_user_language]
												}
												autoComplete="false"
												onFocus={() => {
													_set_focus("password");
												}}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														_submit_login();
													}
												}}
											/>
											<button
												className="showPassword"
												onClick={_toggle_password}
											></button>
										</div>
										<div className="cnConnect submit-button">
											<button
												onClick={() => {
													_submit_login();
												}}
												className="submit"
											>
												{Language.translations.login.connect[_user_language]}
											</button>
										</div>
									</div>
									<div className="keyboard">
										<Keyboard
											keyboardRef={(r) => (keyboard.current = r)}
											layoutName={layout}
											onKeyPress={onKeyPress}
											theme={""}
										/>
									</div>
								</div>
								<div className="cnFooter">
									<div className="baron-bet-logo">
										<img src="./images/baron.svg" alt="Keno" />
									</div>
									<div className="copyright">
										Ⓒ Baron Bet, {
											localStorage.getItem('timezone') ?
												changeTimeZone(new Date(), localStorage.getItem('timezone')).getFullYear() :
												new Date().getFullYear()
										}.{" "}
										{Language.translations.login.footer_text[_user_language]}
									</div>
								</div>
								<ToastContainer position="top-right" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Login;

function changeTimeZone(date, timeZone) {
	if (typeof date === 'string') {
		return new Date(
			new Date(date).toLocaleString('en-US', {
				timeZone,
			}),
		);
	}

	return new Date(
		date.toLocaleString('en-US', {
			timeZone,
		}),
	);
}