import React from "react";

function JackpotLastNumbers(data) {
	return (
		<div>
			<div id="invoice-POS" className="jackpot-last-numbers">
				<div className="jackpot-last-main-inner">
					<div id="top-jackpot">
						<div className="cnInner">
							{
								data.jackpot_info.lastDrawDate &&
								<div className="cnDate">
									<p>{data.jackpot_info.lastDrawDate}</p>
								</div>
							}
							<div className="cnImg">
								<img src="./images/keno-jackpot-black.svg" />
							</div>
						</div>
					</div>
					{
						data._last_draw_winning_number &&
						<div id="middle">
							<ul>
								<li>{data._last_draw_winning_number[0]}</li>
								<li>{data._last_draw_winning_number[1]}</li>
								<li>{data._last_draw_winning_number[2]}</li>
								<li>{data._last_draw_winning_number[3]}</li>
								<li>{data._last_draw_winning_number[4]}</li>
								<li>{data._last_draw_winning_number[5]}</li>
								<li>{data._last_draw_winning_number[6]}</li>
								<li>{data._last_draw_winning_number[7]}</li>
							</ul>
						</div>
					}
					<div id="bottom">
						<div className="cnInner">
							<p>Faritra mpandresy : {data.jackpot_info.lastDrawWinnerRegion ? data.jackpot_info.lastDrawWinnerRegion : " - "}</p>
							{
								data.jackpot_info.lastJackpotAmount &&
								<p>Haben'ny Jackpot : {data.jackpot_info.lastJackpotAmount.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.00', '')} Ar</p>
							}
							{
								data.jackpot_info.drawTime &&
								<p>Jackpot manaraka : {data.jackpot_info.drawTime}</p>
							}
						</div>
					</div>
				</div>
			</div>
		</div >
	);
}

export default JackpotLastNumbers;
