import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/Login";
import Keno from "./component/Keno";
import Televisions from "./component/Televisions";
import KenoDraw from "./component/KenoDraw";
import Jackpot from "./kenojackpot/Jackpot";
import Sidebar from "./globalcomponents/Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css'


const root = ReactDOM.createRoot(document.getElementById("root"));


const _check_session = async () => {
    if (localStorage.getItem("user_logged") === null) {
        if (window.location.pathname != "/") {
            window.location.href = "/";
            return;
        }
    }
};


document.addEventListener("DOMContentLoaded", () => {
    _check_session();
});


root.render(
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/keno-game" element={<Keno />} />
                <Route path="/games-tv" element={<Televisions />} />
                <Route path="/keno-draw" element={<KenoDraw />} />
                <Route path="/keno-jackpot" element={<Jackpot />} />
            </Routes>
        </BrowserRouter>
        {
            window.location.pathname !== '/' &&
                window.location.pathname !== '/games-tv' &&
                window.location.pathname !== '/keno-draw'
                ?
                <Sidebar />
                :
                <></>
        }
    </>
);