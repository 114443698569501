import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Clock from 'react-live-clock';
import Language from "../assets/language.json";
import { useReactToPrint } from 'react-to-print';
import Settlement from "./Settlement";
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from 'react-tooltip';

function Header(data) {

    /*
     */
    const navigate = useNavigate();
    const [_ticket_of_the_day_tab, _set_ticket_of_the_day_tab] = useState(data._toktok_day);
    const [_open_tickets_data, _set_open_tickets_data] = useState();
    const [_user_language, _set_user_language] = useState(localStorage.getItem("language"));
    const [_settlement_modal, _set_settlement_modal] = useState(false);
    const [_settlement_data, _set_settlement_data] = useState();
    const [_confirm_settlement, _set_confirm_settlement] = useState(false);
    const [_print_settlement_data, _set_print_settlement_data] = useState();
    const [active_settlement, set_active_settlement] = useState(false);
    const [please_wait, set_please_wait] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const _get_tickets_data = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "game-one-bet-list/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                _set_open_tickets_data(data.betList)
            });
    }

    const _open_modal_settlement = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "settlement/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {

                if (data.message == "settlement_already_done") {
                    toast.error("Settlement already done. Please contact Vipin for more information.");
                    return;
                }

                if (data.message == "bet_in_progress") {
                    toast.error(Language.translations.Header.bet_in_progress[_user_language]);
                    return;
                } else {
                    _set_settlement_data(data);
                    _set_settlement_modal(true);
                    return;
                }
            });
    }

    const _settle_account = async () => {
        _set_settlement_modal(false);
        set_please_wait(true);
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "confirm-settlement/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                _set_print_settlement_data(data)
                setTimeout(function () {
                    handlePrint();
                }, 200);
                setTimeout(function () {
                    _set_confirm_settlement(false);
                    _set_settlement_modal(false);
                    _set_settlement_data();
                    _set_print_settlement_data();
                    set_please_wait(false);
                }, 1000);
            });
    }

    const _logout_user = async () => {
        const requestOptions = {
            method: "POST",
            headers: {
                Authorization: localStorage.getItem('auth_token'),
                "Content-Type": "application/json",
            },
        };
        fetch(process.env.REACT_APP_API_URL + "logout/" + localStorage.getItem('user_id'), requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
                localStorage.clear();
                // navigate("/");
                window.location.href = "/";
            });
    }

    useEffect(() => {
        data._ticket_of_the_day(_ticket_of_the_day_tab);
    }, [_ticket_of_the_day_tab]);

    useEffect(() => {
        data._change_open_tickets(_open_tickets_data)
    }, [_open_tickets_data]);

    // useEffect(() => {
    //     const updateActiveSettlement = () => {
    //         const currentHour = new Date().getHours();
    //         if (currentHour >= 18 && currentHour < 21) {
    //             set_active_settlement(true);
    //         } else {
    //             set_active_settlement(false);
    //         }
    //     };
    //     updateActiveSettlement();
    //     const intervalId = setInterval(updateActiveSettlement, 1000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);


    return (
        <>
            {
                please_wait == true &&
                <div className="no-more-bets">
                    <div className="cnInner">
                        <h1>{Language.translations.Slip.please_wait[_user_language]}</h1>
                    </div>
                </div>
            }
            {
                _settlement_modal == true && _settlement_data !== undefined && _settlement_data != null &&
                <div className="modal-settings">
                    <div className="cnInner">
                        <div className="cnTop">
                            <h1>{Language.translations.Header.financial_statement[_user_language]}</h1>
                            <div className="cnClose">
                                <button
                                    onClick={() => {
                                        _set_confirm_settlement(false);
                                        _set_settlement_modal(false);
                                        _set_settlement_data();
                                        _set_print_settlement_data();
                                    }}
                                ></button>
                            </div>
                        </div>
                        <div className="cnBody">
                            <div className="center-element">
                                {
                                    _confirm_settlement == false &&
                                    <div className="form">
                                        <div className="divTable">
                                            <div className="divTableBody">
                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.number[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.nextSettlement &&
                                                            _settlement_data.nextSettlement
                                                        }
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.last_settlement[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.lastSettlement &&
                                                            _settlement_data.lastSettlement
                                                        }
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.actual_date_time[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.actualTime &&
                                                            _settlement_data.actualTime
                                                        }
                                                    </div>
                                                </div>





                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.total_in[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.totalIn &&
                                                            _settlement_data.totalIn
                                                        }
                                                        {
                                                            localStorage.getItem('currency_symbol')
                                                        }
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.total_out[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.totalOut &&
                                                            _settlement_data.totalOut
                                                        }
                                                        {
                                                            localStorage.getItem('currency_symbol')
                                                        }
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.reversal[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.reversal &&
                                                            _settlement_data.reversal
                                                        }
                                                        {
                                                            localStorage.getItem('currency_symbol')
                                                        }
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">Profit</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.netProfit &&
                                                            _settlement_data.netProfit
                                                        }
                                                        {
                                                            localStorage.getItem('currency_symbol')
                                                        }
                                                    </div>
                                                </div>
                                                <div className="divTableRow">
                                                    <div className="divTableCell">{Language.translations.Header.open_payouts[_user_language]}</div>
                                                    <div className="divTableCell">
                                                        {
                                                            _settlement_data.openPayouts &&
                                                            _settlement_data.openPayouts
                                                        }
                                                        {
                                                            localStorage.getItem('currency_symbol')
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="cnButSettle" onClick={() => { _set_confirm_settlement(true); }}>Settlement</button>
                                    </div>
                                }
                                {
                                    _confirm_settlement == true &&
                                    <div className="form cnFormSettle">
                                        <div className="desc">
                                            {Language.translations.Header.question_to_settle_account[_user_language]}
                                        </div>
                                        <div className="actions">
                                            <button className="cnConfirmBut" onClick={() => { _settle_account(); }}>{Language.translations.Header.yes[_user_language]}</button>
                                            <button className="cnDeclineBut" onClick={() => {
                                                _set_confirm_settlement(false);
                                                _set_settlement_modal(false);
                                                _set_settlement_data();
                                                _set_print_settlement_data();
                                            }}>{Language.translations.Header.no[_user_language]}</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                data._shop_credit <= 0 &&
                <div className="no-more-bets">
                    <div className="cnInner">
                        <h1>{Language.translations.Header.no_money[_user_language]}</h1>
                        <button className="no-money-logout" onClick={() => { _logout_user(); }}>{Language.translations.Header.logout[_user_language]}</button>
                    </div>
                </div>
            }
            <div className="cnTop">
                <div className="cnLogo">
                    <a onClick={() => { navigate("/keno-game"); }}>
                        <img src="./images/baron-bet-logo.svg" alt="Keno" />
                    </a>
                </div>
                <div className="cnMoreGames">
                    <ul className="cnClear">
                        <li className="kenoGame active">
                            <button onClick={() => { navigate("/keno-game"); }}></button>
                        </li>

                        {/* < li className="kenoJackpotGame">
                                <button onClick={() => { navigate("/keno-jackpot"); }}></button>
                            </li> */}
                    </ul>
                </div>
                <div className="cnTools">
                    <ul className="second">
                        <li className="cnLogout">
                            <button
                                onClick={() => {
                                    _logout_user();
                                }}
                            ></button>
                        </li>
                        <li className="cnScreen">
                            <button onClick={() => { window.open('/games-tv', '_blank', 'noopener,noreferrer'); }}></button>
                        </li>
                        <li
                            className={data._settings == true ? 'cnSet active' : 'cnSet'}
                        >
                            <button
                                onClick={() => {
                                    if (data._settings == true) {
                                        data._on_change_settings(false);
                                    } else {
                                        data._on_change_settings(true);
                                    }
                                }}
                            ></button>
                        </li>

                        <li
                            className={_settlement_modal == true ? 'cnCal active' : 'cnCal'}
                        >
                            <button onClick={() => { _open_modal_settlement(); }}></button>
                        </li>

                        <li
                            className={
                                data._toktok_day == true ? "cnDoc active" : "cnDoc"
                            }
                        >
                            <button
                                onClick={() => {
                                    if (data._toktok_day == false) {
                                        _get_tickets_data();
                                        data._ticket_of_the_day(true);
                                    } else {
                                        _set_open_tickets_data();
                                        data._ticket_of_the_day(false);
                                    }
                                }}
                            ></button>
                        </li>




                    </ul>
                    <ul className="first">
                        <li place data-tip={localStorage.getItem("name")} className="cnAccount left">{localStorage.getItem("name").substr(0, 5)}</li>
                        <li className="cnCash">{data._shop_credit}{localStorage.getItem('currency_symbol')}</li>
                        <li className="cnClock">
                            <Clock format={'HH:mm:ss'} ticking={true} timezone={localStorage.getItem('timezone')} />
                        </li>
                    </ul>
                </div>
            </div >
            <div ref={componentRef} className="waza">
                <Settlement
                    print_data={_print_settlement_data}
                />
            </div>
            <ToastContainer position="top-right" />
            <ReactTooltip place={"left"} />
        </>
    );
}

export default Header;
