import React, { useState } from "react";

function KenoDraw() {
    return (
        <iframe className="keno-game-draw" src={process.env.REACT_APP_KENO_GAME_URL + "/?game=" + generateRandomNumber() + "&id=" + generateRandomNumber() + "&authID=" + generateRandomNumber() + "&display=true&fullscreen=allow&secure=yes"} height="100%" width="100%"></iframe>
    )
}
export default KenoDraw

function generateRandomNumber() {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math
        .random() * (maxm - minm + 1)) + minm;
}