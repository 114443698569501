import React, { useEffect, useState } from "react";
import HeaderJackpot from './HeaderJackpot'
import { useNavigate } from "react-router-dom";
import JackpotTab from "./JackpotTab";
import Language from "../assets/language.json";




function Jackpot(data) {

    const navigate = useNavigate();
    const [_shop_credit, _set_shop_credit] = useState();
    let [_ticket_day, _set_ticket_day] = useState(false);
    let [_open_tickets, _set_open_tickets] = useState();
    let [_show_iframe, _set_show_iframe] = useState(false);
    let [_settings, _set_settings] = useState(false);


    const _check_session = async () => {
        if (localStorage.getItem("user_logged") === null) {
            navigate("/");
            return;
        }
    };

    const _fetch_shop_money = async () => {
        /*
        */
        if (localStorage.getItem('user_id')) {
            const requestOptions = {
                method: "POST",
                headers: {
                    Authorization: localStorage.getItem('auth_token'),
                    "Content-Type": "application/json",
                },
            };
            fetch(process.env.REACT_APP_API_URL + "shop-credit/" + localStorage.getItem('user_id'), requestOptions)
                .then((response) => response.json())
                .then(async (data) => {
                    if (data.credit) {
                        _set_shop_credit(data.credit)
                    } else {
                        navigate("/");
                        return;
                    }
                });
        }
    }

    const show_frame = async () => {
        _set_show_iframe(true)
    }

    const _set_ticket_of_the_day = async (e) => {
        _set_ticket_day(e);
    };

    const _change_settings_modal = async (e) => {
        _set_settings(e)
    }

    useEffect(() => {
        _check_session();
        _fetch_shop_money();
    }, []);

    return (
        <>
            <div className="board-wrapper" id="keno-jackpot">
                <HeaderJackpot
                    _shop_credit={_shop_credit}
                    _ticket_of_the_day={(e) => { _set_ticket_of_the_day(e); }}
                    _toktok_day={_ticket_day}
                    _change_open_tickets={(e) => { _set_open_tickets(e) }}
                    _on_change_settings={(e) => { _change_settings_modal(e); }}
                    _settings={_settings}
                />
                <JackpotTab
                    _shop_credit={_shop_credit}
                    _update_slip={() => { _set_ticket_of_the_day(false) }}
                    all_ticket={_ticket_day}
                    tickets={_open_tickets}
                    keno_inside_update={_fetch_shop_money}
                    _show_frame={() => { show_frame() }}
                    _settings={_settings}
                    _on_change_settings={(e) => { _change_settings_modal(e); }}
                />
                {
                    _show_iframe == true &&
                    <>
                        <div className="modal-settings game-Popup">
                            <div className="cnInner">
                                <div className="cnBody">
                                    <div className="live-game">
                                        <div className="cnClose close-live-game">
                                            <button
                                                onClick={() => {
                                                    _set_show_iframe(false)
                                                }}
                                            ></button>
                                        </div>
                                        <iframe className="keno-game-draw" src={process.env.REACT_APP_KENO_GAME_URL + "/?game=" + generateRandomNumber() + "&id=" + generateRandomNumber() + "&authID=" + generateRandomNumber() + "&display=true&fullscreen=allow&secure=yes"} height="100%" width="100%"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

        </>
    )
}

export default Jackpot

function generateRandomNumber() {
    var minm = 100000;
    var maxm = 999999;
    return Math.floor(Math.random() * (maxm - minm + 1)) + minm;
}
