import React, { useEffect, useState, useReducer, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useReactToPrint } from "react-to-print";
import KenoBoard from "./KenoBoard";
import socketIOClient from "socket.io-client";
import "react-tabs/style/react-tabs.css";
import { useNavigate } from "react-router-dom";
import KenoSlip from "./KenoSlip";
import Language from "../assets/language.json";
import KenoTicketCopy from "./KenoTicketCopy";
import { ColorRing } from "react-loader-spinner";
import { Scrollbars } from "react-custom-scrollbars-2";
import Settlement from "./Settlement";
import $ from "jquery";


function KenoTab(data) {

	const navigate = useNavigate();
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const settlmentSlip = useRef();
	const reprint_settlement_handle = useReactToPrint({
		content: () => settlmentSlip.current,
	});


	const childFunc = React.useRef(null)
	const enterFunc = React.useRef(null)
	/*
	 */
	const [_user_language, _set_user_language] = useState(
		localStorage.getItem("language")
	);
	const [please_wait, set_please_wait] = useState(false);

	/*
	 */
	const ENDPOINT = process.env.REACT_APP_KENO_GAME_URL;
	const [_loading, _set_loading] = useState(true);
	const [_three_ids, _set_three_ids] = useState([]);
	const [_track_tab, _set_track_tab] = useState(0);

	/*
	 */
	let [_current_time, _set_current_time] = useState(0);
	let [_next_current_time, _set_next_current_time] = useState(0);
	let [_next_next_current_time, _set_next_next_current_time] = useState(0);

	/*
	 */
	let [_duration_1, _set_duration_1] = useState(180 - 10);
	let [_duration_2, _set_duration_2] = useState(_duration_1 + 60 + 180 - 10);
	let [_duration_3, _set_duration_3] = useState(_duration_2 + 60 + 180 - 10);

	/*
	 */
	let [_current_display_id, _set_current_display_id] = useState();
	let [_next_display_id, _set_next_display_id] = useState();
	let [_next_next_display_id, _set_next_next_display_id] = useState();

	/*
	 */
	let [_current_game_time, _set_current_game_time] = useState();
	let [_next_game_time, _set_next_game_time] = useState();
	let [_next_next_game_time, _set_next_next_game_time] = useState();

	/*
	 */
	let [_slip, _set_slip] = useState([]);
	let [_odds, _set_odds] = useState([]);

	/*
	 */
	let [_fastbet, _set_fastbet] = useState("");
	let [_sync, _set_sync] = useState(false);
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);

	/*
	 */
	let [_no_more_bets, _set_no_more_bets] = useState(false);
	let [_is_game_paused, _set_is_game_paused] = useState();
	let [_is_internet_good, _set_is_internet_good] = useState(true);
	let [_close_total_stake_modal, _set_close_total_stake_modal] = useState(true);

	/*
	*/
	const [_barcode, _set_barcode] = useState();
	let [_copy_ticket_bets, _set_copy_ticket_bets] = useState();
	let [_copy_ticket_date, _set_copy_ticket_date] = useState();
	let [_copy_ticket_max_odds, _set_copy_ticket_max_odds] = useState();
	let [_copy_ticket_max_potential_win, _set_copy_ticket_max_potential_win] = useState();
	let [_copy_ticket_max_total_win, _set_copy_ticket_max_total_win] = useState();
	let [_copy_ticket_min_odds, _set_copy_ticket_min_odds] = useState();
	let [_copy_ticket_min_potential_win, _set_copy_ticket_min_potential_win] = useState();
	let [_copy_ticket_time, _set_copy_ticket_time] = useState();
	let [_copy_ticket_total_stakes, _set_copy_ticket_total_stakes] = useState();

	/*
	*/
	let [_reprint_ticket_number, _set_reprint_ticket_number] = useState();
	let [_reprint_ticket_error, _set_reprint_ticket_error] = useState();
	let [_show_connection_spinner, _set_show_connection_spinner] = useState(false);

	let [_show_error, _set_show_error] = useState(false);

	const [_settlement_data, _set_settlement_data] = useState();
	const [last_settlement_print_in_progress, set_last_settlement_print_in_progress] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);


	/*
	 */
	const _manipulate_slip = async (board_id, row, times, disp_id) => {

		data._update_slip();

		var is_not_decimal = row.every(function (element) {
			return Number.isInteger(element);
		});
		if (is_not_decimal == false) {
			return;
		}

		row = row.sort(function (a, b) {
			return a - b;
		});
		/*
		 */
		var arr = _slip;

		/*
		 */
		let _is_same = false;
		for (let i = 0; i < arr.length; i++) {
			const element = arr[i];
			if (areEqual(element.ball, row) && element.id == board_id) {
				_is_same = true;
				return;
			}
		}

		/*
		 */
		if (_is_same == true) {
			_is_same = false;
			return;
		}

		/*
		 */
		if (checkIfDuplicateExists(row) == true) {
			return;
		}

		for (let i = 0; i < row.length; i++) {
			if (row[i] > 80) {
				_set_show_error(true);
				setTimeout(function () {
					_set_show_error(false);
				}, 1000);
				return;
			}
		}

		/*
		 */
		if (times == "1x") {
			let loop = [0];
			for (let i = 0; i < loop.length; i++) {
				var obj = {};
				if (i == 0) {
					obj["id"] = board_id;
				}
				obj["ball"] = row;
				obj["stakes"] = 1000;
				obj["display_id"] = disp_id;
				obj["odds"] =
					row.length == 8
						? 2500
						: row.length == 7
							? 1000
							: row.length == 6
								? 500
								: row.length == 5
									? 150
									: row.length == 4
										? 80
										: row.length == 3
											? 50
											: row.length == 2
												? 10
												: row.length == 1
													? 3.6
													: null;
				arr.push(obj);
				_set_slip(arr);
			}
		}
		if (times == "2x") {
			let loop = [0, 1];
			for (let i = 0; i < loop.length; i++) {
				var obj = {};
				if (i == 0) {
					obj["id"] = _three_ids[0];
					obj["display_id"] = _current_display_id;
				}
				if (i == 1) {
					obj["id"] = _three_ids[1];
					obj["display_id"] = _next_display_id;
				}
				obj["ball"] = row;
				obj["stakes"] = 1000;
				obj["odds"] =
					row.length == 8
						? 2500
						: row.length == 7
							? 1000
							: row.length == 6
								? 500
								: row.length == 5
									? 150
									: row.length == 4
										? 80
										: row.length == 3
											? 50
											: row.length == 2
												? 10
												: row.length == 1
													? 3.6
													: null;
				arr.push(obj);
				_set_slip(arr);
			}
		}
		if (times == "3x") {
			let loop = [0, 1, 2];
			for (let i = 0; i < loop.length; i++) {
				var obj = {};
				if (i == 0) {
					obj["id"] = _three_ids[0];
					obj["display_id"] = _current_display_id;
				}
				if (i == 1) {
					obj["id"] = _three_ids[1];
					obj["display_id"] = _next_display_id;
				}
				if (i == 2) {
					obj["id"] = _three_ids[2];
					obj["display_id"] = _next_next_display_id;
				}
				obj["ball"] = row;
				obj["stakes"] = 1000;
				obj["odds"] =
					row.length == 8
						? 2500
						: row.length == 7
							? 1000
							: row.length == 6
								? 500
								: row.length == 5
									? 150
									: row.length == 4
										? 80
										: row.length == 3
											? 50
											: row.length == 2
												? 10
												: row.length == 1
													? 3.6
													: null;
				arr.push(obj);
				_set_slip(arr);
			}
		}
	};

	/*
	 */
	const _manipulate_slip_with_custom_stakes = async (board_id, row, times, disp_id, stakes) => {
		data._update_slip();

		var is_not_decimal = row.every(function (element) {
			return Number.isInteger(element);
		});

		if (is_not_decimal == false) {
			return;
		}

		if (isNaN(stakes)) {
			_set_show_error(true);
			setTimeout(function () {
				_set_show_error(false);
			}, 1000);
			return;
		}

		for (let i = 0; i < row.length; i++) {
			if (row[i] > 80) {
				_set_show_error(true);
				setTimeout(function () {
					_set_show_error(false);
				}, 1000);
				return;
			}
		}

		row = row.sort(function (a, b) {
			return a - b;
		});
		/*
		 */
		var arr = _slip;

		/*
		 */
		let _is_same = false;
		for (let i = 0; i < arr.length; i++) {
			const element = arr[i];
			if (areEqual(element.ball, row) && element.id == board_id) {
				_is_same = true;
				return;
			}
		}

		/*
		 */
		if (_is_same == true) {
			_is_same = false;
			return;
		}

		/*
		 */
		if (checkIfDuplicateExists(row) == true) {
			return;
		}

		/*
		 */
		if (times == "1x") {
			let loop = [0];
			for (let i = 0; i < loop.length; i++) {
				var obj = {};
				if (i == 0) {
					obj["id"] = board_id;
				}
				obj["ball"] = row;
				obj["stakes"] = stakes;
				obj["display_id"] = disp_id;
				obj["odds"] =
					row.length == 8
						? 2500
						: row.length == 7
							? 1000
							: row.length == 6
								? 500
								: row.length == 5
									? 150
									: row.length == 4
										? 80
										: row.length == 3
											? 50
											: row.length == 2
												? 10
												: row.length == 1
													? 3.6
													: null;
				arr.push(obj);
				_set_slip(arr);
			}
		}
		if (times == "2x") {
			let loop = [0, 1];
			for (let i = 0; i < loop.length; i++) {
				var obj = {};
				if (i == 0) {
					obj["id"] = _three_ids[0];
					obj["display_id"] = _current_display_id;
				}
				if (i == 1) {
					obj["id"] = _three_ids[1];
					obj["display_id"] = _next_display_id;
				}
				obj["ball"] = row;
				obj["stakes"] = stakes;
				obj["odds"] =
					row.length == 8
						? 2500
						: row.length == 7
							? 1000
							: row.length == 6
								? 500
								: row.length == 5
									? 150
									: row.length == 4
										? 80
										: row.length == 3
											? 50
											: row.length == 2
												? 10
												: row.length == 1
													? 3.6
													: null;
				arr.push(obj);
				_set_slip(arr);
			}
		}
		if (times == "3x") {
			let loop = [0, 1, 2];
			for (let i = 0; i < loop.length; i++) {
				var obj = {};
				if (i == 0) {
					obj["id"] = _three_ids[0];
					obj["display_id"] = _current_display_id;
				}
				if (i == 1) {
					obj["id"] = _three_ids[1];
					obj["display_id"] = _next_display_id;
				}
				if (i == 2) {
					obj["id"] = _three_ids[2];
					obj["display_id"] = _next_next_display_id;
				}
				obj["ball"] = row;
				obj["stakes"] = stakes;
				obj["odds"] =
					row.length == 8
						? 2500
						: row.length == 7
							? 1000
							: row.length == 6
								? 500
								: row.length == 5
									? 150
									: row.length == 4
										? 80
										: row.length == 3
											? 50
											: row.length == 2
												? 10
												: row.length == 1
													? 3.6
													: null;
				arr.push(obj);
				_set_slip(arr);
			}
		}
	};

	/*
	 */
	const _remove_row_bet = async (i) => {
		_slip.splice(i, 1);
	};

	/*
	 */
	const remove_upon_change_tab = async (j) => {
		let tmp = _slip;
		for (var i = 0; i < tmp.length; i++) {
			if (tmp[i]["id"] === j) {
				tmp.splice(i, 1);
				remove_upon_change_tab(j);
			}
		}
		_set_slip(tmp);
	};

	/*
	 */
	const _fast_bet_quick_pick = async (_fast_bet) => {
		let tmp = _fast_bet.split("");
		_generate_quick_pick_random(tmp[1]);
	};

	/*
	 */
	const _generate_quick_pick_random = async (_quantity) => {
		let _rand_numbs = [];
		for (let i = 0; i < _quantity; i++) {
			let _tmp = _generate_random(1, 80, _rand_numbs);
			_rand_numbs.push(parseInt(_tmp));
		}
		_manipulate_slip(_three_ids[0], _rand_numbs, "1x", _current_display_id);
	};

	const _generate_quick_pick_random_to_different_game = async (_quantity, which_game) => {
		let qty = _quantity.replace("Q", "");
		let _rand_numbs = [];
		for (let i = 0; i < qty; i++) {
			let _tmp = _generate_random(1, 80, _rand_numbs);
			_rand_numbs.push(parseInt(_tmp));
		}
		_manipulate_slip(_three_ids[0], _rand_numbs, which_game.toLowerCase(), _current_display_id);
	}

	const _generate_quick_pick_random_to_different_game_with_custom_stakes = async (_quantity, which_game, stakes) => {
		let qty = _quantity.replace("Q", "");
		let _rand_numbs = [];
		for (let i = 0; i < qty; i++) {
			let _tmp = _generate_random(1, 80, _rand_numbs);
			_rand_numbs.push(parseInt(_tmp));
		}
		_manipulate_slip_with_custom_stakes(_three_ids[0], _rand_numbs, which_game.toLowerCase(), _current_display_id, stakes);
	}

	const _fast_bet_numbers_only = async (_number) => {
		_manipulate_slip(
			_three_ids[0],
			[parseInt(_number)],
			"1x",
			_current_display_id
		);
	};

	const _fast_bet_numbers_custom_stakes_only = async (fastbet) => {
		let tmp = fastbet.split("@");
		if (tmp[0] > 80) { return }
		_manipulate_slip_with_custom_stakes(_three_ids[0], [parseInt(tmp[0])], "1x", _current_display_id, parseInt(tmp[1]));
	}

	const _fast_bet_multiple_numbers = async (_numbers) => {
		let tmp = _numbers.split(",");
		let arr = [];
		for (let i = 0; i < 8; i++) {
			if (tmp[i]) {
				arr.push(parseInt(tmp[i]));
			}
		}
		_manipulate_slip(_three_ids[0], arr, "1x", _current_display_id);
	};

	const _fast_bet_multiple_numbers_with_custom_stakes = async (fastbet) => {

		let obj = fastbet.split(/,|@/);
		let arr = [];
		for (let i = 0; i < 8; i++) {
			if (obj[i] && obj[i] !== obj[obj.length - 1]) {
				arr.push(parseInt(obj[i]));
			}
		}
		_manipulate_slip_with_custom_stakes(_three_ids[0], arr, '1x', _current_display_id, obj[obj.length - 1]);
	}

	const _fast_bet_multiple_numbers_with_custom_stakes_and_multiple_games = async (fastbet) => {
		let obj = fastbet.split(/,|:|@/);
		let arr = [];
		for (let i = 0; i < 8; i++) {
			if (obj[i] && obj[i] !== obj[obj.length - 1] && obj[i] !== obj[obj.length - 2]) {
				arr.push(parseInt(obj[i]));
			}
		}
		_manipulate_slip_with_custom_stakes(_three_ids[0], arr, obj[obj.length - 2].toLowerCase(), _current_display_id, obj[obj.length - 1]);
	}

	const _remove_all_bets = async () => {
		let tmp = _slip;
		for (var i = 0; i < tmp.length; i++) {
			tmp.splice(i, 1);
			_remove_all_bets();
		}
		_set_slip(tmp);
	};

	const _refresh_check_internet_connection = async () => {
		_set_show_connection_spinner(true);
		var startTime = new Date().getTime();
		var img = new Image();
		img.onload = function () {
			var loadtime = new Date().getTime() - startTime;
			let x = checkConnectionSpeed(loadtime);
			if (x == 'GOOD') {
				_set_is_internet_good(true);
				_set_show_connection_spinner(false);
			} else {
				_set_is_internet_good(false);
				_set_show_connection_spinner(false);
			}
		};
		img.src = "https://baronbet.starly.mu/images/pexels-photo-531880.jpeg?" + startTime;
	};

	const _check_internet_connection = async () => {
		var startTime = new Date().getTime();
		var img = new Image();
		img.onload = function () {
			var loadtime = new Date().getTime() - startTime;
			let x = checkConnectionSpeed(loadtime);
			if (x == 'GOOD') {
				_set_is_internet_good(true);

			} else {
				_set_is_internet_good(false);
			}
		};
		img.src = "https://baronbet.starly.mu/images/pexels-photo-531880.jpeg?" + startTime;
		setInterval(() => {
			var startTime = new Date().getTime();
			var img = new Image();
			img.onload = function () {
				var loadtime = new Date().getTime() - startTime;
				let x = checkConnectionSpeed(loadtime);
				if (x == 'GOOD') {
					_set_is_internet_good(true);

				} else {
					_set_is_internet_good(false);
				}
			};
			img.src = "https://baronbet.starly.mu/images/pexels-photo-531880.jpeg?" + startTime;
		}, 60000);
	};

	const _keno_tab_update_money = async () => {
		data.keno_inside_update();
	};

	const _check_if_valid_ticket = async () => {
		let error = [];

		if (_reprint_ticket_number == '' || _reprint_ticket_number == undefined || _reprint_ticket_number == null) {
			error.push("cannot be empty");
			_set_reprint_ticket_error(error);
			_set_reprint_ticket_number("");
			setTimeout(function () {
				_set_reprint_ticket_error();
			}, 20000);
		}
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem("auth_token"),
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ barcode: _reprint_ticket_number }),
		};
		fetch(
			process.env.REACT_APP_API_URL +
			"keno-bet-copy/" +
			localStorage.getItem("user_id"),
			requestOptions
		)
			.then((response) => response.json())
			.then(async (data) => {
				if (data.message == "bet_not_found") {
					error.push("Invalid");
					_set_reprint_ticket_error(error);
					set_please_wait(false);
					_set_reprint_ticket_number("");
					setTimeout(function () {
						_set_reprint_ticket_error();
					}, 20000);
					return;
				}
				if (data.message == "copy_found") {
					_set_copy_ticket_bets(data.userBetDetails);
					_set_copy_ticket_date(data.bet_time);
					_set_copy_ticket_max_potential_win(data.max_potential_win);
					_set_copy_ticket_min_potential_win(data.min_potential_win);
					_set_copy_ticket_time(data.bet_time);
					_set_copy_ticket_total_stakes(data.total_stakes);
					_set_barcode(data.barcode);
					setTimeout(function () {
						handlePrint();
					}, 200);
					setTimeout(function () {
						_set_copy_ticket_bets(null);
						_set_copy_ticket_date(null);
						_set_copy_ticket_max_potential_win(null);
						_set_copy_ticket_min_potential_win(null);
						_set_copy_ticket_time(null);
						_set_copy_ticket_total_stakes(null);
						_set_barcode(null);
						set_please_wait(false);
						_set_reprint_ticket_number("");
					}, 1500);
					return;
				}
				error.push(Language.translations.Slip.something_wrong_try_again[_user_language]);
				_set_reprint_ticket_error(error);
				set_please_wait(false);
				_set_reprint_ticket_number("");
				setTimeout(function () {
					_set_reprint_ticket_error();
				}, 20000);
				return;
			});
	}

	const remove_bet_list = async () => {
		data._update_slip();
	}

	const _generate_q_1_8_1_3_x = async (fastbet) => {
		let arr = fastbet.split(":");
		_generate_quick_pick_random_to_different_game(arr[0], arr[1])
	}

	const _generate_q_1_8_1_3_x_amount = async (fastbet) => {
		let arr = fastbet.split(/:|@/);
		_generate_quick_pick_random_to_different_game_with_custom_stakes(arr[0], arr[1], arr[2])
	}

	const _generate_q_with_amount = async (fastbet) => {
		let arr = fastbet.split(/@/);
		let qty = arr[0].replace("Q", "");
		let _rand_numbs = [];
		for (let i = 0; i < qty; i++) {
			let _tmp = _generate_random(1, 80, _rand_numbs);
			_rand_numbs.push(parseInt(_tmp));
		}
		_manipulate_slip_with_custom_stakes(_three_ids[0], _rand_numbs, '1x', _current_display_id, arr[1]);
	}

	const _fast_bet__one_number_multiple_event = async (fastbet) => {
		let arr = fastbet.split(/:/);
		_manipulate_slip(
			_three_ids[0],
			[parseInt(arr[0])],
			arr[1].toLowerCase(),
			_current_display_id
		);
	}

	const _fast_bet_multiple_numbers_and_multiple_games = async (fastbet) => {
		let obj = fastbet.split(/,|:/);
		let arr = [];
		for (let i = 0; i < 8; i++) {
			if (obj[i] && obj[i] !== obj[obj.length - 1]) {
				arr.push(parseInt(obj[i]));
			}
		}
		_manipulate_slip_with_custom_stakes(_three_ids[0], arr, obj[obj.length - 1].toLowerCase(), _current_display_id, 1000);
	}

	const _reprint_last_settlement = async () => {
		set_last_settlement_print_in_progress(true);
		const requestOptions = {
			method: "POST",
			headers: {
				Authorization: localStorage.getItem('auth_token'),
				"Content-Type": "application/json",
			},
		};
		fetch(process.env.REACT_APP_API_URL + "print-settlement/" + localStorage.getItem('user_id'), requestOptions)
			.then((response) => response.json())
			.then(async (data) => {
				_set_settlement_data(data);
				setTimeout(function () {
					reprint_settlement_handle();
					set_last_settlement_print_in_progress(false);
				}, 3000);
			});
	}

	/*
	 */
	useEffect(() => {
		if (_current_time <= 11) {
			let tmp_id = _three_ids[0];
			remove_upon_change_tab(tmp_id);
			_set_no_more_bets(true);
			_set_close_total_stake_modal(false);
		}
		if (_current_time > 11) {
			_set_no_more_bets(false);
			_set_close_total_stake_modal(true);
		}
		// if (!document.hasFocus()) {
		// 	_set_sync(true);
		// } else {
		// 	if (_sync == true) {
		// 		setTimeout(function () {
		// 			window.location.reload();
		// 		}, 200);
		// 	}
		// }
	}, [_current_time]);

	/*
	 */
	useEffect(() => {
		/*
		 */
		_check_internet_connection();

		/*
		 */
		const socket = socketIOClient(ENDPOINT);
		socket.on("_current_three_id", (data) => {
			_set_three_ids([
				data._normal_starting_id,
				data._next_starting_id,
				data._next_next_starting_id,
			]);
		});
		socket.on("_react_js_swap_tab", (data) => {
			_set_three_ids([
				data._normal_starting_id,
				data._next_starting_id,
				data._next_next_starting_id,
			]);
		});
		socket.on("_normal_draw_time", (data) => {
			_set_current_time(data - 10);
		});
		socket.on("_next_normal_time", (data) => {
			_set_next_current_time(data - 10);
		});
		socket.on("_next_next_normal_time", (data) => {
			_set_next_next_current_time(data - 10);
		});
		socket.on("_current_display_id", (data) => {
			_set_current_display_id(data);
		});
		socket.on("_next_display_id", (data) => {
			_set_next_display_id(data);
		});
		socket.on("_next_next_display_id", (data) => {
			_set_next_next_display_id(data);
		});
		socket.on("_current_game_time", (data) => {
			_set_current_game_time(data);
		});
		socket.on("_next_game_time", (data) => {
			_set_next_game_time(data);
		});
		socket.on("_next_next_game_time", (data) => {
			_set_next_next_game_time(data);
		});
		socket.on("_game_status", (data) => {
			_set_is_game_paused(data);
		});
	}, []);


    useEffect(() => {
        const handleFocus = () => {
          // Update the refresh key to trigger a re-render
          setRefreshKey(prevKey => prevKey + 1);
        };
    
        window.addEventListener('focus', handleFocus);
    
        return () => {
          window.removeEventListener('focus', handleFocus);
        };
      }, []);


	/*
	 */
	return (
		<>
			{please_wait == true && (
				<div className="no-more-bets">
					<div className="cnInner">
						<div className="cnTopLogoKeno"></div>
						<h1>{Language.translations.Slip.please_wait[_user_language]}</h1>
					</div>
				</div>
			)}
			{_sync == true && (
				<div className="no-more-bets">
					<div className="cnInner">
						<div className="cnTopLogoKeno"></div>
						<h1>{Language.translations.Tab.sync[_user_language]}</h1>
					</div>
				</div>
			)}
			{_is_game_paused == true && (
				<div className="no-more-bets">
					<div className="cnInner">
						<div className="cnTopLogoKeno"></div>
						<h1>{Language.translations.Tab.maintanance[_user_language]}</h1>
					</div>
				</div>
			)}
			<div className="cnMainBoard" key={refreshKey}>
				<div className="cn-left">
					<div className="tabs">
						<button className="cnLiveDraw" onClick={() => { data._show_frame() }}>{Language.translations.Tab.live_draw[_user_language]}</button>
						<Tabs
							selectedIndex={_track_tab}
							onSelect={(value) => _set_track_tab(value)}
						>
							<TabList>
								{_three_ids !== undefined &&
									_three_ids !== null &&
									_three_ids.map((object, i) =>
										object !== undefined && object != null && i == 0 ? (
											<Tab key={i}>
												{_current_game_time}
											</Tab>
										) : i == 1 ? (
											<Tab key={i}>
												{_next_game_time}
											</Tab>
										) : i == 2 ? (
											<Tab key={i}>
												{_next_next_game_time}
											</Tab>
										) : null
									)}
							</TabList>
							{_three_ids !== undefined &&
								_three_ids !== null &&
								_three_ids.map(
									(object, i) =>
										object !== undefined &&
										object != null && (
											<TabPanel key={i}>
												<KenoBoard
													_is_betting_allowed={i == 0 ? _no_more_bets : false}
													_remove_upon_zero={(id) => {
														remove_upon_change_tab(id);
													}}
													_send_to_tab={(board_id, row, rep, disp_id) => {
														_manipulate_slip(board_id, row, rep, disp_id);
													}}
													key={object}
													_board_id={object}
													_three_ids={_three_ids}
													_remaining_time_to_bet={
														i == 0
															? _current_time
															: i == 1
																? _next_current_time
																: i == 2
																	? _next_next_current_time
																	: null
													}
													_remaining_duration={
														i == 0
															? _duration_1
															: i == 1
																? _duration_2
																: i == 2
																	? _duration_3
																	: null
													}
													_id={object}
													_display_id={
														i == 0
															? _current_display_id
															: i == 1
																? _next_display_id
																: i == 2
																	? _next_next_display_id
																	: null
													}
												/>
											</TabPanel>
										)
								)}
						</Tabs>
					</div>
				</div>
				<div className="cn-right">
					<div className="fastbet">
						{
							_show_error == true &&
							<div className="error-fastbet">
								<p className="danger">{Language.translations.Tab.error[_user_language]}</p>
							</div>
						}
						<input
							id="fastbet-input"
							autoComplete="off"
							onChange={(e) => {
								_set_fastbet(e.target.value.toUpperCase());
							}}
							value={_fastbet.toUpperCase()}
							placeholder={Language.translations.Slip.fastbet[_user_language]}
							onKeyPress={(ev) => {
								if (ev.key === "Enter") {

									if (_fastbet == "") {
										_set_fastbet("");
										enterFunc.current.key = "Enter";
										enterFunc.current();
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: Q1 - Q8
									if (_fastbet == "Q1" || _fastbet == "Q2" || _fastbet == "Q3" || _fastbet == "Q4" || _fastbet == "Q5" || _fastbet == "Q6" || _fastbet == "Q7" || _fastbet == "Q8"
									) {
										remove_bet_list();
										_fast_bet_quick_pick(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 10
									if (_fastbet > 0 && _fastbet <= 80) {
										remove_bet_list();
										_fast_bet_numbers_only(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 10005166331417898432
									if (isNumeric(_fastbet) && _fastbet.length > 18) {
										remove_bet_list();
										childFunc.current.fastbet = _fastbet;
										childFunc.current();
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 24:3X
									if (/^([1-8]?[0-9])*:([1-3]X)$/.test(_fastbet)) {
										remove_bet_list();
										_fast_bet__one_number_multiple_event(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 80,11
									if (/^([1-8]?[0-9])(,[1-8]?[0-9])*$/.test(_fastbet)) {
										remove_bet_list();
										_fast_bet_multiple_numbers(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 11,1,3@9000
									if (/^([1-8]?[0-9])(,[1-8]?[0-9])*@([1-9][0-9][0-9][0-9](?!00$))*/.test(_fastbet)) {
										remove_bet_list();
										_fast_bet_multiple_numbers_with_custom_stakes(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 11,1,3:1X@9000
									if (/^([1-8]?[0-9])(,[1-8]?[0-9])*:([1-3]X)@([1-9][0-9][0-9][0-9](?!00$))*/.test(_fastbet)) {
										remove_bet_list();
										_fast_bet_multiple_numbers_with_custom_stakes_and_multiple_games(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 10@9000
									if (/^[1-8]?[0-9]?(\.[1-8][0-9]?)?@([1-9][0-9][0-9][0-9](?!00$))*/.test(_fastbet)) {
										remove_bet_list();
										_fast_bet_numbers_custom_stakes_only(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: Q1:3X
									if (/^(Q[1-8]):([1-3]X)$/.test(_fastbet)) {
										_generate_q_1_8_1_3_x(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: Q4:3X@9000
									if (/^(Q[1-8]):([1-3]X)@([1-9][0-9][0-9][0-9](?!00$))*/.test(_fastbet)) {
										_generate_q_1_8_1_3_x_amount(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: Q8:@9000
									if (/^(Q[1-8])@([1-9][0-9][0-9][0-9](?!00$))*/.test(_fastbet)) {
										_generate_q_with_amount(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									// example: 1,2,3,4,5,6,7,8:3X
									if (/^([1-8]?[0-9])(,[1-8]?[0-9])*:([1-3]X)*/.test(_fastbet)) {
										remove_bet_list();
										_fast_bet_multiple_numbers_and_multiple_games(_fastbet);
										_set_fastbet("");
										ev.preventDefault();
										ev.target.blur();
										return;
									}

									_set_fastbet("");
									ev.preventDefault();
									ev.target.blur();
									_set_show_error(true);
									setTimeout(function () {
										_set_show_error(false);
									}, 1000);
									return;
								}
							}}
						/>
						<button onClick={() => { window.open('/pdf/keno_shortcut_' + localStorage.getItem('language') + '.pdf', '_blank') }}></button>
					</div>
					<div
						className={
							data.all_ticket == true ? "keno-slip true" : "keno-slip false"
						}
					>
						<KenoSlip
							_is_settings_open={data._settings}
							remove_bet_list={remove_bet_list}
							childFunc={childFunc}
							enterFunc={enterFunc}
							_current_time={_current_time}
							_tab_update_money={_keno_tab_update_money}
							all_tickets={data.tickets}
							_on_going_play={_three_ids[0]}
							_remove_all={_remove_all_bets}
							_show_ticket_of_the_day={data.all_ticket}
							_slip={_slip}
							stakes={_odds}
							_remove_row={(i) => {
								_remove_row_bet(i);
							}}
						/>
					</div>
				</div>
			</div>
			{data._settings == true && (
				<div className="modal-settings cnGeneral">
					<div className="cnInner">
						<Scrollbars>
							<div className="cnTop">
								<h1>
									{Language.translations.Tab.general_settings[_user_language]}
								</h1>
								<div className="cnClose">
									<button
										onClick={() => {
											data._on_change_settings(false);
											_set_reprint_ticket_number("");
										}}
									></button>
								</div>
							</div>
							<div className="cnBody">
								<div className="cnTitle">
									<p>{Language.translations.Tab.general_information[_user_language]}</p>
								</div>
								<div className="cnTableWrap cnGeneralSetting">
									<div className="divTable">
										<div className="divTableBody">
											<div className="divTableRow">
												<div className="divTableCell cnleft">{Language.translations.Tab.username[_user_language]}</div>
												<div className="divTableCell">
													{localStorage.getItem("name")}
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">{Language.translations.Tab.balance[_user_language]}</div>
												<div className="divTableCell">
													{data._shop_credit}
													{localStorage.getItem("currency_symbol")}
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">{Language.translations.Tab.language[_user_language]}</div>
												<div className="divTableCell">
													<select
														name="lang"
														id="lang"
														value={_user_language}
														onChange={(e) => {
															localStorage.setItem("language", e.target.value);
															window.location.reload();
														}}
													>
														<option value="en">EN</option>
														<option value="fr">FR</option>
													</select>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">{Language.translations.Tab.connection_quality[_user_language]}</div>
												<div className="divTableCell">

													{_is_internet_good == true && (
														<div className="internet internet-good">{Language.translations.Tab.good[_user_language]}</div>
													)}
													{_is_internet_good == false && (
														<div className="internet internet-bad">{Language.translations.Tab.bad[_user_language]}</div>
													)}
													<div className="cnBut">
														<button onClick={() => { _refresh_check_internet_connection() }}>{Language.translations.Tab.refresh[_user_language]}</button>
													</div>
													{
														_show_connection_spinner == true &&
														<div className="loader-check-connection">
															<ColorRing
																visible={true}
																height="80"
																width="80"
																ariaLabel="blocks-loading"
																wrapperStyle={{}}
																wrapperClass="blocks-wrapper"
																colors={[
																	"#003779",
																	"#ff171b",
																	"#ff8400",
																	"#008032",
																	"#ffcc00",
																]}
															/>
														</div>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="cnTitle">
									<p>{Language.translations.Tab.keyboard_shortcuts[_user_language]}</p>
								</div>
								<div className="cnTableWrap">
									<div className="divTable">
										<div className="divTableBody">
											<div className="divTableRow">
												<div className="divTableCell">[1 - 80] + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.first_fastbet_shortcut[_user_language]} <i>(eg.) 24</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1 - 80] + @ + {Language.translations.Tab.stakes[_user_language]} + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.second_fastbet_shortcut[_user_language]} <i>(eg.) 24@1000</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1 - 80] + [:] + [1 - 3] + [X] + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.third_fastbet_shortcut[_user_language]} <i>(eg.) 24:1X</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1 - 80] + [:] + [1 - 3] + [X] + @ + {Language.translations.Tab.stakes[_user_language]} + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.forth_fastbet_shortcut[_user_language]} <i>(eg.) 24:3X@1000</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1] , [2] , [3] , [4] , [5] , [7] , [8] + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.fifth_fastbet_shortcut[_user_language]} <i>(eg.) 1,2,3,4,5,6,7,8</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1] , [2] , [3] , [4] , [5] , [7] , [8] + @ + {Language.translations.Tab.stakes[_user_language]} + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.sixth_fastbet_shortcut[_user_language]} <i>(eg.) 1,2,3,4,5,6,7,8@1000</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1] , [2] , [3] , [4] , [5] , [7] , [8] + [:] + [1 - 3] + [X] + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.seventh_fastbet_shortcut[_user_language]} <i>(eg.) 1,2,3,4,5,6,7,8:3X</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[1] , [2] , [3] , [4] , [5] , [7] , [8] + [:] + [1 - 3] + [X] + @ + {Language.translations.Tab.stakes[_user_language]} + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.eighth_fastbet_shortcut[_user_language]} <i>(eg.) 1,2,3,4,5,6,7,8:3X@1000</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell cnleft">
													[Q] + [1 - 8] + {Language.translations.Tab.enter[_user_language]}
												</div>
												<div className="divTableCell">
													{Language.translations.Tab.nineth_fastbet_shortcut[_user_language]} <i>(eg.) Q8</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[Q] + [1 - 8] + @ + {Language.translations.Tab.stakes[_user_language]} + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.tenth_fastbet_shortcut[_user_language]} <i>(eg.) Q8@1000</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[Q] + [1 - 8] + [:] + [1 - 3] + [X] + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.eleventh_fastbet_shortcut[_user_language]} <i>(eg.) Q8:1X</i>
												</div>
											</div>
											<div className="divTableRow">
												<div className="divTableCell">[Q] + [1 - 8] + [:] + [1 - 3] + [X] + @ + {Language.translations.Tab.stakes[_user_language]} + {Language.translations.Tab.enter[_user_language]}</div>
												<div className="divTableCell">
													{Language.translations.Tab.twelveth_fastbet_shortcut[_user_language]} <i>(eg.) Q8:3X@1000</i>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="cnTitle">
									<p>{Language.translations.Tab.settings[_user_language]}</p>
								</div>
								<div className="cnTableWrap cnGeneralSetting">
									<div className="divTable">
										<div className="divTableBody">
											<div className="divTableRow">
												<div className="divTableCell">{Language.translations.Tab.reprint_ticket[_user_language]}</div>
												<div className="divTableCell">
													<div className="cnInput">
														<input className="reprint-input" placeholder={Language.translations.Tab.ticket_number[_user_language]} type="text" value={_reprint_ticket_number} onChange={(e) => { _set_reprint_ticket_number(e.target.value) }} />
														{_reprint_ticket_error != undefined &&
															_reprint_ticket_error != null && (
																<div className="error-message">
																	<ul>
																		{_reprint_ticket_error.map((item, index) => (
																			<li key={index}>{item}</li>
																		))}
																	</ul>
																</div>
															)}
													</div>
												</div>
												<div className="divTableCell">
													<div className="cnButton">
														<button className="cnRightBut" onClick={() => { _check_if_valid_ticket() }}>{Language.translations.Tab.apply[_user_language]}</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="cnTableWrap cnGeneralSetting">
									<div className="divTable">
										<div className="divTableBody">
											<div className="divTableRow">
												<div className="divTableCell">Reprint Last Settlement</div>
												<div className="divTableCell">
													<div className="cnButton">
														{
															last_settlement_print_in_progress == true &&
															<div className="loader-check-connection">
																<ColorRing
																	visible={true}
																	height="80"
																	width="80"
																	ariaLabel="blocks-loading"
																	wrapperStyle={{}}
																	wrapperClass="blocks-wrapper"
																	colors={[
																		"#003779",
																		"#ff171b",
																		"#ff8400",
																		"#008032",
																		"#ffcc00",
																	]}
																/>
															</div>
														}
														<button className="cnRightBut" onClick={() => { _reprint_last_settlement(); }}>Reprint</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="cnTableWrap cnGeneralSetting">
									<div className="divTable">
										<div className="divTableBody">
											<div className="divTableRow">
												<div className="divTableCell">{Language.translations.Tab.printer_driver[_user_language]}</div>
												<div className="divTableCell">
													<div className="cnButton">
														<button className="cnRightBut" onClick={() => { window.open('driver/printer_driver.zip', '_blank').focus(); }}>{Language.translations.Tab.download[_user_language]}</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Scrollbars>
					</div>
				</div >
			)
			}

			<div ref={componentRef} className="wa-copy">
				<KenoTicketCopy
					bets={_copy_ticket_bets}
					min_potential_win={_copy_ticket_min_potential_win}
					max_potential_win={_copy_ticket_max_potential_win}
					total_stakes={_copy_ticket_total_stakes}
					date={_copy_ticket_date}
					time={_copy_ticket_time}
					name={localStorage.getItem("name")}
					shop_name={localStorage.getItem("shop_name")}
					barcode={_barcode}
				/>
			</div>

			<div ref={settlmentSlip} className="waza">
				<Settlement
					print_data={_settlement_data}
				/>
			</div>

		</>
	);
}

export default KenoTab;

function _generate_random(min, max, arr) {
	let difference = max - min;
	let rand = Math.random();
	rand = Math.floor(rand * difference);
	rand = rand + min;
	if (arr.includes(rand)) {
		rand = _generate_random(1, 80, arr);
	}
	return rand;
}

function areEqual(array1, array2) {
	if (array1.length === array2.length) {
		return array1.every((element, index) => {
			if (element === array2[index]) {
				return true;
			}
			return false;
		});
	}
	return false;
}

function checkIfDuplicateExists(arr) {
	return new Set(arr).size !== arr.length;
}

function checkConnectionSpeed(millisecond) {
	var x = document.getElementById("connection-message");
	if (millisecond > 5000) {
		return 'BAD';
	} else if (millisecond > 3000) {
		return 'BAD';
	} else {
		return 'GOOD';
	}
}

function isNumeric(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}